import React, { memo, useRef } from 'react';

// Config
import { CONTENT_TYPE } from '../../../../../../../../utils/constants';

// Context(s)
import { useTask } from '../../../../../../../../context/TaskContext';

// Component(s)
import TextContent from './TextContent';
import PhotoContent from './PhotoContent';

// Styling
import './Content.scss';

const Content = ({ content }) => {
  const contentRef = useRef();
  const { setIsEditDetailFormOpen, setEditorContentProps, task } = useTask();

  const openEditor = () => {
    setEditorContentProps({
      data: content,
      onClose: () => {
        setIsEditDetailFormOpen(false);
        setEditorContentProps(null);
      },
    });
    setIsEditDetailFormOpen(true);
  };

  const component = (() => {
    if (content.type === CONTENT_TYPE.TEXT) {
      return <TextContent content={content} openEditor={openEditor} />;
    }

    if (content.type === CONTENT_TYPE.PHOTO) {
      return <PhotoContent content={content} openEditor={openEditor} task={task} />;
    }

    return null;
  })();

  return (
    <div className="govuk-grid-column-full content-item" ref={contentRef}>
      {component}
    </div>
  );
};

export default memo(Content);
