import { Button, Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { CONTENT_TYPE, TASK_DETAIL_PAGE_UPDATING, TASK_STATUS, TIME_FORMATS } from '../../../../../../../../utils/constants';

// Component(s)
import ContentPhoto from './ContentPhoto';
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import LabelValuePair from '../generics/LabelValuePair';

// Util(s)
import { toTimestamp, toCreator } from '../../../helper/toContent';

// Styling
import './PhotoContent.scss';

const PhotoContent = ({ content, openEditor, task }) => {
  const isUpdatingTask = () => {
    return localStorage.getItem(TASK_DETAIL_PAGE_UPDATING);
  };

  return (
    <>
      <Heading size="s">Addition</Heading>
      <div id="photo-content-container">
        <div className="grid-item">
          <ContentPhoto url={content?.url} filename={content?.filename} />
        </div>
        <div className="grid-item">
          <LabelValuePair
            id={`content-source-${content.id}`}
            label="Source"
            value={content?.source}
          />
        </div>
        <div className="grid-item">
          <LabelValuePair
            id={`content-photo-date-${content.id}`}
            label="Date of photo"
            value={content?.approximateDateTaken}
          />
        </div>
        <div className="grid-item">
          <LabelValuePair
            id={`content-timestamp-${content.id}`}
            label="Added"
            value={toTimestamp({ timestamp: content?.timestamp }, TIME_FORMATS.CONTENT)}
          />
        </div>
        <div className="grid-item content-creator-edit-control">
          <LabelValuePair
            id={`content-creator-${content.id}`}
            label="Added by"
            value={toCreator({ user: content?.user })}
          />
          <ComponentWrapper show={
              (content?.editable
              && task?.status === TASK_STATUS.ISSUED
              && isUpdatingTask())
              || (content?.editable && task?.status === TASK_STATUS.IN_PROGRESS)
            }
          >
            <Button
              id={`content-edit-${content.id}`}
              classModifiers="secondary"
              onClick={openEditor}
            >
              Edit
            </Button>
          </ComponentWrapper>
        </div>
      </div>
    </>
  );
};

PhotoContent.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    user: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
    timestamp: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
    type: (props, propName, componentName) => {
      if (props[propName] !== CONTENT_TYPE.PHOTO) {
        return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
      }
    },
    url: PropTypes.string,
    source: PropTypes.string,
    approximateDateTaken: PropTypes.string,
  }).isRequired,
  openEditor: PropTypes.func.isRequired,
  task: PropTypes.shape({}).isRequired,
};

export default PhotoContent;
