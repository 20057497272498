import { DateTimeUtil } from '../../../../../../utils';
import { DATE_FORMATS } from '../../../../../../utils/constants';

const toAdditionalContentAndCredibilityChecksPayload = (additionalContent, deletedAdditionalContent, checks) => {
  const getDeletedAdditionalContent = (content) => {
    let normalizedContent;

    if (Array.isArray(content)) {
      normalizedContent = content.map((item) => item.id);
    } else if (content) {
      normalizedContent = [content.id];
    } else {
      normalizedContent = null;
    }
    return normalizedContent;
  };

  if (deletedAdditionalContent !== null && deletedAdditionalContent !== undefined) {
    return {
      additionalContent: {
        delete: {
          additionIds: getDeletedAdditionalContent(deletedAdditionalContent),
          timestamp: DateTimeUtil.format(new Date().toISOString(), DATE_FORMATS.UTC),
        },
        add: additionalContent ? [...additionalContent] : null,
      },
      credibilityChecks: checks,
    };
  }

  return {
    additionalContent: {
      add: additionalContent ? [...additionalContent] : null,
    },
    credibilityChecks: checks,
  };
};

export default toAdditionalContentAndCredibilityChecksPayload;
