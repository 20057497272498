// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import classNames from 'classnames';
import React from 'react';

// Config
import FIELDS from '../../Fields';

// Components
import EnrichmentCount from './EnrichmentCount';

// Util(s)
import renderBlock, { toPreviousMovementLink, toPreviousTaskLink, toPreviousTaskLinkParams } from '../../common';
import { CommonUtil, EnrichmentUtil, VehicleUtil, TaskVersionUtil } from '../../../../../../../../utils';

const Vehicle = ({ version, versionDiff, classModifiers }) => {
  const vehicle = VehicleUtil.get(version);
  const vehicleDiff = VehicleUtil.get(versionDiff);
  const vehicleRegistration = VehicleUtil.registration(vehicle);
  const vehicleType = VehicleUtil.type(vehicle);
  const vehicleMake = VehicleUtil.make(vehicle);
  const vehicleModel = VehicleUtil.model(vehicle);
  const vehicleNationalityIso2Code = VehicleUtil.nationality(vehicle);
  const formattedVehicleNationality = CommonUtil.iso3Code(vehicleNationalityIso2Code);
  const vehicleColour = VehicleUtil.colour(vehicle);

  return (
    <div className={classNames('task-details-container', 'govuk-!-margin-bottom-2', classModifiers)}>
      <Heading className="govuk-!-margin-top-0" size="m">Vehicle</Heading>
      <EnrichmentCount
        id="vehicle"
        movementCount={EnrichmentUtil.movementCount(CommonUtil.movementStats(vehicle))}
        examinationCount={EnrichmentUtil.examinationCount(CommonUtil.movementStats(vehicle))}
        seizureCount={EnrichmentUtil.seizureCount(CommonUtil.movementStats(vehicle))}
      />
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Vehicle registration',
          {
            content: vehicleRegistration,
            url: CommonUtil.entitySearchUrl(vehicle),
          },
          TaskVersionUtil.hasAny(vehicleDiff, [FIELDS.registration]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, vehicle)),
          toPreviousMovementLink(vehicle),
        )}
        {renderBlock(
          'Type',
          { content: vehicleType },
          TaskVersionUtil.hasAny(vehicleDiff, [FIELDS.type]),
        )}
        {renderBlock(
          'Make',
          { content: vehicleMake },
          TaskVersionUtil.hasAny(vehicleDiff, [FIELDS.make]),
        )}
        {renderBlock(
          'Model',
          { content: vehicleModel },
          TaskVersionUtil.hasAny(vehicleDiff, [FIELDS.model]),
        )}
        {renderBlock(
          'Country of registration',
          { content: formattedVehicleNationality },
          TaskVersionUtil.hasAny(vehicleDiff, [FIELDS.nationality]),
        )}
        {renderBlock(
          'Colour',
          { content: vehicleColour },
          TaskVersionUtil.hasAny(vehicleDiff, [FIELDS.colour]),
        )}
      </div>
    </div>
  );
};

export default Vehicle;
