// Global import(s)
import lookup from 'country-code-lookup';

const toFormattedCountryCode = (countryCode) => {
  if (!countryCode) {
    return null;
  }
  try {
    return lookup?.byIso(countryCode)?.country || null;
  } catch {
    return null;
  }
};

const getDocumentCountryOfIssue = (document) => {
  return document?.issueCountry?.isoCode || null;
};
const getDocumentCountryOfIssueName = (document) => {
  return document?.issueCountry?.name || null;
};

const getDocument = (person) => {
  return person?.document || undefined;
};

const getDocumentType = (document) => {
  return document?.type || undefined;
};

const getDocumentTypeDescription = (document) => {
  if (!document) {
    return undefined;
  }
  return getDocumentType(document)?.shortdescription || undefined;
};

const getDocumentNumber = (document) => {
  return document?.number || undefined;
};

const getDocumentExpiry = (document) => {
  return document?.expiry || undefined;
};

const TargetDocumentUtil = {
  doc: getDocument,
  docCountry: getDocumentCountryOfIssue,
  docCountryName: getDocumentCountryOfIssueName,
  docExpiry: getDocumentExpiry,
  docNumber: getDocumentNumber,
  docType: getDocumentType,
  docTypeDescription: getDocumentTypeDescription,
  format: {
    country: toFormattedCountryCode,
  },
};

export default TargetDocumentUtil;

export {
  getDocument,
  getDocumentCountryOfIssue,
  getDocumentCountryOfIssueName,
  getDocumentExpiry,
  getDocumentNumber,
  getDocumentType,
  getDocumentTypeDescription,
  toFormattedCountryCode,
};
