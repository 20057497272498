import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Config(s)
import { MOVEMENT_MODES, TEXT_DEFAULTS } from '../../../../utils/constants';
import { DIRECTION } from '../../../../utils/TargetInformation/constants';

// Component(s)
import Accordion from '../../../../components/AccordionCustom/Accordion';
import AccordionRow from '../../../../components/AccordionCustom/AccordionRow';
import PreArrival from '../../../Target/TargetSheetPage/components/movement/details/PreArrival';
import SystemChecks from '../../../Target/TargetSheetPage/components/movement/checks/SystemChecks';
import TargetingDetails from '../../../Target/TargetSheetPage/components/movement/details/TargetingDetails';

// Util(s)
import { DateTimeUtil, TargetJourneyUtil, TargetMovementUtil, StringUtil, TargetInformationUtil } from '../../../../utils';

// Styling
import './MovementDetails.scss';

const MovementDetails = ({ informationSheet }) => {
  const [panelData, setPanelData] = useState(null);
  const mode = TargetMovementUtil.mode(informationSheet);
  const journey = TargetJourneyUtil.get(informationSheet);
  const direction = TargetJourneyUtil.direction(journey);
  const dateFormat = TEXT_DEFAULTS.DATE.DMY1;
  const timeFormat = 'HH:mm';

  const toAdditionalInformation = () => {
    if (mode === MOVEMENT_MODES.AIR_PASSENGER) {
      return {
        label: 'Additional information',
        value: TargetInformationUtil.informationSheet.additionalInformation(informationSheet),
        id: 'additional-info',
      };
    }
    return null;
  };

  const setupAirpaxPanelData = () => {
    setPanelData([
      {
        label: 'Flight number',
        value: TargetJourneyUtil.id(journey),
        id: 'flight-number',
      },
      {
        label:
        direction === DIRECTION.INBOUND
          ? 'Route to UK'
          : 'Route from UK',
        value: TargetMovementUtil.route(informationSheet),
        id: 'route',
      },
      {
        label: 'Inbound or Outbound',
        value: StringUtil.capitalizeFirst(direction),
        id: 'direction',
      },
      toAdditionalInformation(),
      {
        label: 'Port',
        value: TargetMovementUtil.port(informationSheet),
        id: 'event-port',
      },
      {
        label:
        direction === DIRECTION.INBOUND
          ? 'Estimated date of arrival'
          : 'Estimated date of departure',
        value:
        direction === DIRECTION.INBOUND
          ? DateTimeUtil.format(TargetJourneyUtil.arrivalDate(journey), dateFormat)
          : DateTimeUtil.format(TargetJourneyUtil.departureDate(journey), dateFormat),
        id: 'event-date',
      },
      {
        label:
        direction === DIRECTION.INBOUND
          ? 'Estimated time of arrival'
          : 'Estimated time of departure',
        value:
        direction === DIRECTION.INBOUND
          ? DateTimeUtil.format(TargetJourneyUtil.arrivalDate(journey), timeFormat)
          : DateTimeUtil.format(TargetJourneyUtil.departureDate(journey), timeFormat),
        id: 'event-time',
      },
    ]);
  };

  const setupRoRoPanelData = () => {
    setPanelData([
      {
        label: 'Vessel Name',
        value: TargetMovementUtil.vesselName(informationSheet),
        id: 'vessel-name',
      },
      {
        label: 'Route',
        value: TargetJourneyUtil.format.route(journey),
        id: 'route',
      },
      {
        label: 'Inbound or Outbound',
        value: StringUtil.capitalizeFirst(direction),
        id: 'direction',
      },
      {
        label: 'Port',
        value: TargetMovementUtil.port(informationSheet),
        id: 'event-port',
      },
      {
        label:
        direction === DIRECTION.INBOUND
          ? 'Estimated date of arrival'
          : 'Estimated date of departure',
        value:
        direction === DIRECTION.INBOUND
          ? DateTimeUtil.format(TargetJourneyUtil.arrivalDate(journey), TEXT_DEFAULTS.DATE.DMY1)
          : DateTimeUtil.format(TargetJourneyUtil.departureDate(journey), TEXT_DEFAULTS.DATE.DMY1),
        id: 'event-date',
      },
      {
        label:
        direction === DIRECTION.INBOUND
          ? 'Estimated time of arrival'
          : 'Estimated time of departure',
        value:
        direction === DIRECTION.INBOUND
          ? DateTimeUtil.format(TargetJourneyUtil.arrivalDate(journey), 'HH:mm')
          : DateTimeUtil.format(TargetJourneyUtil.departureDate(journey), 'HH:mm'),
        id: 'event-time',
      },
    ]);
  };

  const setupPanelData = () => {
    if (!mode) {
      return;
    }

    if (mode === MOVEMENT_MODES.AIR_PASSENGER) {
      setupAirpaxPanelData();
      return;
    }

    setupRoRoPanelData();
  };

  useEffect(() => {
    setupPanelData();
  }, [informationSheet]);

  return (
    <Accordion
      classnames="target-info-accordion govuk-!-padding-bottom-4"
      id="movement-details-accordion"
      items={[
        {
          id: 'movement-details',
          heading: 'Movement details',
          children: (
            <div className="govuk-grid-row">
              <div className="govuk-grid-column-two-thirds">
                <div className="govuk-!-padding-bottom-4">
                  <dl className="govuk-summary-list">
                    {panelData?.map((item) => {
                      return (
                        <AccordionRow key={item.label} id={item.id} label={item.label} value={item.value} />
                      );
                    })}
                  </dl>
                </div>
                <PreArrival informationSheet={informationSheet} />
                <SystemChecks systemChecks={TargetMovementUtil.nominalChecks(informationSheet)} />
              </div>
              <div id="targeting-details-wrapper" className="govuk-grid-column-one-third">
                <TargetingDetails informationSheet={informationSheet} />
              </div>
            </div>
          ),
        },
      ]}
    />
  );
};

export default MovementDetails;

MovementDetails.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};
