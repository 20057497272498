import React, { useEffect } from 'react';
import { Button, Link, Panel } from '@ukhomeoffice/cop-react-components';

import { CommonUtil } from '../../../../../../utils';

// Hook
import useSetTabTitle, { setTabTitle } from '../../../../../../utils/Hooks/useSetTabTitle';

const TaskOutcomeMessage = ({ message, messageDescription, onFinish, setRefreshTaskData, setRefreshPrefillData, displayInfo = true, tabTitle, onCloseTabTitle }) => {
  useSetTabTitle(tabTitle);

  useEffect(() => {
    CommonUtil.removePreviousFlag();
    CommonUtil.removeMovementFlag();
    if (typeof setRefreshTaskData === 'function') {
      setRefreshTaskData();
    }
    if (typeof setRefreshPrefillData === 'function') {
      setRefreshPrefillData();
    }

    return () => setTabTitle(onCloseTabTitle);
  }, []);

  return (
    <>
      <Panel title={message} />
      {displayInfo && (
        <>
          {messageDescription}
          <Button
            className="govuk-button"
            onClick={onFinish}
          >
            Finish
          </Button>
        </>
      )}

      {!displayInfo && (
        <>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            onClick={onFinish}
          >
            Continue to task detail
          </Link>
        </>
      )}
    </>
  );
};

export default TaskOutcomeMessage;
