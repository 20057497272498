import lookup from 'country-code-lookup';
import nationalities from 'i18n-nationality';
import { withCount } from 'pluralise';

// Util(s)
import { getHiddenValue, shouldHideNumber } from '../../Hiding/hidingUtil';
import { getCentaurEntitySearchURL, getSipEntitySearchURL } from '../commonUtil';

// Locale
import enNationalitiesLocale from '../../../public/locales/en/nationalities';
import { getMaskedValue } from '../../Masking/maskingUtil';

nationalities.registerLocale(enNationalitiesLocale);

const convertToIso3Code = (iso2Code) => {
  if (!iso2Code) {
    return null;
  }

  if (iso2Code === getMaskedValue()) {
    return getMaskedValue();
  }

  try {
    return lookup?.byIso(iso2Code)?.iso3;
  } catch {
    return null;
  }
};

const toFormattedNationality = (iso2Code) => {
  if (!iso2Code) {
    return null;
  }

  return nationalities?.getName(iso2Code, 'en') || null;
};

const getOtherMovementsLabel = (movementCount) => {
  return withCount(movementCount, 'movement', 'movements');
};

const toOtherMovementsLinkParams = (entity) => {
  return {
    otherMovementsUrl: entity?.movementSearchUrl,
    movementCount: entity?.movementStats?.movementCount,
  };
};

const getNumberOfPreviousTasks = (entity) => {
  if (shouldHideNumber(entity?.numberOfPreviousTasks)) {
    return null;
  }
  return entity?.numberOfPreviousTasks ?? 0;
};

const getOtherTaskLinkUrl = (params) => {
  const entity = params.entity;
  const numberOfPreviousTasks = getNumberOfPreviousTasks(entity);
  if (!numberOfPreviousTasks || numberOfPreviousTasks < 1) {
    return null;
  }

  const entityId = entity.entityId;
  if (!entityId) {
    return null;
  }

  const allIds = [...(params?.movementIds && params?.movementIds), params.movementId].filter((id) => !!id);
  const dedupedIds = [...new Set(allIds)];

  return `/previous-tasks?movement-ids=${dedupedIds.join(',')}`
  + `&current-task-id=${params.taskId || null}`
  + `&entity-type=${entityId.type}&svx-id=${entityId.svxId || null}&pole-v1-id=${entityId.poleV1Id || null}`
  + `&pole-v2-id=${encodeURIComponent(entityId.poleV2Id || null)}`
  + `&pnr-pole-v1-id=${encodeURIComponent(entityId.pnrPoleV1Id || null)}`
  + `&pnr-pole-v2-id=${encodeURIComponent(entityId.pnrPoleV2Id || null)}`;
};

const toOtherTasksLinkParams = (movementId, entity, taskId, movementIds = []) => {
  return {
    movementIds,
    movementId,
    entity,
    taskId,
  };
};

const getOtherTasksListLabel = (entity) => {
  const numberOfPreviousTasks = getNumberOfPreviousTasks(entity);
  if (!numberOfPreviousTasks) {
    return null;
  }
  return withCount(numberOfPreviousTasks, '% task', '% tasks');
};

const toOtherTasksURL = (params) => {
  if (!params) {
    return null;
  }

  if (getNumberOfPreviousTasks(params.entity) === getHiddenValue()) {
    return null;
  }

  return getOtherTaskLinkUrl(params);
};

const CommonUtil = {
  centaurEntitySearchURL: getCentaurEntitySearchURL,
  iso3Code: convertToIso3Code,
  otherMovementsLabel: getOtherMovementsLabel,
  otherMovementsLinkParams: toOtherMovementsLinkParams,
  otherTasksLinkParams: toOtherTasksLinkParams,
  otherTasksListLabel: getOtherTasksListLabel,
  otherTasksListURL: toOtherTasksURL,
  sipEntitySearchURL: getSipEntitySearchURL,
  format: {
    nationality: toFormattedNationality,
  },
};

export default CommonUtil;

export {
  convertToIso3Code,
  getOtherMovementsLabel,
  getOtherTasksListLabel,
  toFormattedNationality,
  toOtherMovementsLinkParams,
  toOtherTasksLinkParams,
  toOtherTasksURL,
};
