// Global import(s)
import { Link } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import LoadingSpinner from '../../../../../../../../components/LoadingSpinner/LoadingSpinner';

// Hook(s)
import useFetchImage from '../../../../../../../../utils/Hooks/useFetchImage';

// Styling
import './ContentPhoto.scss';

const ContentPhoto = ({ url, filename }) => {
  const { url: imageUrl, isLoading } = useFetchImage(url);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="photo-detail">
      <p id="image-file-name" aria-label={`file name is ${filename}`}>{filename}</p>
      <Link className="new-tab-image-link" aria-label={`link to ${filename}`} href={imageUrl} target="_blank">
        <img className="passport-photo hods-file-upload" src={imageUrl} alt=" " />
      </Link>
    </div>
  );
};

ContentPhoto.propTypes = {
  url: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
};

export default ContentPhoto;
