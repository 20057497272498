import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import classNames from 'classnames';

// Utils
import { DocumentUtil, PersonUtil, TaskVersionUtil, CommonUtil } from '../../../../../../../../utils';
import renderBlock, { toPreviousTaskLinkParams, toPreviousTaskLink, toPreviousMovementLink } from '../../common';

import FIELDS from '../../Fields';

const Document = ({ version, versionDiff, classModifiers }) => {
  const person = PersonUtil.get(version);
  const document = DocumentUtil.get(person);
  const documentDiff = DocumentUtil.get(PersonUtil.get(versionDiff));

  return (
    <div className={classNames('task-details-container', 'govuk-!-margin-bottom-2', classModifiers)}>
      <Heading className="govuk-!-margin-top-0" size="m">Document</Heading>
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Travel document type',
          {
            content: DocumentUtil.docType(document),
          },
          TaskVersionUtil.hasAny(documentDiff, [FIELDS.type]),
        )}
        {renderBlock(
          'Travel document number',
          {
            content: DocumentUtil.docNumber(document),
            url: CommonUtil.entitySearchUrl(document),
          },
          TaskVersionUtil.hasAny(documentDiff, [FIELDS.number]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, document)),
          toPreviousMovementLink(document),
        )}
        {renderBlock(
          'Travel document expiry',
          {
            content: DocumentUtil.docExpiry(document, true),
          },
          TaskVersionUtil.hasAny(documentDiff, [FIELDS.expiry]),
        )}
      </div>
    </div>
  );
};

export default Document;
