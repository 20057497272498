// Global import(s)
import React from 'react';

// Context(s)
import { usePermission } from '../../../../context/PermissionContext';
import { useTabs } from '../../../../context/TabContext';
import { useView } from '../../../../context/ViewContext';

// Component(s)
import InternalLayout from './components/InternalLayout';
import InternalPreviousTaskDetailsPage from './InternalPreviousTaskDetailsPage';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

// Hook
import useSetTabTitle, { TAB_TITLES } from '../../../../utils/Hooks/useSetTabTitle';

// Styling
import './PreviousTaskDetailsPage.scss';

const PreviousTaskDetailsPage = () => {
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { view, isSettingView } = useView();

  // Hook
  useSetTabTitle(TAB_TITLES.PREVIOUS_TASK_DETAILS);

  if (!view || isSettingView) {
    return (
      <InternalLayout>
        <LoadingSpinner />
      </InternalLayout>
    );
  }

  if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
    return (
      <InternalLayout>
        <p>You are not authorised to view this task.</p>
      </InternalLayout>
    );
  }

  return (
    <InternalLayout>
      <InternalPreviousTaskDetailsPage />
    </InternalLayout>
  );
};

export default PreviousTaskDetailsPage;
