import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import classNames from 'classnames';

import FIELDS from '../../Fields';

import EnrichmentCount from './EnrichmentCount';

// Utils
import { CommonUtil, EnrichmentUtil, HaulierUtil, StringUtil, TaskVersionUtil } from '../../../../../../../../utils';
import renderBlock, { getAllKeyValues, toPreviousMovementLink, toPreviousTaskLink, toPreviousTaskLinkParams } from '../../common';

const Haulier = ({ version, versionDiff, classModifiers }) => {
  const haulier = HaulierUtil.get(version);
  const haulierDiff = HaulierUtil.get(versionDiff);
  const address = CommonUtil.address(haulier);
  const mobileContact = CommonUtil.mobileContact(haulier);
  const telephoneContact = CommonUtil.telephoneContact(haulier);

  return (
    <div className={classNames('task-details-container', 'govuk-!-margin-bottom-2', classModifiers)}>
      <Heading className="govuk-!-margin-top-0" size="m">Haulier details</Heading>
      <EnrichmentCount
        id="haulier"
        movementCount={EnrichmentUtil.movementCount(CommonUtil.movementStats(haulier))}
        examinationCount={EnrichmentUtil.examinationCount(CommonUtil.movementStats(haulier))}
        seizureCount={EnrichmentUtil.seizureCount(CommonUtil.movementStats(haulier))}
      />
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Name',
          {
            content: HaulierUtil.name(haulier),
            url: CommonUtil.entitySearchUrl(haulier),
          },
          TaskVersionUtil.hasAny(haulierDiff, [FIELDS.name]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, haulier)),
          toPreviousMovementLink(haulier),
        )}
        {renderBlock(
          'Address',
          {
            content: StringUtil.format.address(HaulierUtil.address(haulier)),
          },
          TaskVersionUtil.hasAny(haulierDiff?.address, [...getAllKeyValues(FIELDS.address)]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, address)),
        )}
        {renderBlock(
          'Telephone',
          {
            content: HaulierUtil.telephone(haulier),
          },
          TaskVersionUtil.hasAny(haulierDiff?.contacts?.phone, [FIELDS.value]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, telephoneContact)),
        )}
        {renderBlock(
          'Mobile',
          {
            content: HaulierUtil.mobile(haulier),
          },
          TaskVersionUtil.hasAny(haulierDiff?.contacts?.mobile, [FIELDS.value]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, mobileContact)),
        )}
      </div>
    </div>
  );
};

export default Haulier;
