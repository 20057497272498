// Global import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Context(s)
import { useTask } from '../../../../../../../../context/TaskContext';

// Component(s)
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import { isCommoditiesSubMode, isGaSubMode, isIdpSubMode } from '../../../../../../../../utils/Task/taskUtil';

const CredibilityChecksCaption = ({ isEditable }) => {
  const { taskState: { isClaimedByUser, isTargetIssued, isDismissedByTargeter, isWithdrawnByTargeter, isCompletedByFrontline }, subMode } = useTask();
  return (
    <>
      <ComponentWrapper show={isEditable && !isDismissedByTargeter}>
        <p>Select the checks you have conducted and enter the results.</p>
        <ComponentWrapper show={isCommoditiesSubMode(subMode)}>
          <p>PNC check is mandatory if issuing a target.</p>
          <p>Where a target is issued, check results will be sent to Frontline in the target sheet, except HMRC check results which are not shared.</p>
        </ComponentWrapper>
        <ComponentWrapper show={isIdpSubMode(subMode)}>
          <p>
            Where a referral is issued, check results will be sent to HOIOs in the target sheet.
            Check results will not be included in target sheets sent to carriers.
          </p>
        </ComponentWrapper>
        <ComponentWrapper show={isGaSubMode(subMode)}>
          <p>
            Where a referral is issued, only PNC check results without the PNC ID will be sent to the frontline in the alert sheet.
          </p>
        </ComponentWrapper>
      </ComponentWrapper>
      <ComponentWrapper show={!isClaimedByUser && !isTargetIssued && !isEditable && !isDismissedByTargeter && !isWithdrawnByTargeter && !isCompletedByFrontline}>
        <p>Results of credibility checks may be entered when the task is claimed.</p>
      </ComponentWrapper>
      <ComponentWrapper show={!isClaimedByUser && isTargetIssued && !isEditable && !isDismissedByTargeter && !isWithdrawnByTargeter && !isCompletedByFrontline}>
        <p>Results of credibility may be updated by pressing ‘Update task details’.</p>
      </ComponentWrapper>
    </>
  );
};

CredibilityChecksCaption.propTypes = {
  isEditable: PropTypes.bool.isRequired,
};

export default CredibilityChecksCaption;
