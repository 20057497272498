import { Button, FormGroup } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { COMPONENT_IDS } from '../constants';

// Context
import { useTask } from '../../../../../../../../../context/TaskContext';

// Component(s)
import CommoditiesTargetRecipients from '../airpax/CommoditiesTargetRecipients';
import ComponentWrapper from '../../../../../../../../../components/ComponentWrapper/ComponentWrapper';

// Util
import { isGaSubMode } from '../../../../../../../../../utils/Task/taskUtil';
import GaTargetRecipients from '../airpax/GaTargetRecipients';

const TargetRecipients = ({ componentErrors, onChangeRecipient, onRemoveRecipient, onAddActionOption, formData, onChangeBorderRecipient, onRemoveBorderRecipient, recipientType }) => {
  const { subMode } = useTask();
  return (
    <FormGroup
      id={COMPONENT_IDS.RECIPIENTS}
      label={<></>}
      error={componentErrors?.[COMPONENT_IDS.RECIPIENTS]?.message}
      className="govuk-!-margin-bottom-7"
    >
      <ComponentWrapper show={!isGaSubMode(subMode)}>
        <CommoditiesTargetRecipients
          id="target-recipients-table"
          formData={formData}
          onChangeRecipient={onChangeRecipient}
          onRemoveRecipient={onRemoveRecipient}
          onChangeBorderRecipient={onChangeBorderRecipient}
          onRemoveBorderRecipient={onRemoveBorderRecipient}
          recipientType={recipientType}
        />
      </ComponentWrapper>
      <ComponentWrapper show={isGaSubMode(subMode)}>
        <GaTargetRecipients
          id="target-recipients-table"
          formData={formData}
          onChangeRecipient={onChangeRecipient}
          onRemoveRecipient={onRemoveRecipient}
          onChangeBorderRecipient={onChangeBorderRecipient}
          onRemoveBorderRecipient={onRemoveBorderRecipient}
          recipientType={recipientType}
        />

      </ComponentWrapper>
      <ComponentWrapper show={(isGaSubMode(subMode) && formData?.targetRecipients?.length < 1) || !isGaSubMode(subMode)}>
        <Button
          className="govuk-!-margin-bottom-0"
          classModifiers="secondary"
          onClick={onAddActionOption}
        >
          Add recipient
        </Button>
      </ComponentWrapper>
    </FormGroup>
  );
};

TargetRecipients.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChangeRecipient: PropTypes.func.isRequired,
  onRemoveRecipient: PropTypes.func.isRequired,
  onAddActionOption: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
};

TargetRecipients.defaultProps = {
  componentErrors: null,
  formData: null,
};

export default memo(TargetRecipients);
