import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import config from '../../../../../../../../utils/config';

// Context(s)
import { useKeycloak } from '../../../../../../../../context/Keycloak';
import { useTask } from '../../../../../../../../context/TaskContext';

// Services
import AxiosRequests from '../../../../../../../../api/axiosRequests';

// Components
import UpliftedForm from '../forms/UpliftedForm';

// Hook(s)
import { useAxiosInstance } from '../../../../../../../../utils/Axios/axiosInstance';
import useSetTabTitle, { TAB_TITLES } from '../../../../../../../../utils/Hooks/useSetTabTitle';

// Form
import form from '../../../../../../../../forms/uplift/relistTask';

// Styling
import './RelistTask.scss';

const RelistTask = ({ onClose, ...props }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { setTask, taskId } = useTask();

  useSetTabTitle(TAB_TITLES.RELIST_TASK);

  const onSubmit = async ({ data }) => {
    await AxiosRequests.relistTask(apiClient, taskId,
      {
        reason: data?.addReason,
      },
    )
      .then((response) => {
        setTask(response.data);
        onClose();
      })
      .catch((exception) => console.error(exception.message));
  };

  return (
    <div {...props}>
      <UpliftedForm
        form={form}
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    </div>
  );
};

RelistTask.propTypes = {
  onClose: PropTypes.func,
};

RelistTask.defaultProps = {
  onClose: () => {},
};

export default RelistTask;
