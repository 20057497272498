import React from 'react';

import { NumberUtil } from '../../../../../../../../utils';

import './EnrichmentCount.scss';

const EnrichmentCount = ({ movementCount, examinationCount, seizureCount, ...props }) => {
  const createIdWithProps = (id) => {
    if (!props?.id) {
      return id;
    }
    return `${props.id}-${id}`;
  };

  return (
    <div id={createIdWithProps('enrichment-counts-container')} className="enrichment-counts-container">
      <div className="enrichment-counts-headers">
        <div id={createIdWithProps('movements-label')} className="font__light">Previous RoRo movements</div>
        <div id={createIdWithProps('examinations-label')} className="font__light">Examinations</div>
        <div id={createIdWithProps('seizures-label')} className="font__light">Seizures</div>
      </div>
      <div className="enrichment-counts-values">
        <div id={createIdWithProps('movements-count')} className="font__bold">{NumberUtil.zeroIfRequired(NumberUtil.withComma(movementCount))}</div>
        <div id={createIdWithProps('examinations-count')} className="font__bold">{NumberUtil.zeroIfRequired(NumberUtil.withComma(examinationCount))}</div>
        <div id={createIdWithProps('seizures-count')} className="font__bold">{NumberUtil.zeroIfRequired(NumberUtil.withComma(seizureCount))}</div>
      </div>
    </div>
  );
};

export default EnrichmentCount;
