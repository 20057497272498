import React from 'react';

import TargetingIndicators from '../../shared/TargetingIndicators';
import Booking from '../../shared/Booking';
import PrimaryTraveller from './PrimaryTraveller';
import OccupantCount from '../../shared/OccupantCount';

import { MovementUtil, PersonUtil, TaskVersionUtil } from '../../../../../../../../../utils';

const TouristIndividualVersionDetails = ({ version, versionDiff }) => {
  // This object is the current version that has been patched with changed data from previous version.
  const patchedPreviousVersion = TaskVersionUtil.patchPrevious(version, versionDiff);
  const mode = MovementUtil.movementMode(version);

  // Data from current version
  const primaryTraveller = PersonUtil.get(version);
  const occupantCounts = MovementUtil.occupantCounts(version);

  // Diff data from previous version
  const occupantCountsDiff = MovementUtil.occupantCounts(patchedPreviousVersion);
  return (
    <div className="govuk-task-details-grid govuk-!-padding-top-4">
      <div className="govuk-grid-column-one-third">
        <div>
          <TargetingIndicators version={version} />
        </div>
      </div>
      <div className="govuk-grid-column-one-third">
        <div className="vertical-dotted-line">
          <Booking version={version} versionDiff={versionDiff} />
        </div>
      </div>
      <div className="govuk-grid-column-one-third">
        <div className="vertical-dotted-line">
          <OccupantCount
            mode={mode}
            primaryTraveller={primaryTraveller}
            occupantCounts={occupantCounts}
            occupantCountsDiff={occupantCountsDiff}
            classModifiers={primaryTraveller ? ['govuk-!-padding-bottom-1'] : []}
            label="Occupants"
          />
          <PrimaryTraveller version={version} versionDiff={versionDiff} />
        </div>
      </div>
    </div>
  );
};

export default TouristIndividualVersionDetails;
