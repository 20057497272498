// Global import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { PNC_FIELDS, PNC_OUTCOMES } from '../../../../../../../../utils/TargetInformation/constants';
import { STRINGS } from '../../../../../../../../utils/constants';

// Context(s)
import { useFormData } from '../../../../../../../../context/FormDataContext';
import { useTask } from '../../../../../../../../context/TaskContext';

// Component(s)
import UpliftedForm from '../forms/UpliftedForm';

// Hook
import { setTabTitle, TAB_TITLES } from '../../../../../../../../utils/Hooks/useSetTabTitle';

// Util(s)
import PersonUtil from '../../../../../../../../utils/Person/Uplift/personUtil';
import { isGaSubMode } from '../../../../../../../../utils/Task/taskUtil';

// Form
import form from '../../../../../../../../forms/uplift/addPncCheck';

// Styling
import './AddPncCheckResults.scss';

const AddPncCheckResults = ({ passengerPositionedAt, onClose, person, containerId, ...props }) => {
  const { formData, setFormData } = useFormData();
  const { subMode } = useTask();
  const labels = {
    role: isGaSubMode(subMode) ? person.roleLabel : `Passenger ${passengerPositionedAt}`,
    name: PersonUtil.formattedName(PersonUtil.firstname(person))(PersonUtil.lastname(person, true)),
  };

  setTabTitle(TAB_TITLES.ADD_PNC_RESULTS);

  // See logic in AddOtherChecks that clears all checks
  const onHandleChecksNotNecessaryIfRequired = (data) => {
    const checks = formData?.[containerId]?.otherChecks?.checks || [];
    const formDataCopy = { ...formData };
    if (data?.pncOutcome && checks.includes(STRINGS.FORM.NOT_REQUIRED)) {
      formDataCopy[containerId].otherChecks.checks = checks.filter((check) => check !== STRINGS.FORM.NOT_REQUIRED);
    }
    return formDataCopy;
  };

  const onSubmit = async ({ data }) => {
    const formDataCopy = onHandleChecksNotNecessaryIfRequired(data);
    setFormData((prev) => {
      return {
        ...prev,
        ...formDataCopy,
        [containerId]: {
          ...prev?.[containerId],
          pnc: {
            id: data?.id || null,
            pncOutcome: data?.pncOutcome,
            ...(data?.pncOutcome === PNC_OUTCOMES.POTENTIALLY_RELEVANT_INFORMATION_FOUND && {
              pncFound: data?.pncFound,
              pncDetails: {
                types: data?.pncFound.safetyInformation === STRINGS.YES ? data?.pncDetails?.types : null,
                staffConcerns: (data?.pncFound.safetyInformation === STRINGS.YES && data?.pncDetails?.types?.includes('STAFF_CONCERN')) ? data?.pncDetails?.staffConcerns : null,
                poiConcerns: (data?.pncFound.safetyInformation === STRINGS.YES && data?.pncDetails?.types?.includes('POI_CONCERN')) ? data?.pncDetails?.poiConcerns : null,
              },
              pncCategories: {
                ...(data?.pncCategories?.drugsPossession?.length && {
                  drugsPossession: [PNC_FIELDS.DRUGS],
                  possessionClass: data?.pncCategories?.possessionClass,
                }),
                ...(data?.pncCategories?.drugsSupply?.length && {
                  drugsSupply: [PNC_FIELDS.SUPPLY],
                  supplyClass: data?.pncCategories?.supplyClass,
                }),
                ...(data?.pncCategories?.drugsTrafficking?.length && {
                  drugsTrafficking: [PNC_FIELDS.TRAFFICKING],
                  traffickingClass: data?.pncCategories?.traffickingClass,
                }),
                ...(data?.pncCategories?.weaponsPossession?.length && {
                  weaponsPossession: [PNC_FIELDS.POSSESSION],
                  weaponsPossessionOpts: data?.pncCategories?.weaponsPossessionOpts,
                }),
                ...(data?.pncCategories?.weaponsSupply?.length && {
                  weaponsSupply: [PNC_FIELDS.SUPPLY],
                  weaponsSupplyOptions: data?.pncCategories?.weaponsSupplyOptions,
                }),
                ...(data?.pncCategories?.goodsSmuggling?.length && {
                  goodsSmuggling: [PNC_FIELDS.GOODS_SMUGGLING],
                  goodsSmugglingOpts: data?.pncCategories?.goodsSmugglingOpts,
                }),
                ...(data?.pncCategories?.peopleSmuggling?.length && {
                  peopleSmuggling: [PNC_FIELDS.PEOPLE_SMUGGLING],
                  peopleSmugglingOpts: data?.pncCategories?.peopleSmugglingOpts,
                }),
              },
            }),
          },
        },
      };
    });
    onClose();
  };

  return (
    <div {...props}>
      <UpliftedForm
        form={form(labels)}
        preFillData={formData?.[containerId]?.pnc}
        onSubmit={onSubmit}
        onCancel={onClose}
        enableScrollTop={false}
      />
    </div>
  );
};

AddPncCheckResults.propTypes = {
  passengerPositionedAt: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  person: PropTypes.shape({}).isRequired,
  containerId: PropTypes.string.isRequired,
};

export default AddPncCheckResults;
