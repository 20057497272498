// Global import(s)
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

// Config(s)
import { MOVEMENT_MODES } from '../../../../../utils/constants';

// Context(s)
import { NotificationContext } from '../../../../../context/NotificationContext';
import { TasksContext } from '../../../../../context/TasksContext';

// Component(s)
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';
import MovementSection from '../../roro/components/MovementSection';
import TargetIndicatorsSection from '../../roro/components/TargetIndicatorsSection';
import TaskCard from '../../air/components/shared/card/TaskCard';
import TaskNotification from './TaskNotification';
import TitleSection from '../../roro/components/TitleSection';
import VoyageSection from '../../roro/components/VoyageSection';

// Util
import MovementUtil from '../../../../../utils/Movement/movementUtil';

const TaskListCard = ({ task, currentUser, redirectPath }) => {
  const { notification } = useContext(NotificationContext);
  const { taskIdsToRemove } = useContext(TasksContext);
  const mode = MovementUtil.movementMode(task);

  if ([MOVEMENT_MODES.AIR_PASSENGER, MOVEMENT_MODES.GENERAL_AVIATION].includes(mode)) {
    return <TaskCard task={task} />;
  }

  //  Default to RORO
  return (
    <div className="govuk-task-list-card">
      <ComponentWrapper show={notification && taskIdsToRemove.includes(task.id)}>
        <TaskNotification />
      </ComponentWrapper>
      <div className="card-container">
        <TitleSection
          targetTask={task}
          currentUser={currentUser}
          redirectPath={redirectPath}
        />
        <VoyageSection targetTask={task} />
        <MovementSection targetTask={task} />
        <TargetIndicatorsSection targetTask={task} redirectPath={redirectPath} />
      </div>
    </div>
  );
};

TaskListCard.propTypes = {
  task: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired,
};

export default TaskListCard;
