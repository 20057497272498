import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import classNames from 'classnames';

import { UNITS } from '../../../../../../../../utils/constants';

import { CommonUtil, EnrichmentUtil, FieldFormatterUtil, TaskVersionUtil, TrailerUtil } from '../../../../../../../../utils';
import renderBlock, { toPreviousMovementLink, toPreviousTaskLink, toPreviousTaskLinkParams } from '../../common';

import FIELDS from '../../Fields';

import EnrichmentCount from './EnrichmentCount';

const Trailer = ({ version, versionDiff, classModifiers }) => {
  const trailer = TrailerUtil.get(version);
  const trailerDiff = TrailerUtil.get(versionDiff);

  return (
    <div className={classNames('task-details-container', 'govuk-!-margin-bottom-2', classModifiers)}>
      <Heading className="govuk-!-margin-top-0" size="m">Trailer</Heading>
      <EnrichmentCount
        id="trailer"
        movementCount={EnrichmentUtil.movementCount(CommonUtil.movementStats(trailer))}
        examinationCount={EnrichmentUtil.examinationCount(CommonUtil.movementStats(trailer))}
        seizureCount={EnrichmentUtil.seizureCount(CommonUtil.movementStats(trailer))}
      />
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Trailer registration number',
          {
            content: TrailerUtil.registration(trailer),
            url: CommonUtil.entitySearchUrl(trailer),
          },
          TaskVersionUtil.hasAny(trailerDiff, [FIELDS.registration]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, trailer)),
          toPreviousMovementLink(trailer),
        )}
        {renderBlock(
          'Trailer type',
          {
            content: TrailerUtil.type(trailer),
          },
          TaskVersionUtil.hasAny(trailerDiff, [FIELDS.type]),
        )}
        {renderBlock(
          'Trailer country of registration',
          {
            content: CommonUtil.iso3Code(TrailerUtil.nationality(trailer)),
          },
          TaskVersionUtil.hasAny(trailerDiff, [FIELDS.nationality]),
        )}
        {renderBlock(
          'Empty or loaded',
          {
            content: TrailerUtil.loadStatus(trailer),
          },
          TaskVersionUtil.hasAny(trailerDiff, [FIELDS.loadStatus]),
        )}
        {renderBlock(
          'Trailer length',
          {
            content: FieldFormatterUtil.format.field(UNITS.DISTANCE.name, TrailerUtil.length(trailer)),
          },
          TaskVersionUtil.hasAny(trailerDiff, [FIELDS.length]),
        )}
        {renderBlock(
          'Trailer height',
          {
            content: FieldFormatterUtil.format.field(UNITS.DISTANCE.name, TrailerUtil.height(trailer)),
          },
          TaskVersionUtil.hasAny(trailerDiff, [FIELDS.height]),
        )}
      </div>
    </div>
  );
};

export default Trailer;
