// Global Import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { DIRECTION } from '../../../../../../../../../utils/TargetInformation/constants';

// Component(s)
import TimeCountDown from '../../../../../../../../../components/Uplift/Countdown/TimeCountDown';
import ComponentWrapper from '../../../../../../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import JourneyUtil from '../../../../../../../../../utils/Journey/Uplift/journeyUtil';
import toEventDate from '../../../../../../../TaskList/air/helper/toEventDate';

const DepartureArrivalCountDown = ({ journey, numberClassName, unitClassName, labelClassName }) => {
  const direction = JourneyUtil.direction(journey);
  const eventDate = toEventDate(journey);

  const toDepartureArrivalLabel = () => {
    if (direction === DIRECTION.OUTBOUND) {
      return 'departure';
    }
    return 'arrival';
  };

  return (
    <ComponentWrapper show={eventDate}>
      <TimeCountDown dateTime={eventDate} numberClassName={numberClassName} unitClassName={unitClassName} />
      &nbsp;
      <span className={labelClassName}>
        to {toDepartureArrivalLabel()}
      </span>
    </ComponentWrapper>
  );
};

DepartureArrivalCountDown.propTypes = {
  journey: PropTypes.shape({}).isRequired,
  numberClassName: PropTypes.string,
  unitClassName: PropTypes.string,
  labelClassName: PropTypes.string,
};

DepartureArrivalCountDown.defaultProps = {
  numberClassName: null,
  unitClassName: null,
  labelClassName: null,
};

export default DepartureArrivalCountDown;
