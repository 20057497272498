// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import AbuseTypesIdp from '../components/idp/AbuseTypes';
import OperationNameIdp from '../components/idp/OperationName';
import ReferralReasonIdp from '../components/idp/ReferralReason';
import TargetRecipientsIdp from '../components/idp/TargetRecipients';

const CreateTarget = ({
  componentErrors,
  formData,
  onChange,
  onChangeRecipient,
  onRemoveRecipient,
  onSetAddActionOption,
}) => {
  return (
    <>
      <Heading size="m">Target sheet recipients</Heading>
      <TargetRecipientsIdp
        componentErrors={componentErrors}
        formData={formData}
        onChangeRecipient={onChangeRecipient}
        onRemoveRecipient={(previouslySelectedRecipient) => {
          onSetAddActionOption((prev) => ({
            ...prev,
            previouslySelectedRecipient,
          }));
          onRemoveRecipient(previouslySelectedRecipient);
        }}
        onAddActionOption={() => {
          onSetAddActionOption((prev) => ({
            ...prev,
            show: true,
          }));
        }}
      />
      <AbuseTypesIdp onChange={onChange} componentErrors={componentErrors} formData={formData} />
      <ReferralReasonIdp onChange={onChange} componentErrors={componentErrors} formData={formData} />
      <OperationNameIdp onChange={onChange} formData={formData} />
      {/* "Select PNR data to include in the target sheet for HOIO" will go here */}
    </>
  );
};

CreateTarget.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChangeRecipient: PropTypes.func.isRequired,
  onRemoveRecipient: PropTypes.func.isRequired,
  onSetAddActionOption: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
};

CreateTarget.defaultProps = {
  componentErrors: null,
  formData: null,
};

export default memo(CreateTarget);
