// Global import(s)
import PropTypes from 'prop-types';
import { Heading } from '@ukhomeoffice/cop-react-components';
import React, { memo } from 'react';
import { useWindowSize } from 'react-use';

// Config
import { TIME_FORMATS } from '../../../../../../utils/constants';

// Context(s)
import { useTask } from '../../../../../../context/TaskContext';
import { useView } from '../../../../../../context/ViewContext';

// Component
import AdditionalContent from '../content/AdditionalContent';
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import Itinerary from '../../../../../Task/TaskDetails/CurrentTaskDetails/air/components/shared/movement/voyage/shared/Itinerary';
import PreviousNext from '../generics/PreviousNext';
import Details from '../../../../../Task/TaskDetails/CurrentTaskDetails/air/components/shared/generics/Details';
import LabelField from '../../../../../Task/TaskDetails/CurrentTaskDetails/air/components/shared/generics/LabelField';
import LabelValuePair from '../../../../../Task/TaskDetails/CurrentTaskDetails/air/components/shared/generics/LabelValuePair';
import TotalBaggage from './TotalBaggage';

// Util(s)
import { CommonUtil, TargetCommonUtil } from '../../../../../../utils';
import BookingUtil from '../../../../../../utils/Booking/bookingUtil';
import DateTimeUtil from '../../../../../../utils/Datetime/Uplift/datetimeUtil';
import ExtendedListRow from '../generics/ExtendedListRow';
import StringUtil from '../../../../../../utils/String/stringUtil';
import UpliftedCommonUtil from '../../../../../../utils/Common/Uplift/commonUtil';
import { shouldHideObjectArrayByAttr } from '../../../../../../utils/Hiding/hidingUtil';
import { isMobilePortrait } from '../../../../../../utils/TargetSheet/targetSheetUtil';

import './BookingDetails.scss';

const BookingDetails = ({ informationSheet }) => {
  const { additionalContent } = useTask();
  const { isVisibleTargetActionsToolbar } = useView();

  const booking = BookingUtil.get(informationSheet);
  const journey = informationSheet?.movement?.journey;
  const iso2CountryCode = booking?.bookingCountry?.isoCode;
  const formattedCountryName = BookingUtil.format.countryName(iso2CountryCode);
  const iso3CountryCode = UpliftedCommonUtil.iso3Code(iso2CountryCode);
  const dateBooked = BookingUtil.bookedAt(booking);
  const formattedDateBooked = DateTimeUtil.format.asUTC(dateBooked, TIME_FORMATS.UTC, TIME_FORMATS.BOOKING_CHECK_IN);
  const agent = BookingUtil.agent(booking);
  const formattedAgentDetails = StringUtil.format.address(BookingUtil.agentAddress(agent), true);
  const agentIata = BookingUtil.agentIataCode(booking);
  const telephoneNumbers = [BookingUtil.agentMobileContactNumber(agent), BookingUtil.agentTelephoneContactNumber(agent)].filter((contact) => !!contact).join(', ');
  const agentLocation = BookingUtil.agentLocation(agent);
  const formattedAgentLocation = BookingUtil.format.agentLocation(agentLocation);
  const payments = BookingUtil.payments(booking);
  const { width } = useWindowSize();
  const itinerary = journey?.itinerary;

  const bookingAdditionalContents = TargetCommonUtil.additionalContents(booking, additionalContent, 'BOOKING');
  const journeyAdditionalContents = TargetCommonUtil.additionalContents(journey, additionalContent, 'VOYAGE');

  const shouldShowPayments = () => {
    return !shouldHideObjectArrayByAttr(payments, 'type');
  };

  const getBookingType = () => {
    if (!booking?.bookingType) {
      return null;
    }

    return booking?.bookingType;
  };

  const getPnrLocator = () => {
    if (!booking?.pnrLocator) {
      return null;
    }

    return booking?.pnrLocator;
  };

  const getTicketType = () => {
    if (!booking?.ticketType) {
      return null;
    }

    return booking?.ticketType;
  };

  const toPaymentText = (payment) => {
    const card = BookingUtil.paymentCard(payment);
    const expiry = BookingUtil.cardExpiry(card);
    const formattedExpiry = BookingUtil.format.cardExpiry(expiry);
    const number = BookingUtil.cardLastFourDigits(card);
    const amount = BookingUtil.paymentAmount(payment);

    return `${payment?.type || ''} ${amount || ''} ${number ? `Card ****${number},` : ''} ${formattedExpiry ? `exp. ${formattedExpiry}` : ''}`;
  };

  const toPayments = () => {
    if (!payments?.length) {
      return [];
    }

    if (shouldHideObjectArrayByAttr(payments, 'type')) {
      return null;
    }

    return CommonUtil
      .uniqueId(payments)
      .map((payment) => {
        return (
          <>
            <span key={payment.id} id={`payment-${payment.id}`}>{toPaymentText(payment)}</span>
            <br />
          </>
        );
      });
  };

  const toAgentDetails = () => {
    if (!formattedAgentDetails) {
      return null;
    }
    const splitParts = formattedAgentDetails.split(',');
    return splitParts.map((part) => {
      return (
        <>
          <span>{part}</span>
          <br />
        </>
      );
    });
  };

  const getTimeDiff = () => {
    // Added extra negativity check as it caused the BVI to display an enormous
    // values (68 years etc) - this checks for any negative value and returns
    // null;
    if (!informationSheet?.movement?.bookingVsIntent || informationSheet?.movement?.bookingVsIntent < 0) {
      return null;
    }

    const bvi = BookingUtil.timeDifferenceAsStringFromMins(informationSheet?.movement?.bookingVsIntent);
    return `Booked ${bvi} before travel`;
  };

  return (
    <div id="booking-details" className="govuk-grid-row target-info-summary-list">
      <hr />
      <ComponentWrapper show={!isVisibleTargetActionsToolbar}>
        <PreviousNext
          id="booking-details"
          index={0}
          size={1}
        />
      </ComponentWrapper>
      <ComponentWrapper show={informationSheet?.movement?.booking}>
        <ComponentWrapper show={!isMobilePortrait(width)}>
          <Heading id="booking-details-header" size="l">Booking details</Heading>
        </ComponentWrapper>
        <ComponentWrapper show={isMobilePortrait(width)}>
          <h2 id="booking-details-header" className="ho-heading-m">Booking details</h2>
        </ComponentWrapper>
      </ComponentWrapper>
      <div className="govuk-grid-column-full">
        <div id="details" className="govuk-grid-row">
          <ExtendedListRow
            id="booking"
            type="header-first-booking"
            values={[
              'Booking',
              getTimeDiff(),
            ]}
          />
          <div id="booking-details-module">
            <Details
              items={[
                {
                  content: (
                    <ComponentWrapper key="1" show={iso2CountryCode && iso3CountryCode && formattedCountryName}>
                      <div className="grid-item">
                        <ComponentWrapper show={isMobilePortrait(width)}>
                          <LabelField
                            classNames="govuk-body-s font--grey mobile-label"
                            id="booking-country-label"
                            value="Booking country"
                          />
                        </ComponentWrapper>
                        <LabelValuePair
                          id="booking-country"
                          label={!isMobilePortrait(width) && 'Booking country'}
                          value={[formattedCountryName, iso3CountryCode].join(' - ')}
                        />
                      </div>
                    </ComponentWrapper>
                  ),
                },
                {
                  content: (
                    <ComponentWrapper key="2" show={getBookingType()}>
                      <div className="grid-item">
                        <ComponentWrapper show={isMobilePortrait(width)}>
                          <LabelField
                            classNames="govuk-body-s font--grey mobile-label"
                            id="booking-type-label"
                            value="Booking type"
                          />
                        </ComponentWrapper>
                        <LabelValuePair
                          id="booking-type"
                          label={!isMobilePortrait(width) && 'Booking type'}
                          value={getBookingType()}
                        />
                      </div>
                    </ComponentWrapper>
                  ),
                },
                {
                  content: (
                    <ComponentWrapper key="3" show={getPnrLocator()}>
                      <div className="grid-item">
                        <ComponentWrapper show={isMobilePortrait(width)}>
                          <LabelField
                            classNames="govuk-body-s font--grey mobile-label"
                            id="booking-pnr-locator-label"
                            value="PNR locator"
                          />
                        </ComponentWrapper>
                        <LabelValuePair
                          id="booking-pnr-locator"
                          label={!isMobilePortrait(width) && 'PNR locator'}
                          value={getPnrLocator()}
                        />
                      </div>
                    </ComponentWrapper>
                  ),
                },
                {
                  content: (
                    <ComponentWrapper key="4" show={getTicketType()}>
                      <div className="grid-item">
                        <ComponentWrapper show={isMobilePortrait(width)}>
                          <LabelField
                            classNames="govuk-body-s font--grey mobile-label"
                            id="booking-ticket-type-label"
                            value="Ticket type"
                          />
                        </ComponentWrapper>
                        <LabelValuePair
                          id="booking-ticket-type"
                          label={!isMobilePortrait(width) && 'Ticket type'}
                          value={getTicketType()}
                        />
                      </div>
                    </ComponentWrapper>
                  ),
                },
                {
                  content: (
                    <ComponentWrapper key="5" show={dateBooked && formattedDateBooked}>
                      <div className="grid-item">
                        <ComponentWrapper show={isMobilePortrait(width)}>
                          <LabelField
                            classNames="govuk-body-s font--grey mobile-label"
                            id="booking-booked-at-label"
                            value="Booking date and time"
                          />
                        </ComponentWrapper>
                        <LabelValuePair
                          id="booking-booked-at"
                          label={!isMobilePortrait(width) && 'Booking date and time'}
                          value={formattedDateBooked}
                        />
                      </div>
                    </ComponentWrapper>
                  ),
                },
                {
                  content: (
                    <ComponentWrapper key="6" show={agent && formattedAgentDetails}>
                      <div className="grid-item">
                        <ComponentWrapper show={isMobilePortrait(width)}>
                          <LabelField
                            classNames="govuk-body-s font--grey mobile-label"
                            id="booking-agent-details-label"
                            value="Agent details"
                          />
                        </ComponentWrapper>
                        <LabelValuePair
                          id="booking-agent-details"
                          label={!isMobilePortrait(width) && 'Agent details'}
                          value={toAgentDetails()}
                        />
                      </div>
                    </ComponentWrapper>
                  ),
                },
                {
                  content: (
                    <ComponentWrapper key="7" show={agent && agentIata}>
                      <div className="grid-item">
                        <ComponentWrapper show={isMobilePortrait(width)}>
                          <LabelField
                            classNames="govuk-body-s font--grey mobile-label"
                            id="booking-agent-iata-label"
                            value="Agent IATA"
                          />
                        </ComponentWrapper>
                        <LabelValuePair
                          id="booking-agent-iata"
                          label={!isMobilePortrait(width) && 'Agent IATA'}
                          value={agentIata}
                        />
                      </div>
                    </ComponentWrapper>
                  ),
                },
                {
                  content: (
                    <ComponentWrapper key="8" show={agent && agentLocation}>
                      <div className="grid-item">
                        <ComponentWrapper show={isMobilePortrait(width)}>
                          <LabelField
                            classNames="govuk-body-s font--grey mobile-label"
                            id="booking-agent-location-label"
                            value="Agent location"
                          />
                        </ComponentWrapper>
                        <LabelValuePair
                          id="booking-agent-location"
                          label={!isMobilePortrait(width) && 'Agent location'}
                          value={formattedAgentLocation}
                        />
                      </div>
                    </ComponentWrapper>
                  ),
                },
                {
                  content: (
                    <ComponentWrapper key="9" show={agent && telephoneNumbers}>
                      <div className="grid-item">
                        <ComponentWrapper show={isMobilePortrait(width)}>
                          <LabelField
                            classNames="govuk-body-s font--grey mobile-label"
                            id="booking-agent-telephone-label"
                            value="Phone number"
                          />
                        </ComponentWrapper>
                        <LabelValuePair
                          id="booking-agent-telephone"
                          label={!isMobilePortrait(width) && 'Phone number'}
                          value={telephoneNumbers}
                        />
                      </div>
                    </ComponentWrapper>
                  ),
                },
                {
                  content: (
                    <ComponentWrapper key="10" show={payments && payments.length && shouldShowPayments() && toPayments()}>
                      <div className="grid-item">
                        <ComponentWrapper show={isMobilePortrait(width)}>
                          <LabelField
                            classNames="govuk-body-s font--grey mobile-label"
                            id="booking-payments-label"
                            value="Payments"
                          />
                        </ComponentWrapper>
                        <LabelValuePair
                          id="booking-payments"
                          label={!isMobilePortrait(width) && 'Payments'}
                          value={toPayments()}
                        />
                      </div>
                    </ComponentWrapper>
                  ),
                },
              ]}
            />
          </div>
          <AdditionalContent id="booking-additional-content-container" additions={bookingAdditionalContents} heading="Addition" />
          <Itinerary itinerary={itinerary} />
          <AdditionalContent id="journey-additional-content-container" additions={journeyAdditionalContents} heading="Addition" />
          <TotalBaggage informationSheet={informationSheet} />
        </div>
      </div>
    </div>
  );
};

BookingDetails.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};

export default memo(BookingDetails);
