// Global import(s)
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

// Config(s)
import config from '../../../../../../utils/config';
import { FORM_MESSAGES, FILTER_TAB_ID } from '../../../../../../utils/constants';
import QUERY_KEYS from '../../../../../../utils/Hooks/constants';

// Context(s)
import { useKeycloak } from '../../../../../../context/Keycloak';
import { useTabs } from '../../../../../../context/TabContext';
import { useTasks } from '../../../../../../context/TasksContext';

// Services
import AxiosRequests from '../../../../../../api/axiosRequests';

// Component(s)
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import UpliftedForm from '../../../../TaskDetails/CurrentTaskDetails/air/components/shared/forms/UpliftedForm';

// Hook(s)
import { useAxiosInstance } from '../../../../../../utils/Axios/axiosInstance';

// Form
import bulkDeleteTask from '../../../../../../forms/uplift/bulkDeleteTask';

// Utils
import { CommonUtil } from '../../../../../../utils';

const BulkDeleteTask = ({ onClose, numberOfTasksToDelete, ruleName }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { setSelectedFilterTab } = useTabs();
  const { selectedForDelete, setSelectedForDelete, rules } = useTasks();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const onCancelConfirmation = (onCancel) => {
    // eslint-disable-next-line no-alert
    if (confirm(FORM_MESSAGES.ON_CANCELLATION)) {
      onCancel();
    }
  };

  const getRuleIds = () => {
    return rules;
  };

  const invalidateQueries = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TASKS] }),
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TASKS_FILTER_COUNTS] }),
    ]);
  };

  const navigateToToolsTab = () => {
    setSelectedFilterTab(FILTER_TAB_ID.TOOLS);
    navigate(CommonUtil.getListPath(location?.pathname));
  };

  const toPayload = (formData) => {
    return {
      ruleNames: getRuleIds(),
      taskIds: selectedForDelete,
      reason: formData?.reason,
      otherReasonDetail: formData?.otherReasonDetail ?? null,
      authoriser: formData?.authoriser,
    };
  };

  const onSubmit = async ({ data: formData }) => {
    setIsLoading(true);
    await AxiosRequests.bulkDelete(apiClient, toPayload(formData))
      .then(() => {
        setIsLoading(false);
        setSelectedForDelete([]);
        invalidateQueries();
        navigateToToolsTab();
        setSubmitted(true);
        onClose();
      })
      .catch(() => setIsLoading(false));
  };

  if (!submitted) {
    return (
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-two-thirds">
          <UpliftedForm
            form={bulkDeleteTask(numberOfTasksToDelete, ruleName)}
            fullScreenOnly
            onSubmit={onSubmit}
            onCancel={() => onCancelConfirmation(onClose)}
            overrideOnCancelConfirm
          />
        </div>
      </div>
    );
  }
};

BulkDeleteTask.propTypes = {
  onClose: PropTypes.shape({}).isRequired,
  numberOfTasksToDelete: PropTypes.number.isRequired,
  ruleName: PropTypes.string.isRequired,
};

export default BulkDeleteTask;
