import { Details } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import PropTypes from 'prop-types';

// Config(s)
import { EUROPE_LONDON, TIME_FORMATS } from '../../../../../../../../utils/constants';

// Components
import LabelValuePair from '../generics/LabelValuePair';

// Utils
import { CommonUtil } from '../../../../../../../../utils';
import DateTimeUtil from '../../../../../../../../utils/Datetime/Uplift/datetimeUtil';
import toFormattedIndicatorMatch from './helper/toFormattedIndicatorMatch';

// Styling
import './ThreatType.scss';

const ThreatTypeS4 = ({ rule, index }) => {
  const toFormattedDateTime = (value, timeFormat) => {
    return DateTimeUtil.local.format(value, EUROPE_LONDON, timeFormat);
  };

  return (
    <div key={`${rule?.name}-${index}`} className="govuk-grid-row matched-rule">
      <div className="govuk-grid-column-full">
        <Details id={`matched-rule-${rule?.name}`} summary={rule?.name}>
          <div className="govuk-grid-row govuk-!-margin-bottom-4">
            <div className="govuk-grid-column-one-half">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <p id={`rule-${rule?.name}-description-header`} className="rule-field-header">
                    Description
                  </p>
                </div>
              </div>
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <p id={`rule-${rule?.name}-description-value`}>
                    {rule?.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="govuk-grid-column-one-half">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <p id={`rule-${rule?.name}-threat-header`} className="rule-field-header">
                    Threat
                  </p>
                </div>
              </div>
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <p id={`rule-${rule?.name}-threat-value`}>
                    {rule?.threatType}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-one-quarter">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <p id={`rule-${rule?.name}-risk-indicators-header`} className="rule-field-header">
                    Risk Indicators
                  </p>
                </div>
              </div>
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <ol className="rule-risk-indicators">
                    {rule?.indicatorMatches && CommonUtil
                      .uniqueId(rule.indicatorMatches)
                      .map((indicatorMatch) => (
                        <li
                          key={`rule-${rule?.name}-${index}-risk-indicator-${indicatorMatch.id}`}
                          id={`rule-${rule?.name}-${index}-risk-indicator-value-${indicatorMatch.id}`}
                        >
                          {toFormattedIndicatorMatch(indicatorMatch)}
                        </li>
                      ),
                      )}
                  </ol>
                </div>
              </div>
            </div>
            <div className="govuk-grid-column-one-quarter">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full">
                      <p id={`rule-${rule?.name}-date-header`} className="rule-field-header">
                        Rule Date
                      </p>
                    </div>
                  </div>
                  <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full">
                      <LabelValuePair
                        id={`rule-${rule?.name}-date`}
                        value={(
                          <>
                            <span>{toFormattedDateTime(rule?.s4Rule?.date, TIME_FORMATS.VOYAGE.DAY_SHORT)}</span>
                            <span>{toFormattedDateTime(rule?.s4Rule?.date, TIME_FORMATS.VOYAGE.TIME_SHORT)}</span>
                          </>
                      )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="govuk-grid-column-one-quarter">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full">
                      <p id={`rule-${rule?.name}-agency-header`} className="rule-field-header">
                        Agency
                      </p>
                    </div>
                  </div>
                  <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full">
                      <p id={`rule-${rule?.name}-agency-value`} className="rule-agency">
                        {rule?.targetingTeam}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="govuk-grid-column-one-quarter">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full">
                      <p id={`rule-${rule?.name}-version-header`} className="rule-field-header">
                        Rule Version
                      </p>
                    </div>
                  </div>
                  <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full">
                      <p id={`rule-${rule?.name}-version-value`}>
                        {rule?.version}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-one-quarter">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <ol id={`rule-${rule?.name}-score-header`} className="s4-field-header">
                    Rule Score
                  </ol>
                  <ol id={`rule-${rule?.name}-score-value`} className="s4-field-value">
                    {rule?.s4Rule?.score}
                  </ol>
                </div>
              </div>
            </div>
            <div className="govuk-grid-column-one-quarter">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <ol id={`rule-${rule?.name}-status-header`} className="s4-field-header">
                    Status
                  </ol>
                  <ol id={`rule-${rule?.name}-status-value`} className="s4-field-value">
                    {rule?.s4Rule?.status}
                  </ol>
                </div>
              </div>
            </div>
            <div className="govuk-grid-column-one-quarter">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <ol id={`rule-${rule?.name}-status-date-header`} className="s4-field-header">
                    Status Date
                  </ol>
                  <ol className="s4-field-value">
                    <LabelValuePair
                      id={`rule-${rule?.name}-status-date`}
                      value={(
                        <>
                          <span>{toFormattedDateTime(rule?.s4Rule?.statusDate, TIME_FORMATS.VOYAGE.DAY_SHORT)}</span>
                          <span>{toFormattedDateTime(rule?.s4Rule?.statusDate, TIME_FORMATS.VOYAGE.TIME_SHORT)}</span>
                        </>
                    )}
                    />
                  </ol>
                </div>
              </div>
            </div>
            <div className="govuk-grid-column-one-quarter">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <ol id={`rule-${rule?.name}-replay-match-header`} className="s4-field-header">
                    Is Replay match?
                  </ol>
                  <ol id={`rule-${rule?.name}-replay-match-value`} className="s4-field-value">
                    {rule?.s4Rule?.replayMatch ? 'true' : 'false'}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </Details>
      </div>
    </div>
  );
};

ThreatTypeS4.propTypes = {
  rule: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
};

export default ThreatTypeS4;
