// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { TIME_FORMATS } from '../../../../../../../../../../utils/constants';

// Component(s)
import ComponentWrapper from '../../../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import Details from '../../../generics/Details';
import HeaderModule from '../../../generics/HeaderModule';
import LabelValuePair from '../../../generics/LabelValuePair';
import TimeCountDown from '../../../../../../../../../../components/Uplift/Countdown/TimeCountDown';
import ValueField from '../../../generics/ValueField';

// Util(s)
import JourneyUtil from '../../../../../../../../../../utils/Journey/Uplift/journeyUtil';
import toEventDate, { toEventTime } from '../../../../../../../../TaskList/air/helper/toEventDate';
import { TargetJourneyUtil } from '../../../../../../../../../../utils';
import { toDepartureArrivalLabel } from '../helper/common';

// Styling
import './Route.scss';

const Route = ({ journey }) => {
  const arrival = JourneyUtil.arrival(journey);
  const departure = JourneyUtil.departure(journey);
  const direction = JourneyUtil.direction(journey);
  const departureLocation = JourneyUtil.departureLoc(journey);
  const departureIata = JourneyUtil.iata(departure);
  const departureIcao = JourneyUtil.icao(departure);
  const departureTime = JourneyUtil.departureTime(journey);
  const arrivalLocation = JourneyUtil.arrivalLoc(journey);
  const arrivalTime = JourneyUtil.arrivalTime(journey);
  const arrivalIata = JourneyUtil.iata(arrival);
  const arrivalIcao = JourneyUtil.icao(arrival);

  const toAirportName = (icao, iata) => {
    if (!iata && !icao) {
      return null;
    }

    const getName = () => {
      let airportName;
      airportName = TargetJourneyUtil.format.airportName(icao);
      if (airportName) {
        return airportName;
      }

      airportName = TargetJourneyUtil.format.airportName(iata);
      if (airportName) {
        return airportName;
      }

      return null;
    };

    const airportName = getName();
    if (!airportName) {
      return null;
    }
    return airportName.replace(/airport/gi, '').trim();
  };

  return (
    <div id="route-module" className="govuk-grid-column-full">
      <HeaderModule
        id="route-details"
        items={[
          {
            content: (
              <div key="1" className="grid-item">
                <Heading id="route-header" size="m">Route</Heading>
              </div>
            ),
          },
          {
            content: (
              <ComponentWrapper show={departureLocation && arrivalLocation}>
                <div key="2" className="grid-item grid-item-span-3">
                  <ValueField
                    value={(
                      <>
                        <span id="departure-location" className="govuk-!-font-weight-bold">{departureLocation}</span>&nbsp;
                        <span id="departure-airport">{toAirportName(departureIcao, departureIata)}</span>&nbsp;&nbsp;
                        <span className="right-arrow">&#8594;</span>&nbsp;&nbsp;
                        <span id="arrival-location" className="govuk-!-font-weight-bold">{arrivalLocation}</span>&nbsp;
                        <span id="arrival-airport">{toAirportName(arrivalIcao, arrivalIata)}</span>&nbsp;
                      </>
                  )}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper show={departureTime && arrivalTime && departureLocation && arrivalLocation}>
                <div key="3" className="grid-item">
                  <ValueField value={(
                    <>
                      <span className="govuk-!-font-weight-bold">
                        <TimeCountDown dateTime={toEventDate(journey)} summary />
                      </span>
                    &nbsp;
                      <span>
                        {toDepartureArrivalLabel(direction)}
                      </span>
                    </>
                )}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      <Details
        items={[
          {
            content: (
              <ComponentWrapper show={departureTime && departureLocation}>
                <div key="1" className="grid-item">
                  <LabelValuePair
                    id="route-departure"
                    label="Departs"
                    value={(
                      <>
                        <span>{toEventTime(departureTime, departureLocation, TIME_FORMATS.VOYAGE.DAY_SHORT)}</span>
                        <span>{toEventTime(departureTime, departureLocation, TIME_FORMATS.VOYAGE.TIME_SHORT)}</span>
                      </>
                  )}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper show={arrivalTime && arrivalLocation}>
                <div key="2" className="grid-item">
                  <LabelValuePair
                    id="route-arrival"
                    label="Arrives"
                    value={(
                      <>
                        <span>{toEventTime(arrivalTime, arrivalLocation, TIME_FORMATS.VOYAGE.DAY_SHORT)}</span>
                        <span>{toEventTime(arrivalTime, arrivalLocation, TIME_FORMATS.VOYAGE.TIME_SHORT)}</span>
                      </>
                  )}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
    </div>
  );
};

Route.propTypes = {
  journey: PropTypes.shape({}).isRequired,
};

export default Route;
