import React from 'react';

const SeizuresSection = ({ seizures }) => {
  if (!seizures?.length) {
    return null;
  }

  return (
    <section id="previous-task-list-seizures-section" className="previous-task-list-seizures-section">
      <p className="govuk-!-font-weight-bold">Seizures:</p>
      {seizures?.map((seizure) => {
        return (
          <div className="govuk-grid-row section-body govuk-!-margin-bottom-1" key={seizure?.description}>
            <span className="govuk-grid-column-one-half seizure-description">
              {seizure?.description}
            </span>
            <span className="govuk-grid-column-one-half seizure-quantity">
              {seizure?.quantity}
            </span>
          </div>
        );
      })}
    </section>
  );
};

export default SeizuresSection;
