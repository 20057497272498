import { FILTER_TYPES } from '../../../routes/Task/TaskList/shared/constants';

const toCountsFieldIdIfRequired = (component) => {
  if (component?.countsFieldId) {
    return component.countsFieldId;
  }

  return component.fieldId;
};

const getCountForOption = ({ component,
  value,
  selectedTab,
  filterCounts }) => {
  const fieldId = toCountsFieldIdIfRequired(component);
  if (fieldId === FILTER_TYPES.JOURNEY_DIRECTIONS) {
    return filterCounts?.directionCounts?.find((f) => {
      return f.filterParams[fieldId]?.includes(value) || false;
    })?.statusCounts[selectedTab];
  }
  if (fieldId === FILTER_TYPES.SELECTORS) {
    return filterCounts?.selectorCounts?.find((f) => {
      return f.filterParams[fieldId] === value;
    })?.statusCounts[selectedTab];
  }
  if (fieldId === FILTER_TYPES.MODE) {
    return filterCounts?.movementModeCounts?.find((f) => {
      return f.filterParams.movementModes[0] === value;
    })?.statusCounts[selectedTab];
  }
  if (fieldId === FILTER_TYPES.PASSENGER_STATUSES) {
    return filterCounts?.passengerStatusCounts?.find((f) => {
      return f.filterParams[fieldId]?.includes(value) || false;
    })?.statusCounts[selectedTab];
  }
  if (fieldId === FILTER_TYPES.TARGETING_TEAMS) {
    return filterCounts?.targetingTeamCounts?.find((f) => {
      return f.filterParams[fieldId]?.includes(value) || false;
    })?.statusCounts[selectedTab];
  }
  if (fieldId === FILTER_TYPES.RULES) {
    return filterCounts?.ruleCounts?.find((f) => {
      return f.filterParams[fieldId]?.includes(value) || false;
    })?.statusCounts[selectedTab];
  }
  return 0;
};

const setupOptions = (component, selectedTab, filterCounts) => {
  return component.data.options.map((opt) => {
    const count = getCountForOption({ component,
      value: opt.value,
      selectedTab,
      filterCounts }) ?? 0;
    const originalLabel = opt.originalLabel || opt.label;
    const label = count === null ? originalLabel : `${originalLabel} (${count})`;
    return {
      ...opt,
      count,
      originalLabel,
      label,
    };
  });
};

const setupComponentOptions = (component, selectedTab, filterCounts) => {
  if (component.data?.options && component.dynamicOptions) {
    component.data.options = setupOptions(component, selectedTab, filterCounts);
  }
  return component;
};

const setupFilterTaskCounts = (form, selectedTab, filterCounts) => {
  form.pages = form.pages.map((page) => {
    return {
      ...page,
      components: page.components.map((component) => {
        return setupComponentOptions(component, selectedTab, filterCounts);
      }),
    };
  });
  return form;
};

export default setupFilterTaskCounts;
