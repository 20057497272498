// Global import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Util(s)
import getSourceLink from '../helper/getSourceLink';

const SourceSection = ({ movementMode, source }) => {
  const link = getSourceLink(source, movementMode);

  if (!link) {
    return null;
  }

  return (
    <div className="align-right">
      {link}
    </div>
  );
};

SourceSection.propTypes = {
  source: PropTypes.shape({}).isRequired,
  movementMode: PropTypes.string.isRequired,
};

export default SourceSection;
