import { FormGroup, Radios, VisuallyHidden } from '@ukhomeoffice/cop-react-components';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

// Component(s)
import Comment from './Comment';
import ComponentWrapper from '../../../../../../../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import toTransformedFieldName from './helper/toTransformedFieldName';

// Styling
import './Trace.scss';

const Trace = ({ id,
  uniqueId,
  onChange,
  formData,
  containerId,
  required,
  error,
  system,
  value: _value,
  commentError,
  commentEnabled,
  sourceValuePath: _sourceValuePath,
  commentSourceValuePath }) => {
  const [value, setValue] = useState(_value || formData?.[containerId]?.otherChecks?.[system] || {});
  const componentUniqueId = [id, uniqueId].filter((item) => !!item || item === 0).join('/');
  const sourceValuePath = _sourceValuePath || id;

  const onInternalOnChange = (event) => {
    const { target } = event;
    const cleanedName = toTransformedFieldName(target.name.slice(0, -1));

    setValue((prev) => ({
      ...prev,
      [cleanedName]: target.value,
    }));
  };

  useEffect(() => {
    onChange({
      target: {
        name: system,
        value,
      },
    });
  }, [value]);

  return (
    <FormGroup
      id={componentUniqueId}
      label={<></>}
      required={required}
      error={error}
    >
      <VisuallyHidden id={componentUniqueId} aria-hidden />
      <Radios
        id={componentUniqueId}
        fieldId={componentUniqueId}
        classModifiers="inline"
        options={[
          {
            value: 'TRACE',
            label: 'Trace',
          },
          {
            value: 'NO_TRACE',
            label: 'No trace',
          },
        ]}
        onChange={onInternalOnChange}
        value={get(value, sourceValuePath || id)}
      />
      <ComponentWrapper show={commentEnabled && get(value, sourceValuePath) === 'TRACE'}>
        <Comment
          id={`${id}Comment`}
          uniqueId={uniqueId}
          onChange={onInternalOnChange}
          containerId={containerId}
          formData={value}
          required={required}
          error={commentError}
          sourceValuePath={commentSourceValuePath || `${sourceValuePath}Comment`}
        />
      </ComponentWrapper>
    </FormGroup>
  );
};

Trace.propTypes = {
  id: PropTypes.string.isRequired,
  uniqueId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  containerId: PropTypes.string.isRequired,
  formData: PropTypes.shape({}),
  value: PropTypes.shape({}),
  required: PropTypes.bool,
  commentEnabled: PropTypes.bool,
  error: PropTypes.string,
  system: PropTypes.string.isRequired,
  commentError: PropTypes.string,
  sourceValuePath: PropTypes.string,
  commentSourceValuePath: PropTypes.string,
};

Trace.defaultProps = {
  uniqueId: null,
  formData: null,
  value: null,
  required: true,
  commentEnabled: true,
  error: null,
  commentError: null,
  sourceValuePath: 'trace',
  commentSourceValuePath: 'comment',
};

export default memo(Trace);
