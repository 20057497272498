// Global import(s)
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { useWindowSize } from 'react-use';
import { useParams } from 'react-router-dom';

// Config(s)
import config from '../../../../../../../utils/config';

// Context
import { useFullScreen } from '../../../../../../../context/FullScreenContext';
import { useTask } from '../../../../../../../context/TaskContext';
import { useView } from '../../../../../../../context/ViewContext';

// Component
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import LinkButton from '../../../../../../../components/Buttons/LinkButton';
import LoadingSpinner from '../../../../../../../components/LoadingSpinner/LoadingSpinner';
import PersonDetailFullScreen from './PersonDetailFullScreen';

// Hook(s)
import useFetchImage from '../../../../../../../utils/Hooks/useFetchImage';

// Util
import { isMobilePortrait } from '../../../../../../../utils/TargetSheet/targetSheetUtil';

// Styling
import './Photo.scss';

const Photo = ({ personPosition, photoContent, isPersonOfInterest }) => {
  const { url, isLoading } = useFetchImage(photoContent?.url);
  const { width } = useWindowSize();
  const { setFullScreen, closeFullScreen } = useFullScreen();
  const { setIsUseFullScreen, isTargetsPath } = useView();

  let { targetId } = useParams();
  const { task } = useTask();
  targetId = isTargetsPath ? targetId : task?.targetId;

  const handleClosePhotoDetail = () => {
    setIsUseFullScreen(false);
    closeFullScreen();
  };

  const handleOpenPhotoDetail = () => {
    // Just for desktop at the moment
    if (isMobilePortrait(width)) {
      return;
    }

    // Only manually override fullscreen for task path
    setIsUseFullScreen(!isTargetsPath);
    setFullScreen(
      <PersonDetailFullScreen
        onClose={handleClosePhotoDetail}
        personPosition={isPersonOfInterest ? 0 : personPosition + 1}
        targetId={targetId}
        href={`#passenger-${personPosition}-photo`}
      />,
    );
  };

  useEffect(() => {
    return () => URL.revokeObjectURL(url);
  }, []);

  if (!url) {
    return null;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="target-photo">
      <LinkButton
        onClick={handleOpenPhotoDetail}
      >
        <img
          id={`passenger-${personPosition}-photo`}
          alt={`Taken ${photoContent?.approximateDateTaken}`}
          className="passport-photo hods-file-upload__thumb"
          src={url}
        />
      </LinkButton>
      <ComponentWrapper show={photoContent && config.enableUpliftTargetSheet}>
        <div>
          <div id={`passenger-${personPosition}-photo-date-label`}>
            <span>Date of photo:</span> {photoContent?.approximateDateTaken}
          </div>
        </div>
      </ComponentWrapper>
    </div>
  );
};

Photo.propTypes = {
  personPosition: PropTypes.number,
  photoContent: PropTypes.shape({
    url: PropTypes.string,
    source: PropTypes.string,
    filename: PropTypes.string,
    approximateDateTaken: PropTypes.string,
  }),
  isPersonOfInterest: PropTypes.bool,
};

Photo.defaultProps = {
  personPosition: 0,
  photoContent: null,
  isPersonOfInterest: false,
};

export default memo(Photo);
