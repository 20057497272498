import React from 'react';
import { Heading } from '@ukhomeoffice/cop-react-components';
import { useWindowSize } from 'react-use';

// Components
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import Details from '../../../../../Task/TaskDetails/CurrentTaskDetails/air/components/shared/generics/Details';
import LabelField from '../../../../../Task/TaskDetails/CurrentTaskDetails/air/components/shared/generics/LabelField';
import LabelValuePair from '../../../../../Task/TaskDetails/CurrentTaskDetails/air/components/shared/generics/LabelValuePair';
import SubHeaderModule from '../../../../../Task/TaskDetails/CurrentTaskDetails/air/components/shared/generics/SubHeaderModule';

// Utils
import { isMobilePortrait } from '../../../../../../utils/TargetSheet/targetSheetUtil';
import TargetBaggageUtil from '../../../../../../utils/Baggage/Target/targetBaggageUtil';
import BaggageUtil from '../../../../../../utils/Baggage/baggageUtil';

const TotalBaggage = ({ informationSheet }) => {
  const { width } = useWindowSize();

  const baggage = BaggageUtil.get(informationSheet);

  if (!baggage) {
    return null;
  }

  return (
    <div id="total-baggage" className="booking-sub-module">
      <SubHeaderModule
        items={[
          {
            content: (
              <div key="1" className="grid-item">
                <Heading id="total-baggage-header" size="s">Baggage Total</Heading>
              </div>
            ),
          },
        ]}
      />
      <Details
        items={[
          {
            content: (
              <div className="grid-item" key={1}>
                <ComponentWrapper show={isMobilePortrait(width)}>
                  <LabelField
                    classNames="govuk-body-s font--grey mobile-label"
                    id="baggage-count-label"
                    value="Total checked bags"
                  />
                </ComponentWrapper>
                <LabelValuePair
                  id="baggage-weight"
                  label={!isMobilePortrait(width) && 'Total checked bags'}
                  value={TargetBaggageUtil.totalCheckedBags(baggage)}
                />
              </div>
            ),
          },
          {
            content: (
              <div className="grid-item" key={2}>
                <ComponentWrapper show={isMobilePortrait(width)}>
                  <LabelField
                    classNames="govuk-body-s font--grey mobile-label"
                    id="baggage-weight-label"
                    value="Total weight"
                  />
                </ComponentWrapper>
                <LabelValuePair
                  id="baggage-weight"
                  label={!isMobilePortrait(width) && 'Total weight'}
                  value={TargetBaggageUtil.totalWeight(baggage)}
                />
              </div>
            ),
          },
          {
            content: (
              <div className="grid-item" key={3}>
                <ComponentWrapper show={isMobilePortrait(width)}>
                  <LabelField
                    classNames="govuk-body-s font--grey mobile-label"
                    id="baggage-tags-label"
                    value="Tags"
                  />
                </ComponentWrapper>
                <LabelValuePair
                  id="baggage-tags"
                  label={!isMobilePortrait(width) && 'Tags'}
                  value={TargetBaggageUtil.tags(baggage)}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default TotalBaggage;
