import { Button, Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { CONTENT_TYPE, TIME_FORMATS } from '../../../../../../../../utils/constants';

// Component(s)
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import ValueField from '../generics/ValueField';

// Util(s)
import { toTimestamp, toCreator } from '../../../helper/toContent';

// Styling
import './TextContent.scss';

const TextContent = ({ content, openEditor }) => {
  return (
    <>
      <Heading size="s">Addition</Heading>
      <div id="text-content-container">
        <div className="grid-item grid-item-span-3">
          <ValueField
            id={`content-added-${content.id}`}
            value={content?.content}
          />
        </div>
        <div className="grid-item">
          <ValueField
            id={`content-timestamp-${content.id}`}
            value={toTimestamp({ timestamp: content?.timestamp }, TIME_FORMATS.CONTENT)}
          />
        </div>
        <div className="grid-item content-creator-edit-control">
          <ValueField
            id={`content-creator-${content.id}`}
            value={toCreator({ user: content?.user })}
          />
          <ComponentWrapper show={content?.editable}>
            <Button
              id={`content-edit-${content.id}`}
              classModifiers="secondary"
              onClick={openEditor}
            >
              Edit
            </Button>
          </ComponentWrapper>
        </div>
      </div>
    </>
  );
};

TextContent.propTypes = {
  content: PropTypes.shape({
    type: (type) => type === CONTENT_TYPE.TEXT,
    id: PropTypes.string.isRequired,
    user: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
    timestamp: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  openEditor: PropTypes.func.isRequired,
};

export default TextContent;
