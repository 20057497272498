import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';

import BorderEventAttachments from './BorderEventAttachments';
import SubmittedDetail from './SubmittedDetail';

import './Timeline.scss';

const Timeline = ({ attachments, submissions }) => {
  const toAttachments = () => {
    if (!Array.isArray(attachments) || !attachments?.length) {
      return null;
    }

    return (
      <div className="govuk-!-padding-top-6">
        <Heading size="m">Attachments</Heading>
        <div className="govuk-!-padding-top-4">
          <BorderEventAttachments attachments={attachments} />
        </div>
      </div>
    );
  };

  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-full">
        <Heading size="m">Timeline</Heading>
        <div className="govuk-!-padding-top-4">
          {submissions.map((submission) => <SubmittedDetail key={submission.form.versionId} submission={submission} />)}
        </div>
        {toAttachments()}
      </div>
    </div>
  );
};

export default Timeline;
