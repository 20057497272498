import React from 'react';
import PropTypes from 'prop-types';

import ComponentWrapper from '../../../../components/ComponentWrapper/ComponentWrapper';
import ActionedStatus from '../../TaskDetails/CurrentTaskDetails/air/components/shared/status/ActionedStatus';

const PreviousTaskAction = ({ actionType, action, taskStatus, isDismissedByTargeter, isWithdrawnByTargeter }) => {
  const label = actionType === 'targeterAction' ? 'Targeter action' : 'Outcome';

  return (
    <div>
      <ComponentWrapper show={!!action}>
        <p className="govuk-!-font-weight-bold">
          {`${label}:`} &nbsp; <ActionedStatus
            as="span"
            taskStatus={taskStatus}
            isDismissedByTargeter={isDismissedByTargeter}
            isWithdrawnByTargeter={isWithdrawnByTargeter}
            {...({ [actionType]: action })}
          />
        </p>
        <p>{action?.reason}</p>
      </ComponentWrapper>
    </div>
  );
};

PreviousTaskAction.propTypes = {
  actionType: PropTypes.oneOf(['targeterAction', 'frontlineAction']).isRequired,
  action: PropTypes.shape({
    outcome: PropTypes.string,
    reason: PropTypes.string,
  }),
  taskStatus: PropTypes.string.isRequired,
  isDismissedByTargeter: PropTypes.bool.isRequired,
  isWithdrawnByTargeter: PropTypes.bool.isRequired,
};

PreviousTaskAction.defaultProps = {
  action: null,
};

export default PreviousTaskAction;
