// Global import(s)
import { Checkboxes, FormGroup, Heading, TextInput } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { COMPONENT_IDS, FRONTLINE_CHECK_VALUE_OPTIONS, FRONTLINE_CHECK_OPTION_VALUES } from '../constants';

// Util(s)
import StringUtil from '../../../../../../../../../utils/String/stringUtil';

// Styling
import './FrontlineChecks.scss';

const Checks = ({ componentErrors, option, onChange, data }) => {
  const componentId = `${option.nestedId}`;
  const otherComponentId = `${option.nestedId}OtherDetail`;
  const otherTextDisplay = StringUtil.format.camelToLowerCase(option.nestedId);

  if (componentId === COMPONENT_IDS.ADDITIONAL) {
    return (
      <FormGroup
        error={componentErrors?.additionalChecks?.message}
        label={<></>}
      >
        <TextInput
          id={componentId}
          fieldId={componentId}
          className="govuk-!-margin-top-5 govuk-!-margin-bottom-5"
          onChange={onChange}
          value={data?.[componentId]}
        />
      </FormGroup>
    );
  }

  return (
    <FormGroup
      label={<></>}
      error={componentErrors?.[componentId]?.message}
    >
      <Checkboxes
        className="frontline-checkbox-child"
        id={componentId}
        fieldId={componentId}
        options={[
          ...FRONTLINE_CHECK_VALUE_OPTIONS[option.value],
          {
            label: `Other ${otherTextDisplay} (please specify)`,
            value: 'other',
            children: (
              <FormGroup
                error={componentErrors?.[otherComponentId]?.message}
                label={<></>}
              >
                <TextInput
                  id={otherComponentId}
                  fieldId={otherComponentId}
                  className="govuk-!-margin-bottom-5"
                  onChange={onChange}
                  value={data?.[otherComponentId] || null}
                />
              </FormGroup>
            ),
          },
        ]}
        onChange={onChange}
        value={data?.[componentId] || null}
      />
    </FormGroup>
  );
};

const FrontlineChecks = ({ componentErrors, onChange, formData }) => {
  return (
    <FormGroup
      hint="Select all that apply for the alert"
      label={<Heading size="m">Checks for frontline</Heading>}
    >
      <Checkboxes
        className="govuk-!-margin-bottom-3 frontline-checkbox-top"
        id={COMPONENT_IDS.FRONTLINE_CHECKS}
        fieldId={COMPONENT_IDS.FRONTLINE_CHECKS}
        options={[
          {
            value: FRONTLINE_CHECK_OPTION_VALUES.IMMIGRATION_CHECKS,
            label: 'Immigration checks',
            nestedId: COMPONENT_IDS.IMMIGRATION,
          },
          {
            value: FRONTLINE_CHECK_OPTION_VALUES.CUSTOMS_CHECKS,
            label: 'Custom checks',
            nestedId: COMPONENT_IDS.CUSTOMS,
          },
          {
            value: FRONTLINE_CHECK_OPTION_VALUES.INTELLIGENCE_GATHERING_CHECKS,
            label: 'Intelligence gathering checks',
            nestedId: COMPONENT_IDS.INTELLIGENCE,
          },
          {
            value: FRONTLINE_CHECK_OPTION_VALUES.CREW_CHECKS,
            label: 'Crew checks',
            nestedId: COMPONENT_IDS.CREW,
          },
          {
            value: FRONTLINE_CHECK_OPTION_VALUES.ADDITIONAL_CHECKS,
            label: 'Additional checks (please specify)',
            nestedId: COMPONENT_IDS.ADDITIONAL,
          },
        ].map((option) => ({
          ...option,
          children: <Checks componentErrors={componentErrors} data={formData} option={option} onChange={onChange} />,
        }))}
        onChange={onChange}
        value={formData?.[COMPONENT_IDS.FRONTLINE_CHECKS] || []}
      />
    </FormGroup>
  );
};

FrontlineChecks.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
};

FrontlineChecks.defaultProps = {
  componentErrors: null,
  formData: null,
};

Checks.propTypes = {
  componentErrors: PropTypes.shape({}),
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    nestedId: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
};

Checks.defaultProps = {
  componentErrors: null,
  data: null,
};

export default memo(FrontlineChecks);
