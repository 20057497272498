// Config(s)
import { STRINGS } from '../../../../../../../../../utils/constants';

// Util(s)
import validateRootChecks from './validateRootChecks';
import validatePncCheck from './validatePncCheck';
import validateOtherChecks from './validateOtherChecks';

const bypassPncChecksValidation = (checks, bypassPncValidation) => {
  if (bypassPncValidation) {
    return true;
  }

  return checks?.includes(STRINGS.FORM.NOT_REQUIRED);
};

const mergeErrors = (errors) => {
  if (!errors?.length) {
    return null;
  }

  return errors.reduce((prev, current) => {
    return {
      ...prev,
      [current.componentId]: current.message,
    };
  }, {});
};

const validateCredibilityChecks = async (addError, setSummaryErrors, formData, bypassPncValidation, triggerType) => {
  const allErrors = [];

  Object.keys(formData).forEach((containerId) => {
    const entityData = formData[containerId];
    const entityIndex = containerId.charAt(containerId.length - 1);

    // Root error (no addition has been made).
    let error = validateRootChecks(entityData, entityIndex);
    if (error) {
      addError(containerId, { [error.componentId]: error.message });
      allErrors.push({ componentId: error.componentId, message: error.message });
      // Do not validate the rest of checks when no data has been added.
      return;
    }

    if (!bypassPncChecksValidation(formData?.[containerId]?.otherChecks?.checks, bypassPncValidation)) {
      // Validate PNC checks and aggregate other checks errors
      error = validatePncCheck(entityData, entityIndex, triggerType);
      if (error) {
        allErrors.push({ componentId: error.componentId, message: error.message });
      }
    }

    const errors = validateOtherChecks(entityData, entityIndex);
    if (errors || errors?.length) {
      errors.forEach((errorItem) => allErrors.push({ componentId: errorItem.componentId, message: errorItem.message }));
    }

    const mergedErrors = mergeErrors(allErrors);
    addError(containerId, mergedErrors);
  });

  setSummaryErrors(allErrors.map(({ componentId, message }) => ({
    id: componentId,
    error: message,
  })));
  return allErrors;
};

export default validateCredibilityChecks;
