import { Heading, Link } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

// Config(s)
import { SCRIM_TYPE } from '../../../../../../../../utils/constants';

// Contexts
import { useScrim } from '../../../../../../../../context/ScrimContext';

// Component(s)
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';

// Styling
import './EntitySearchModal.scss';

const Modal = ({ id, heading, sipURL, centaurURL, dismissModal }) => {
  const containerRef = useRef(null);
  const { closeScrim } = useScrim();

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      closeScrim();
      dismissModal();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div id={id} ref={containerRef} className="govuk-grid-row entity-search-modal">
      <div>
        <Heading size="s">{heading}</Heading>
      </div>
      <div>
        <ComponentWrapper show={centaurURL}>
          <Link id="centaur-link" href={centaurURL} target="_blank" rel="noreferrer noopener">
            Centaur
          </Link>
        </ComponentWrapper>
      </div>
      <div>
        <ComponentWrapper show={sipURL}>
          <Link id="sip-link" href={sipURL} target="_blank" rel="noreferrer noopener">
            SIP
          </Link>
        </ComponentWrapper>
      </div>
    </div>
  );
};

const EntitySearchModal = ({ id, label, modalHeading, sipURL, centaurURL }) => {
  const [openModal, setOpenModal] = useState(false);
  const { openScrim } = useScrim();

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        id={id}
        onClick={(e) => {
          e.preventDefault();
          openScrim(SCRIM_TYPE.FULL);
          setOpenModal(true);
        }}
      >
        {label}
      </Link>
      <ComponentWrapper show={openModal}>
        <Modal
          id={`${id}-modal`}
          heading={modalHeading}
          sipURL={sipURL}
          centaurURL={centaurURL}
          dismissModal={() => setOpenModal(false)}
        />
      </ComponentWrapper>
    </>
  );
};

EntitySearchModal.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  modalHeading: PropTypes.string.isRequired,
  sipURL: PropTypes.string,
  centaurURL: PropTypes.string,
};

EntitySearchModal.defaultProps = {
  id: '',
  sipURL: null,
  centaurURL: null,
};

Modal.propTypes = {
  id: PropTypes.string,
  heading: PropTypes.string.isRequired,
  sipURL: PropTypes.string,
  centaurURL: PropTypes.string,
  dismissModal: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  id: '',
  sipURL: null,
  centaurURL: null,
};

export default EntitySearchModal;
