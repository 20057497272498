// Global import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Util(s)
import FlightUtil from '../../../../../../../../../utils/Flight/Uplift/flightUtil';

// Styling
import './FlightNumber.scss';

const FlightNumber = ({ flight, className }) => {
  const flightNumber = FlightUtil.flightNumber(flight);

  return (
    <div className="flight-number">
      <span className={className}>
        {flightNumber}
      </span>
    </div>
  );
};

FlightNumber.propTypes = {
  flight: PropTypes.shape({}),
  className: PropTypes.string,
};

FlightNumber.defaultProps = {
  flight: null,
  className: null,
};

export default FlightNumber;
