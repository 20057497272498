// Global import(s)
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';
import { useTabs } from '../../context/TabContext';
import { useTasks } from '../../context/TasksContext';
import { useView } from '../../context/ViewContext';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

// Utils
import { filterParamsToRuleNames } from '../../routes/Task/TaskList/shared/adaptFilters';

const useFetchSingleTask = (requestParams, offset) => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { setTasks, fetchSingleTask, setFetchSingleTask, tasks: remainingTasks } = useTasks();
  const { view } = useView();

  const getTasks = async (params) => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      return [];
    }

    const newRequestParams = {
      ...params,
      pageParams: {
        ...params.pageParams,
        limit: 1,
        offset: offset - 1,
      },
    };
    return AxiosRequests.getTasks(apiClient, filterParamsToRuleNames(newRequestParams));
  };

  const { data: tasks } = useQuery({
    queryKey: [QUERY_KEYS.SINGLE_TASK, requestParams],
    queryFn: () => getTasks(requestParams),
    initialData: [],
    cacheTime: 0,
    enabled: fetchSingleTask && !!requestParams?.filterParams?.taskModes?.length,
  });

  useEffect(() => {
    if (tasks.length) {
      const updatedTasks = [...remainingTasks, ...tasks];
      setTasks(updatedTasks);
      setFetchSingleTask(!fetchSingleTask);
    }
    return () => AxiosRequests.cancel(source);
  }, [tasks]);
};

export default useFetchSingleTask;
