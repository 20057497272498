// Global import(s)
import React from 'react';

// Context(s)
import { usePermission } from '../../../context/PermissionContext';
import { useTabs } from '../../../context/TabContext';
import { useView } from '../../../context/ViewContext';

// Component(s)
import InternalHeader from './shared/components/InternalHeader';
import InternalRoroTaskListPage from './roro/InternalRoroTaskListPage';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { VIEW } from '../../../utils/constants';
import InternalAirTaskListPage from './air/InternalAirTaskListPage';

const TaskListPage = () => {
  const { DEFAULTS } = useTabs();
  const { canReadTask, hasRole } = usePermission();
  const { view, isSettingView } = useView();

  if (isSettingView) {
    return <LoadingSpinner />;
  }

  if (!canReadTask || !view || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
    return (
      <>
        <InternalHeader
          headerLabel={DEFAULTS?.[view]?.headers?.title}
          links={DEFAULTS?.[view]?.headers?.links}
          size="xl"
        />
        <p id="unauthorised-message" aria-label="You are not authorised to view these tasks">
          You are not authorised to view these tasks.
        </p>
      </>
    );
  }

  if ([VIEW.RORO, VIEW.RORO_IO].includes(view)) {
    return <InternalRoroTaskListPage key={view} />;
  }

  return <InternalAirTaskListPage key={view} />;
};

export default TaskListPage;
