/* eslint-disable no-template-curly-in-string */
const getForm = () => {
  return {
    note: 'IMPORTANT: This form has been composed. Make changes to the relevant components, not this file, or your changes may get lost.',
    id: 'cerberus-roro-target-information-sheet',
    version: '1.7.0',
    name: 'cerberus-roro-target-information-sheet',
    title: 'Target Information Sheet (RoRo)',
    type: 'wizard',
    components: [
      {
        id: 'arrivalPort',
        fieldId: 'arrivalPort',
        label: 'Port',
        'aria-label': 'port',
        hint: 'The port that the target is scheduled to arrive at',
        type: 'autocomplete',
        item: {
          value: 'id',
          label: 'name',
        },
        required: true,
        clearable: true,
        openOnClick: true,
        placeholder: '',
        data: {
          url: '${environmentContext.referenceDataUrl}/v2/entities/port?skip=0&sort=name.asc&select=id,name,municipality&filter=countryid=eq.234&filter=sea=eq.t&filter=nonoperational=eq.false&mode=dataOnly&validDateTime=true',
        },
        show_when: {
          field: './direction',
          op: 'eq',
          value: 'INBOUND',
        },
      },
      {
        id: 'controlStrategies',
        fieldId: 'controlStrategies',
        label: 'Threat type/ National Control Strategy',
        'aria-label': 'threat type/ national control strategy',
        role: 'complementary',
        type: 'autocomplete',
        placeholder: '',
        required: true,
        openOnClick: true,
        closeOnSelect: false,
        multi: true,
        item: {
          value: 'id',
          label: 'strategy',
          priority: 'priority',
          format: '${label} / Control Strategy ${priority}',
        },
        data: {
          url: '${environmentContext.referenceDataUrl}/v2/entities/controlstrategy?sort=strategy.asc&mode=dataOnly&validDateTime=true',
        },
        custom_errors: [
          {
            type: 'required',
            message: 'You must select a Threat Type/ National Control Strategy',
          },
        ],
      },
      {
        id: 'nominalChecks',
        fieldId: 'nominalChecks',
        label: 'Checks completed on nominals',
        type: 'collection',
        labels: {
          item: 'Nominal ${index} details',
          add: 'Add another nominal',
        },
        item: [
          {
            id: 'nominalType',
            fieldId: 'nominalType',
            label: 'Nominal type',
            'aria-label': 'nominal type',
            type: 'autocomplete',
            required: true,
            clearable: true,
            openOnClick: true,
            placeholder: '',
            item: {
              value: 'value',
              label: 'label',
            },
            data: {
              options: [
                {
                  label: 'Account',
                  value: 'account',
                },
                {
                  label: 'Aircraft',
                  value: 'aircraft',
                },
                {
                  label: 'Air Waybill',
                  value: 'airWaybill',
                },
                {
                  label: 'Bank Account',
                  value: 'bank',
                },
                {
                  label: 'Carriage',
                  value: 'carriage',
                },
                {
                  label: 'Container',
                  value: 'container',
                },
                {
                  label: 'Flight',
                  value: 'flight',
                },
                {
                  label: 'Identity Document',
                  value: 'identity',
                },
                {
                  label: 'Organisation',
                  value: 'organisation',
                },
                {
                  label: 'Person',
                  value: 'person',
                },
                {
                  label: 'Postal Address',
                  value: 'postal',
                },
                {
                  label: 'Telephone',
                  value: 'telephone',
                },
                {
                  label: 'Trailer',
                  value: 'trailer',
                },
                {
                  label: 'Vehicle',
                  value: 'vehicle',
                },
                {
                  label: 'Vessel',
                  value: 'vessel',
                },
              ],
            },
          },
          {
            id: 'systemsCheck',
            fieldId: 'systemsCheck',
            label: 'System checks completed',
            'aria-label': 'system checks completed',
            hint: 'List all the systems you have checked this nominal against',
            type: 'autocomplete',
            required: true,
            multi: true,
            openOnClick: true,
            closeOnSelect: false,
            item: {
              value: 'id',
              label: 'name',
            },
            data: {
              url: '${environmentContext.referenceDataUrl}/v2/entities/partnersystems?sort=name.asc&mode=dataOnly&validDateTime=true',
            },
          },
          {
            id: 'comments',
            fieldId: 'comments',
            label: 'Comments',
            hint: 'Provide as much useful information as possible. This target will be sent to a frontline team for interdiction.',
            type: 'textarea',
          },
        ],
      },
      {
        id: 'targetingIndicators',
        fieldId: 'targetingIndicators',
        label: 'Targeting indicators',
        'aria-label': 'targeting indicators',
        type: 'autocomplete',
        multi: true,
        openOnClick: true,
        closeOnSelect: false,
        item: {
          value: 'id',
          label: 'userfacingtext',
        },
        data: {
          url: '${environmentContext.referenceDataUrl}/v2/entities/tisfeaturenames?sort=userfacingtext.asc&validto=is.null',
        },
      },
      {
        id: 'departurePort',
        fieldId: 'departurePort',
        label: 'Port',
        'aria-label': 'port',
        hint: 'The port that the target is scheduled to depart from',
        type: 'autocomplete',
        item: {
          value: 'id',
          label: 'name',
        },
        required: true,
        clearable: true,
        openOnClick: true,
        placeholder: '',
        data: {
          url: '${environmentContext.referenceDataUrl}/v2/entities/port?skip=0&sort=name.asc&select=id,name,municipality&filter=countryid=eq.234&filter=sea=eq.t&filter=nonoperational=eq.false&mode=dataOnly&validDateTime=true',
        },
        show_when: {
          field: './direction',
          op: 'eq',
          value: 'OUTBOUND',
        },
      },
      {
        id: 'direction',
        fieldId: 'direction',
        label: 'Inbound or outbound',
        type: 'radios',
        required: true,
        data: {
          options: [
            {
              value: 'INBOUND',
              label: 'Inbound',
            },
            {
              value: 'OUTBOUND',
              label: 'Outbound',
            },
          ],
        },
      },
      {
        id: 'goods',
        fieldId: 'goods',
        type: 'container',
        components: [
          {
            type: 'heading',
            size: 'm',
            content: 'Goods description',
          },
          {
            id: 'load',
            fieldId: 'load',
            label: 'Manifested load',
            type: 'text',
          },
          {
            id: 'manifestedWeight',
            fieldId: 'manifestedWeight',
            label: 'Manifest weight',
            type: 'text',
          },
          {
            id: 'grossWeight',
            fieldId: 'grossWeight',
            label: 'Gross weight',
            type: 'text',
          },
          {
            id: 'netWeight',
            fieldId: 'netWeight',
            label: 'Net weight',
            type: 'text',
          },
          {
            id: 'destinationCountry',
            fieldId: 'destinationCountry',
            label: 'Country of destination',
            'aria-label': 'country of destination',
            type: 'autocomplete',
            clearable: true,
            openOnClick: true,
            placeholder: '',
            item: {
              value: 'id',
              label: 'name',
            },
            data: {
              url: '${environmentContext.referenceDataUrl}/v2/entities/country?sort=name.asc&mode=dataOnly&validDateTime=true',
            },
          },
          {
            id: 'detailsAvailable',
            fieldId: 'detailsAvailable',
            label: 'Details are available for',
            type: 'checkboxes',
            data: {
              options: [
                {
                  value: 'consignor',
                  label: 'Consignor',
                },
                {
                  value: 'consignee',
                  label: 'Consignee',
                },
                {
                  value: 'haulier',
                  label: 'Haulier',
                },
              ],
            },
          },
          {
            id: 'consignee',
            fieldId: 'consignee',
            type: 'container',
            components: [
              {
                type: 'heading',
                size: 'm',
                content: 'Consignee details',
              },
              {
                id: 'name',
                fieldId: 'name',
                label: 'Consignee name',
                type: 'text',
              },
              {
                id: 'line1',
                fieldId: 'line1',
                label: 'Address line 1',
                type: 'text',
              },
              {
                id: 'line2',
                fieldId: 'line2',
                label: 'Address line 2',
                type: 'text',
              },
              {
                id: 'line3',
                fieldId: 'line3',
                label: 'Address line 3',
                type: 'text',
              },
              {
                id: 'city',
                fieldId: 'city',
                label: 'Town or city',
                type: 'text',
              },
              {
                id: 'postcode',
                fieldId: 'postcode',
                label: 'Postcode',
                type: 'text',
              },
              {
                id: 'country',
                fieldId: 'country',
                label: 'Country',
                type: 'text',
              },
            ],
            show_when: {
              field: './detailsAvailable',
              op: 'contains',
              value: 'consignee',
            },
          },
          {
            id: 'hazardous',
            fieldId: 'hazardous',
            label: 'Hazardous',
            type: 'radios',
            data: {
              options: [
                {
                  value: 'YES',
                  label: 'Yes',
                  disabled: true,
                },
                {
                  value: 'NO',
                  label: 'No',
                  disabled: true,
                },
              ],
            },
          },
          {
            id: 'consignor',
            fieldId: 'consignor',
            title: 'Consignor details',
            type: 'container',
            components: [
              {
                type: 'heading',
                size: 'm',
                content: 'Consignor details',
              },
              {
                id: 'name',
                fieldId: 'name',
                label: 'Consignor name',
                type: 'text',
              },
              {
                id: 'line1',
                fieldId: 'line1',
                label: 'Address line 1',
                type: 'text',
              },
              {
                id: 'line2',
                fieldId: 'line2',
                label: 'Address line 2',
                type: 'text',
              },
              {
                id: 'line3',
                fieldId: 'line3',
                label: 'Address line 3',
                type: 'text',
              },
              {
                id: 'city',
                fieldId: 'city',
                label: 'Town or city',
                type: 'text',
              },
              {
                id: 'postcode',
                fieldId: 'postcode',
                label: 'Postcode',
                type: 'text',
              },
              {
                id: 'country',
                fieldId: 'country',
                label: 'Country',
                type: 'text',
              },
            ],
            show_when: {
              field: './detailsAvailable',
              op: 'contains',
              value: 'consignor',
            },
          },
          {
            id: 'haulier',
            fieldId: 'haulier',
            title: 'Haulier details',
            type: 'container',
            components: [
              {
                type: 'heading',
                size: 'm',
                content: 'Haulier details',
              },
              {
                id: 'name',
                fieldId: 'name',
                label: 'Haulier Name',
                type: 'text',
              },
              {
                id: 'line1',
                fieldId: 'line1',
                label: 'Address line 1',
                type: 'text',
              },
              {
                id: 'line2',
                fieldId: 'line2',
                label: 'Address line 2',
                type: 'text',
              },
              {
                id: 'line3',
                fieldId: 'line3',
                label: 'Address line 3',
                type: 'text',
              },
              {
                id: 'city',
                fieldId: 'city',
                label: 'Town or city',
                type: 'text',
              },
              {
                id: 'postcode',
                fieldId: 'postcode',
                label: 'Postcode',
                type: 'text',
              },
              {
                id: 'country',
                fieldId: 'country',
                label: 'Country',
                type: 'text',
              },
            ],
            show_when: {
              field: './detailsAvailable',
              op: 'contains',
              value: 'haulier',
            },
          },
        ],
        show_when: {
          field: '../refDataMode.modecode',
          op: 'in',
          values: [
            'rorofrac',
            'rorofrun',
          ],
        },
      },
      {
        id: 'informFreightAndTourist',
        fieldId: 'informFreightAndTourist',
        label: 'Inform both Freight and Tourist',
        type: 'checkboxes',
        data: {
          options: [
            {
              value: 'true',
              label: 'Inform both Freight and Tourist',
            },
          ],
        },
      },
      {
        id: 'interception',
        fieldId: 'interception',
        type: 'container',
        components: [
          {
            type: 'heading',
            size: 'm',
            content: 'Interception details',
          },
          {
            id: 'vesselName',
            fieldId: 'vesselName',
            label: 'Vessel name',
            type: 'text',
          },
          {
            id: 'shippingCompany',
            fieldId: 'shippingCompany',
            label: 'Shipping company',
            type: 'text',
          },
          {
            id: 'arrival',
            fieldId: 'arrival',
            type: 'container',
            components: [
              {
                type: 'inset-text',
                content: 'Please note, the date and time displayed here is in UTC time.',
              },
              {
                id: 'date',
                fieldId: 'date',
                label: 'Estimated date of arrival',
                type: 'date',
                required: true,
                additionalValidation: [
                  {
                    function: 'mustBeAfter',
                    unit: 'month',
                    value: -3,
                    message: 'Estimated date of arrival must not be older than 3 months',
                  },
                ],
              },
              {
                id: 'time',
                fieldId: 'time',
                label: 'Estimated time of arrival',
                type: 'time',
                required: true,
              },
            ],
            required: true,
            show_when: {
              field: '../direction',
              op: 'eq',
              value: 'INBOUND',
            },
          },
          {
            id: 'departure',
            fieldId: 'departure',
            type: 'container',
            components: [
              {
                type: 'inset-text',
                content: 'Please note, the date and time displayed here is in UTC time.',
              },
              {
                id: 'date',
                fieldId: 'date',
                label: 'Estimated date of departure',
                type: 'date',
                required: true,
                additionalValidation: [
                  {
                    function: 'mustBeInTheFuture',
                    todayAllowed: true,
                    message: 'Estimated date of departure must be in the future',
                  },
                ],
              },
              {
                id: 'time',
                fieldId: 'time',
                label: 'Estimated time of departure',
                type: 'time',
                required: true,
              },
            ],
            required: true,
            show_when: {
              field: '../direction',
              op: 'eq',
              value: 'OUTBOUND',
            },
          },
        ],
      },
      {
        id: 'issuingHub',
        fieldId: 'issuingHub',
        label: 'Issuing hub',
        'aria-label': 'issuing hub',
        type: 'autocomplete',
        clearable: true,
        openOnClick: true,
        placeholder: '',
        required: true,
        item: {
          value: 'name',
          label: 'name',
        },
        data: {
          url: '${environmentContext.referenceDataUrl}/v2/entities/issuinghubcontact?sort=name.asc&mode=dataOnly&validDateTime=true',
        },
      },
      {
        id: 'refDataMode',
        fieldId: 'refDataMode',
        label: 'Mode',
        'aria-label': 'mode',
        type: 'autocomplete',
        required: true,
        clearable: true,
        openOnClick: true,
        placeholder: '',
        item: {
          value: 'id',
          label: 'mode',
        },
        data: {
          url: '${environmentContext.referenceDataUrl}/v2/entities/targetmode?sort=mode.asc&filter=ca=eq.t&mode=dataOnly&validDateTime=true',
        },
      },
      {
        id: 'operation',
        fieldId: 'operation',
        label: 'Operation name',
        type: 'text',
      },
      {
        id: 'otherPersons',
        fieldId: 'otherPersons',
        type: 'collection',
        labels: {
          item: 'Passenger ${index} details',
          add: 'Add another passenger',
        },
        countOffset: 0,
        item: [
          {
            id: 'name',
            fieldId: 'name',
            type: 'container',
            components: [
              {
                id: 'first',
                fieldId: 'first',
                label: 'First name',
                type: 'text',
              },
              {
                id: 'middle',
                fieldId: 'middle',
                label: 'Middle name(s)',
                type: 'text',
              },
              {
                id: 'last',
                fieldId: 'last',
                label: 'Last name',
                type: 'text',
              },
            ],
          },
          {
            id: 'dateOfBirth',
            fieldId: 'dateOfBirth',
            label: 'Date of Birth',
            type: 'date',
          },
          {
            id: 'nationality',
            fieldId: 'nationality',
            label: 'Nationality',
            'aria-label': 'nationality',
            type: 'autocomplete',
            clearable: true,
            openOnClick: true,
            placeholder: '',
            item: {
              value: 'id',
              label: 'nationality',
            },
            data: {
              url: '${environmentContext.referenceDataUrl}/v2/entities/nationality?sort=nationality.asc&mode=dataOnly&validDateTime=true',
            },
          },
          {
            id: 'sex',
            fieldId: 'sex',
            label: 'Sex',
            'aria-label': 'sex',
            type: 'autocomplete',
            clearable: true,
            openOnClick: true,
            placeholder: '',
            item: {
              value: 'id',
              label: 'name',
            },
            data: {
              url: '${environmentContext.referenceDataUrl}/v2/entities/sex?sort=name.asc&mode=dataOnly&validDateTime=true',
            },
            description: 'This is shown as Gender in the UI, which is a different thing to sex.',
          },
          {
            id: 'document',
            fieldId: 'document',
            type: 'container',
            components: [
              {
                id: 'type',
                fieldId: 'type',
                label: 'Travel document type',
                'aria-label': 'travel document type',
                type: 'autocomplete',
                clearable: true,
                openOnClick: true,
                placeholder: '',
                item: {
                  value: 'id',
                  label: 'shortdescription',
                },
                data: {
                  url: '${environmentContext.referenceDataUrl}/v2/entities/documenttype?sort=shortdescription.asc&mode=dataOnly&validDateTime=true',
                },
              },
              {
                id: 'documentNumber',
                fieldId: 'documentNumber',
                label: 'Travel document number',
                type: 'text',
              },
              {
                id: 'documentExpiry',
                fieldId: 'documentExpiry',
                label: 'Travel document expiry',
                type: 'date',
              },
              {
                id: 'issueCountry',
                fieldId: 'issueCountry',
                label: 'Travel document country of issue',
                'aria-label': 'travel document country of issue',
                type: 'autocomplete',
                clearable: true,
                openOnClick: true,
                placeholder: '',
                item: {
                  value: 'iso31661alpha3',
                  label: 'name',
                },
                data: {
                  url: '${environmentContext.referenceDataUrl}/v2/entities/country?sort=name.asc&mode=dataOnly&validDateTime=true',
                },
              },

            ],
          },
          {
            id: 'watchList',
            fieldId: 'watchList',
            label: 'Has this person been added to Border Crossing?',
            type: 'radios',
            required: true,
            data: {
              options: [
                {
                  value: 'YES',
                  label: 'Yes',
                  nested: [
                    {
                      id: 'individualReferenceNumber',
                      fieldId: 'individualReferenceNumber',
                      label: 'Input Border Crossing (IRN) Individual Reference Number',
                      type: 'text',
                    },
                  ],
                },
                {
                  value: 'NO',
                  label: 'No',
                },
              ],
            },
          },
          {
            id: 'pncOutcome',
            fieldId: 'pncOutcome',
            label: 'Police National Computer (PNC) check',
            type: 'radios',
            data: {
              options: [
                {
                  value: 'NO_RECORDS_FOUND',
                  label: 'No PNC records were found based on the identity information available',
                },
                {
                  value: 'INSUFFICIENT_IDENTITY_INFORMATION',
                  label: 'There was not enough identity information available to perform a PNC check',
                },
                {
                  value: 'INSUFFICIENT_TIME',
                  label: 'There was not enough time to complete a PNC search',
                },
                {
                  value: 'CHECK_NOT_RELEVANT_FOR_PERSON',
                  label: 'A PNC check is not relevant for this person',
                },
                {
                  value: 'IRRELEVANT_INFORMATION_FOUND',
                  label: 'A PNC record was found but it does not contain any relevant information',
                },
                {
                  value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
                  label: 'PNC information was found which is potentially relevant to the target',
                },
              ],
            },
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'You must select one option to reflect your actions to confirm a PNC check',
              },
            ],
          },
          {
            id: 'pncFound',
            fieldId: 'pncFound',
            type: 'container',
            className: 'indent',
            components: [
              {
                id: 'pncId',
                fieldId: 'pncId',
                label: 'Enter the PNC ID of the record you found',
                type: 'text',
                hint: 'For example, 12/34567890A, 12/34A, or 76/6518286013A',
                pattern: '^[0-9]{2}/[0-9]{1,}[A-Za-z]{1}$',
                required: true,
                custom_errors: [
                  {
                    type: 'pattern',
                    message: 'Enter a PNC ID in the correct format',
                  },
                  {
                    type: 'required',
                    message: 'Enter a PNC ID in the correct format',
                  },
                ],
              },
              {
                id: 'safetyInformation',
                fieldId: 'safetyInformation',
                className: 'margin-bottom-0 padding-top-1',
                label: 'Did you find any information which was relevant for officer or passenger safety?',
                type: 'radios',
                data: {
                  options: [
                    {
                      value: 'No',
                      label: 'No',
                    },
                    {
                      value: 'Yes',
                      label: 'Yes',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must confirm if you found information relevant to officer or passenger safety',
                  },
                ],
              },
            ],
            required: true,
            show_when: [
              {
                field: './pncOutcome',
                op: 'eq',
                value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
              },
            ],
          },
          {
            id: 'pncDetails',
            fieldId: 'pncDetails',
            className: 'no-label indent padding-top-2',
            type: 'container',
            components: [
              {
                id: 'types',
                fieldId: 'types',
                label: 'PNC information types relevant to officer or passenger safety',
                className: 'no-label types indent margin-bottom-0',
                type: 'checkboxes',
                data: {
                  options: [
                    {
                      value: 'VIOLENCE',
                      label: 'Violence',
                    },
                    {
                      value: 'FIREARMS',
                      label: 'Firearms',
                    },
                    {
                      value: 'DRUG_USE',
                      label: 'Drug use',
                    },
                    {
                      value: 'SELF_HARM',
                      label: 'Self harm',
                    },
                    {
                      value: 'MISSING',
                      label: 'Missing',
                    },
                    {
                      value: 'WANTED',
                      label: 'Wanted',
                    },
                    {
                      value: 'MENTAL',
                      label: 'Mental health issues',
                    },
                    {
                      value: 'STAFF_CONCERN',
                      label: 'Other concerns for the safety of frontline staff',
                    },
                    {
                      value: 'POI_CONCERN',
                      label: 'Other concerns for the person of interest',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must provide details about PNC information relevant for officer or passenger safety',
                  },
                ],
              },
              {
                id: 'staffConcerns',
                fieldId: 'staffConcerns',
                className: 'border-left error-spacing margin-bottom-0 padding-left-1 padding-top-2',
                label: 'Explain the concerns for safety of frontline staff',
                type: 'textarea',
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must provide details about PNC information relevant to officer safety',
                  },
                ],
                showCharacterCount: true,
                show_when: {
                  field: './types',
                  op: 'contains',
                  value: 'staff_concern',
                },
              },
              {
                id: 'poiConcerns',
                fieldId: 'poiConcerns',
                className: 'border-left no-bottom-spacing margin-bottom-0 padding-left-1 padding-top-2',
                label: 'Explain the concerns for person of interest',
                type: 'textarea',
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must provide details about PNC information relevant to passenger safety',
                  },
                ],
                showCharacterCount: true,
                show_when: {
                  field: './types',
                  op: 'contains',
                  value: 'poi_concern',
                },
              },
            ],
            show_when: [
              {
                field: './pncFound/safetyInformation',
                op: 'eq',
                value: 'Yes',
              },
              {
                field: './pncOutcome',
                op: 'eq',
                value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
              },
            ],
          },
          {
            id: 'pncCategories',
            fieldId: 'pncCategories',
            className: 'indent margin-bottom-2',
            type: 'container',
            components: [
              {
                type: 'html',
                tagName: 'p',
                content: 'Select all categories in which PNC information indicated potential involvement',
                className: 'margin-top-0 padding-top-3',
              },
              {
                id: 'drugsPossession',
                fieldId: 'drugsPossession',
                className: 'no-label margin-bottom-1',
                label: '',
                show_on_cya: false,
                type: 'checkboxes',
                data: {
                  options: [
                    {
                      value: 'DRUGS',
                      label: 'Possession of drugs',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'possessionClass',
                fieldId: 'possessionClass',
                label: 'Possession of drugs',
                type: 'checkboxes',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'CLASS_A',
                      label: 'Class A',
                    },
                    {
                      value: 'CLASS_B',
                      label: 'Class B',
                    },
                    {
                      value: 'CLASS_C',
                      label: 'Class C',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'Please provide additional details regarding potential involvement with possession of drugs',
                  },
                ],
                show_when: {
                  field: './drugsPossession',
                  op: 'contains',
                  value: 'drugs',
                },
                description: 'Label added for cya page',
              },
              {
                id: 'drugsSupply',
                fieldId: 'drugsSupply',
                className: 'no-label margin-bottom-1',
                label: 'Possession of drugs',
                show_on_cya: false,
                type: 'checkboxes',
                data: {
                  options: [
                    {
                      value: 'SUPPLY',
                      label: 'Supply of drugs',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'supplyClass',
                fieldId: 'supplyClass',
                label: 'Supply of drugs',
                type: 'checkboxes',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'CLASS_A',
                      label: 'Class A',
                    },
                    {
                      value: 'CLASS_B',
                      label: 'Class B',
                    },
                    {
                      value: 'CLASS_C',
                      label: 'Class C',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'Please provide additional details regarding potential involvement with supply of drugs',
                  },
                ],
                show_when: {
                  field: './drugsSupply',
                  op: 'contains',
                  value: 'supply',
                },
                description: 'Label added for cya page',
              },
              {
                id: 'drugsTrafficking',
                fieldId: 'drugsTrafficking',
                label: 'Trafficking of drugs',
                className: 'no-label margin-bottom-1',
                show_on_cya: false,
                type: 'checkboxes',
                data: {
                  options: [
                    {
                      value: 'TRAFFICKING',
                      label: 'Trafficking of drugs',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'traffickingClass',
                fieldId: 'traffickingClass',
                label: 'Trafficking of drugs',
                type: 'checkboxes',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'CLASS_A',
                      label: 'Class A',
                    },
                    {
                      value: 'CLASS_B',
                      label: 'Class B',
                    },
                    {
                      value: 'CLASS_C',
                      label: 'Class C',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'Please provide additional details regarding potential involvement with trafficking of drugs',
                  },
                ],
                show_when: {
                  field: './drugsTrafficking',
                  op: 'contains',
                  value: 'trafficking',
                },
                description: 'Label added for cya page',
              },
              {
                id: 'weaponsPossession',
                fieldId: 'weaponsPossession',
                label: 'Possession of firearms, weapons, or harmful materials',
                className: 'no-label margin-bottom-1',
                type: 'checkboxes',
                show_on_cya: false,
                data: {
                  options: [
                    {
                      value: 'POSSESSION',
                      label: 'Possession of firearms, weapons, or harmful materials',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'weaponsPossessionOpts',
                fieldId: 'weaponsPossessionOpts',
                label: 'Possession of firearms, weapons, or harmful materials',
                type: 'checkboxes',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'FIREARMS',
                      label: 'Firearms',
                    },
                    {
                      value: 'OFFENSIVE_WEAPONS',
                      label: 'Offensive weapons',
                      hint: '(including non-lethal firearms)',
                    },
                    {
                      value: 'EXPLOSIVE_MATERIALS',
                      label: 'Explosive materials',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'Please provide additional details regarding potential involvement with possession of firearms, weapons, or harmful materials',
                  },
                ],
                show_when: {
                  field: './weaponsPossession',
                  op: 'contains',
                  value: 'possession',
                },
                description: 'Label added for cya page',
              },
              {
                id: 'weaponsSupply',
                fieldId: 'weaponsSupply',
                label: 'Supply of firearms, weapons, or harmful materials',
                className: 'no-label margin-bottom-1',
                type: 'checkboxes',
                show_on_cya: false,
                data: {
                  options: [
                    {
                      value: 'SUPPLY',
                      label: 'Supply of firearms, weapons, or harmful materials',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'weaponsSupplyOptions',
                fieldId: 'weaponsSupplyOptions',
                type: 'checkboxes',
                label: 'Supply of firearms, weapons, or harmful materials',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'FIREARMS',
                      label: 'Firearms',
                    },
                    {
                      value: 'OFFENSIVE_WEAPONS',
                      label: 'Offensive weapons',
                      hint: '(including non-lethal firearms)',
                    },
                    {
                      value: 'EXPLOSIVE_MATERIALS',
                      label: 'Explosive materials',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'Please provide additional details regarding potential involvement with supply of firearms, weapons, or harmful materials',
                  },
                ],
                show_when: {
                  field: './weaponsSupply',
                  op: 'contains',
                  value: 'supply',
                },
                description: 'Label added for cya page',
              },
              {
                id: 'goodsSmuggling',
                fieldId: 'goodsSmuggling',
                label: 'Smuggling of goods',
                className: 'no-label margin-bottom-1',
                type: 'checkboxes',
                show_on_cya: false,
                data: {
                  options: [
                    {
                      value: 'GOODS_SMUGGLING',
                      label: 'Smuggling of goods',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'goodsSmugglingOpts',
                fieldId: 'goodsSmugglingOpts',
                label: 'Smuggling of goods',
                type: 'checkboxes',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'EXCISE',
                      label: 'Actively relating to excise goods',
                    },
                    {
                      value: 'FALSE_IMPORT',
                      label: 'False import licences',
                    },
                    {
                      value: 'MONEY_LAUNDERING',
                      label: 'Money laundering',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must provide details about PNC information indicating potential involvement with smuggling of goods',
                  },
                ],
                show_when: {
                  field: './goodsSmuggling',
                  op: 'contains',
                  value: 'goods_smuggling',
                },
                description: 'Label added for cya page',
              },
              {
                id: 'peopleSmuggling',
                fieldId: 'peopleSmuggling',
                label: 'Smuggling of people',
                className: 'no-label margin-bottom-1',
                type: 'checkboxes',
                show_on_cya: false,
                data: {
                  options: [
                    {
                      value: 'PEOPLE_SMUGGLING',
                      label: 'Smuggling of people',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'peopleSmugglingOpts',
                fieldId: 'peopleSmugglingOpts',
                label: 'Smuggling of people',
                type: 'checkboxes',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'HUMAN_TRAFFICKING',
                      label: 'Human trafficking',
                    },
                    {
                      value: 'FALSE_DOCUMENTS',
                      label: 'False identity documents',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must provide details about PNC information indicating potential smuggling of people',
                  },
                ],
                show_when: {
                  field: './peopleSmuggling',
                  op: 'contains',
                  value: 'people_smuggling',
                },
                description: 'Label added for cya page',
              },
            ],
            show_when: [
              {
                field: './pncOutcome',
                op: 'eq',
                value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
              },
            ],
          },
        ],
        show_when: {
          field: 'refDataMode.modecode',
          op: 'in',
          values: [
            'rorofrac',
            'rorotour',
          ],
        },
      },
      {
        id: 'person',
        fieldId: 'person',
        type: 'container',
        components: [
          {
            type: 'heading',
            size: 'm',
            content: 'Driver details',
          },
          {
            id: 'name',
            fieldId: 'name',
            type: 'container',
            components: [
              {
                id: 'first',
                fieldId: 'first',
                label: 'First name',
                type: 'text',
              },
              {
                id: 'middle',
                fieldId: 'middle',
                label: 'Middle name(s)',
                type: 'text',
              },
              {
                id: 'last',
                fieldId: 'last',
                label: 'Last name',
                type: 'text',
              },
            ],
          },
          {
            id: 'dateOfBirth',
            fieldId: 'dateOfBirth',
            label: 'Date of Birth',
            type: 'date',
          },
          {
            id: 'nationality',
            fieldId: 'nationality',
            label: 'Nationality',
            'aria-label': 'nationality',
            type: 'autocomplete',
            clearable: true,
            openOnClick: true,
            placeholder: '',
            item: {
              value: 'id',
              label: 'nationality',
            },
            data: {
              url: '${environmentContext.referenceDataUrl}/v2/entities/nationality?sort=nationality.asc&mode=dataOnly&validDateTime=true',
            },
          },
          {
            id: 'sex',
            fieldId: 'sex',
            label: 'Sex',
            'aria-label': 'sex',
            type: 'autocomplete',
            clearable: true,
            openOnClick: true,
            placeholder: '',
            item: {
              value: 'id',
              label: 'name',
            },
            data: {
              url: '${environmentContext.referenceDataUrl}/v2/entities/sex?sort=name.asc&mode=dataOnly&validDateTime=true',
            },
            description: 'This is shown as Gender in the UI, which is a different thing to sex.',
          },
          {
            id: 'document',
            fieldId: 'document',
            type: 'container',
            components: [
              {
                id: 'type',
                fieldId: 'type',
                label: 'Travel document type',
                'aria-label': 'travel document type',
                type: 'autocomplete',
                clearable: true,
                openOnClick: true,
                placeholder: '',
                item: {
                  value: 'id',
                  label: 'shortdescription',
                },
                data: {
                  url: '${environmentContext.referenceDataUrl}/v2/entities/documenttype?sort=shortdescription.asc&mode=dataOnly&validDateTime=true',
                },
              },
              {
                id: 'documentNumber',
                fieldId: 'documentNumber',
                label: 'Travel document number',
                type: 'text',
              },
              {
                id: 'documentExpiry',
                fieldId: 'documentExpiry',
                label: 'Travel document expiry',
                type: 'date',
              },
              {
                id: 'issueCountry',
                fieldId: 'issueCountry',
                label: 'Travel document country of issue',
                'aria-label': 'travel document country of issue',
                type: 'autocomplete',
                clearable: true,
                openOnClick: true,
                placeholder: '',
                item: {
                  value: 'iso31661alpha3',
                  label: 'name',
                },
                data: {
                  url: '${environmentContext.referenceDataUrl}/v2/entities/country?sort=name.asc&mode=dataOnly&validDateTime=true',
                },
              },
            ],
          },
          {
            id: 'watchList',
            fieldId: 'watchList',
            label: 'Has this person been added to Border Crossing?',
            type: 'radios',
            required: true,
            data: {
              options: [
                {
                  value: 'YES',
                  label: 'Yes',
                  nested: [
                    {
                      id: 'individualReferenceNumber',
                      fieldId: 'individualReferenceNumber',
                      label: 'Input Border Crossing (IRN) Individual Reference Number',
                      type: 'text',
                    },
                  ],
                },
                {
                  value: 'NO',
                  label: 'No',
                },
              ],
            },
          },
          {
            id: 'pncOutcome',
            fieldId: 'pncOutcome',
            label: 'Police National Computer (PNC) check',
            type: 'radios',
            data: {
              options: [
                {
                  value: 'NO_RECORDS_FOUND',
                  label: 'No PNC records were found based on the identity information available',
                },
                {
                  value: 'INSUFFICIENT_IDENTITY_INFORMATION',
                  label: 'There was not enough identity information available to perform a PNC check',
                },
                {
                  value: 'INSUFFICIENT_TIME',
                  label: 'There was not enough time to complete a PNC search',
                },
                {
                  value: 'CHECK_NOT_RELEVANT_FOR_PERSON',
                  label: 'A PNC check is not relevant for this person',
                },
                {
                  value: 'IRRELEVANT_INFORMATION_FOUND',
                  label: 'A PNC record was found but it does not contain any relevant information',
                },
                {
                  value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
                  label: 'PNC information was found which is potentially relevant to the target',
                },
              ],
            },
            required: true,
            custom_errors: [
              {
                type: 'required',
                message: 'You must select one option to reflect your actions to confirm a PNC check',
              },
            ],
          },
          {
            id: 'pncFound',
            fieldId: 'pncFound',
            type: 'container',
            className: 'indent',
            components: [
              {
                id: 'pncId',
                fieldId: 'pncId',
                label: 'Enter the PNC ID of the record you found',
                type: 'text',
                hint: 'For example, 12/34567890A, 12/34A, or 76/6518286013A',
                pattern: '^[0-9]{2}/[0-9]{1,}[A-Za-z]{1}$',
                required: true,
                custom_errors: [
                  {
                    type: 'pattern',
                    message: 'Enter a PNC ID in the correct format',
                  },
                  {
                    type: 'required',
                    message: 'Enter a PNC ID in the correct format',
                  },
                ],
              },
              {
                id: 'safetyInformation',
                fieldId: 'safetyInformation',
                className: 'margin-bottom-0 padding-top-1',
                label: 'Did you find any information which was relevant for officer or passenger safety?',
                type: 'radios',
                data: {
                  options: [
                    {
                      value: 'No',
                      label: 'No',
                    },
                    {
                      value: 'Yes',
                      label: 'Yes',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must confirm if you found information relevant to officer or passenger safety',
                  },
                ],
              },
            ],
            required: true,
            show_when: [
              {
                field: './pncOutcome',
                op: 'eq',
                value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
              },
            ],
          },
          {
            id: 'pncDetails',
            fieldId: 'pncDetails',
            className: 'no-label indent padding-top-2',
            type: 'container',
            components: [
              {
                id: 'types',
                fieldId: 'types',
                label: 'PNC information types relevant to officer or passenger safety',
                className: 'no-label types indent margin-bottom-0',
                type: 'checkboxes',
                data: {
                  options: [
                    {
                      value: 'VIOLENCE',
                      label: 'Violence',
                    },
                    {
                      value: 'FIREARMS',
                      label: 'Firearms',
                    },
                    {
                      value: 'DRUG_USE',
                      label: 'Drug use',
                    },
                    {
                      value: 'SELF_HARM',
                      label: 'Self harm',
                    },
                    {
                      value: 'MISSING',
                      label: 'Missing',
                    },
                    {
                      value: 'WANTED',
                      label: 'Wanted',
                    },
                    {
                      value: 'MENTAL',
                      label: 'Mental health issues',
                    },
                    {
                      value: 'STAFF_CONCERN',
                      label: 'Other concerns for the safety of frontline staff',
                    },
                    {
                      value: 'POI_CONCERN',
                      label: 'Other concerns for the person of interest',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must provide details about PNC information relevant for officer or passenger safety',
                  },
                ],
              },
              {
                id: 'staffConcerns',
                fieldId: 'staffConcerns',
                className: 'border-left error-spacing margin-bottom-0 padding-left-1 padding-top-2',
                label: 'Explain the concerns for safety of frontline staff',
                type: 'textarea',
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must provide details about PNC information relevant to officer safety',
                  },
                ],
                showCharacterCount: true,
                show_when: {
                  field: './types',
                  op: 'contains',
                  value: 'staff_concern',
                },
              },
              {
                id: 'poiConcerns',
                fieldId: 'poiConcerns',
                className: 'border-left no-bottom-spacing margin-bottom-0 padding-left-1 padding-top-2',
                label: 'Explain the concerns for person of interest',
                type: 'textarea',
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must provide details about PNC information relevant to passenger safety',
                  },
                ],
                showCharacterCount: true,
                show_when: {
                  field: './types',
                  op: 'contains',
                  value: 'poi_concern',
                },
              },
            ],
            show_when: [
              {
                field: './pncFound/safetyInformation',
                op: 'eq',
                value: 'Yes',
              },
              {
                field: './pncOutcome',
                op: 'eq',
                value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
              },
            ],
          },
          {
            id: 'pncCategories',
            fieldId: 'pncCategories',
            className: 'indent margin-bottom-2',
            type: 'container',
            components: [
              {
                type: 'html',
                tagName: 'p',
                content: 'Select all categories in which PNC information indicated potential involvement',
                className: 'margin-top-0 padding-top-3',
              },
              {
                id: 'drugsPossession',
                fieldId: 'drugsPossession',
                className: 'no-label margin-bottom-1',
                label: '',
                show_on_cya: false,
                type: 'checkboxes',
                data: {
                  options: [
                    {
                      value: 'DRUGS',
                      label: 'Possession of drugs',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'possessionClass',
                fieldId: 'possessionClass',
                label: 'Possession of drugs',
                type: 'checkboxes',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'CLASS_A',
                      label: 'Class A',
                    },
                    {
                      value: 'CLASS_B',
                      label: 'Class B',
                    },
                    {
                      value: 'CLASS_C',
                      label: 'Class C',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'Please provide additional details regarding potential involvement with possession of drugs',
                  },
                ],
                show_when: {
                  field: './drugsPossession',
                  op: 'contains',
                  value: 'drugs',
                },
                description: 'Label added for cya page',
              },
              {
                id: 'drugsSupply',
                fieldId: 'drugsSupply',
                className: 'no-label margin-bottom-1',
                label: 'Possession of drugs',
                show_on_cya: false,
                type: 'checkboxes',
                data: {
                  options: [
                    {
                      value: 'SUPPLY',
                      label: 'Supply of drugs',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'supplyClass',
                fieldId: 'supplyClass',
                label: 'Supply of drugs',
                type: 'checkboxes',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'CLASS_A',
                      label: 'Class A',
                    },
                    {
                      value: 'CLASS_B',
                      label: 'Class B',
                    },
                    {
                      value: 'CLASS_C',
                      label: 'Class C',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'Please provide additional details regarding potential involvement with supply of drugs',
                  },
                ],
                show_when: {
                  field: './drugsSupply',
                  op: 'contains',
                  value: 'supply',
                },
                description: 'Label added for cya page',
              },
              {
                id: 'drugsTrafficking',
                fieldId: 'drugsTrafficking',
                label: 'Trafficking of drugs',
                className: 'no-label margin-bottom-1',
                show_on_cya: false,
                type: 'checkboxes',
                data: {
                  options: [
                    {
                      value: 'TRAFFICKING',
                      label: 'Trafficking of drugs',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'traffickingClass',
                fieldId: 'traffickingClass',
                label: 'Trafficking of drugs',
                type: 'checkboxes',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'CLASS_A',
                      label: 'Class A',
                    },
                    {
                      value: 'CLASS_B',
                      label: 'Class B',
                    },
                    {
                      value: 'CLASS_C',
                      label: 'Class C',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'Please provide additional details regarding potential involvement with trafficking of drugs',
                  },
                ],
                show_when: {
                  field: './drugsTrafficking',
                  op: 'contains',
                  value: 'trafficking',
                },
                description: 'Label added for cya page',
              },
              {
                id: 'weaponsPossession',
                fieldId: 'weaponsPossession',
                label: 'Possession of firearms, weapons, or harmful materials',
                className: 'no-label margin-bottom-1',
                type: 'checkboxes',
                show_on_cya: false,
                data: {
                  options: [
                    {
                      value: 'POSSESSION',
                      label: 'Possession of firearms, weapons, or harmful materials',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'weaponsPossessionOpts',
                fieldId: 'weaponsPossessionOpts',
                label: 'Possession of firearms, weapons, or harmful materials',
                type: 'checkboxes',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'FIREARMS',
                      label: 'Firearms',
                    },
                    {
                      value: 'OFFENSIVE_WEAPONS',
                      label: 'Offensive weapons',
                      hint: '(including non-lethal firearms)',
                    },
                    {
                      value: 'EXPLOSIVE_MATERIALS',
                      label: 'Explosive materials',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'Please provide additional details regarding potential involvement with possession of firearms, weapons, or harmful materials',
                  },
                ],
                show_when: {
                  field: './weaponsPossession',
                  op: 'contains',
                  value: 'possession',
                },
                description: 'Label added for cya page',
              },
              {
                id: 'weaponsSupply',
                fieldId: 'weaponsSupply',
                label: 'Supply of firearms, weapons, or harmful materials',
                className: 'no-label margin-bottom-1',
                type: 'checkboxes',
                show_on_cya: false,
                data: {
                  options: [
                    {
                      value: 'SUPPLY',
                      label: 'Supply of firearms, weapons, or harmful materials',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'weaponsSupplyOptions',
                fieldId: 'weaponsSupplyOptions',
                type: 'checkboxes',
                label: 'Supply of firearms, weapons, or harmful materials',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'FIREARMS',
                      label: 'Firearms',
                    },
                    {
                      value: 'OFFENSIVE_WEAPONS',
                      label: 'Offensive weapons',
                      hint: '(including non-lethal firearms)',
                    },
                    {
                      value: 'EXPLOSIVE_MATERIALS',
                      label: 'Explosive materials',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'Please provide additional details regarding potential involvement with supply of firearms, weapons, or harmful materials',
                  },
                ],
                show_when: {
                  field: './weaponsSupply',
                  op: 'contains',
                  value: 'supply',
                },
                description: 'Label added for cya page',
              },
              {
                id: 'goodsSmuggling',
                fieldId: 'goodsSmuggling',
                label: 'Smuggling of goods',
                className: 'no-label margin-bottom-1',
                type: 'checkboxes',
                show_on_cya: false,
                data: {
                  options: [
                    {
                      value: 'GOODS_SMUGGLING',
                      label: 'Smuggling of goods',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'goodsSmugglingOpts',
                fieldId: 'goodsSmugglingOpts',
                label: 'Smuggling of goods',
                type: 'checkboxes',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'EXCISE',
                      label: 'Actively relating to excise goods',
                    },
                    {
                      value: 'FALSE_IMPORT',
                      label: 'False import licences',
                    },
                    {
                      value: 'MONEY_LAUNDERING',
                      label: 'Money laundering',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must provide details about PNC information indicating potential involvement with smuggling of goods',
                  },
                ],
                show_when: {
                  field: './goodsSmuggling',
                  op: 'contains',
                  value: 'goods_smuggling',
                },
                description: 'Label added for cya page',
              },
              {
                id: 'peopleSmuggling',
                fieldId: 'peopleSmuggling',
                label: 'Smuggling of people',
                className: 'no-label margin-bottom-1',
                type: 'checkboxes',
                show_on_cya: false,
                data: {
                  options: [
                    {
                      value: 'PEOPLE_SMUGGLING',
                      label: 'Smuggling of people',
                    },
                  ],
                },
                description: 'Label added for cya page',
              },
              {
                id: 'peopleSmugglingOpts',
                fieldId: 'peopleSmugglingOpts',
                label: 'Smuggling of people',
                type: 'checkboxes',
                className: 'indent no-label',
                data: {
                  options: [
                    {
                      value: 'HUMAN_TRAFFICKING',
                      label: 'Human trafficking',
                    },
                    {
                      value: 'FALSE_DOCUMENTS',
                      label: 'False identity documents',
                    },
                  ],
                },
                required: true,
                custom_errors: [
                  {
                    type: 'required',
                    message: 'You must provide details about PNC information indicating potential smuggling of people',
                  },
                ],
                show_when: {
                  field: './peopleSmuggling',
                  op: 'contains',
                  value: 'people_smuggling',
                },
                description: 'Label added for cya page',
              },
            ],
            show_when: [
              {
                field: './pncOutcome',
                op: 'eq',
                value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
              },
            ],
          },
        ],
        show_when: {
          field: '../refDataMode.modecode',
          op: 'in',
          values: [
            'rorofrac',
            'rorotour',
          ],
        },
      },
      {
        id: 'preArrival',
        fieldId: 'preArrival',
        type: 'container',
        components: [
          {
            type: 'heading',
            size: 'm',
            content: 'Pre-arrival details',
          },
          {
            id: 'accountName',
            fieldId: 'accountName',
            label: 'Account name',
            type: 'text',
            show_when: {
              field: 'refDataMode.modecode',
              op: 'in',
              values: [
                'rorofrac',
                'rorofrun',
              ],
            },
          },
          {
            id: 'accountNumber',
            fieldId: 'accountNumber',
            label: 'Account number',
            type: 'text',
            show_when: {
              field: 'refDataMode.modecode',
              op: 'in',
              values: [
                'rorofrac',
                'rorofrun',
              ],
            },
          },
          {
            id: 'whySelected',
            fieldId: 'whySelected',
            label: 'Comments on reason for selection',
            hint: 'Provide as much useful information as possible. This target will be sent to a frontline team for interdiction.',
            type: 'textarea',
          },
        ],
      },
      {
        id: 'category',
        fieldId: 'category',
        label: 'Target category',
        'aria-label': 'target category',
        type: 'autocomplete',
        required: true,
        clearable: true,
        openOnClick: true,
        className: 'width-one-third hods-multi-select-autocomplete',
        placeholder: 'Select the target category',
        data: {
          options: [
            {
              value: 'A',
              label: 'A',
            },
            {
              value: 'B',
              label: 'B',
            },
            {
              value: 'C',
              label: 'C',
            },
            {
              value: 'U',
              label: 'U',
            },
          ],
        },
        custom_errors: [
          {
            type: 'required',
            message: 'You must select a Target category',
          },
        ],
      },
      {
        id: 'teamToReceiveTheTarget',
        fieldId: 'teamToReceiveTheTarget',
        label: 'Select the team that should receive the target',
        'aria-label': 'select the team that should receive the target',
        type: 'autocomplete',
        item: {
          value: 'id',
          label: 'displayname',
        },
        required: true,
        clearable: true,
        openOnClick: true,
        placeholder: '',
        data: {
          url: '${environmentContext.referenceDataUrl}/v2/entities/groups?sort=displayname.asc&limit=100&filter=grouptypeid=eq.5&filter=name=neq.GP6XOHK0&mode=dataOnly&validDateTime=true',
        },
      },
      {
        id: 'trailer',
        fieldId: 'trailer',
        type: 'container',
        components: [
          {
            id: 'registration',
            fieldId: 'registration',
            label: 'Trailer registration number',
            'aria-label': 'trailer registration number',
            type: 'text',
          },
          {
            id: 'type',
            fieldId: 'type',
            label: 'Trailer Type',
            'aria-label': 'trailer type',
            type: 'autocomplete',
            clearable: true,
            openOnClick: true,
            placeholder: '',
            item: {
              value: 'id',
              label: 'name',
            },
            data: {
              url: '${environmentContext.referenceDataUrl}/v2/entities/trailertypes?sort=name.asc&mode=dataOnly&validDateTime=true',
            },
          },
          {
            id: 'nationality',
            fieldId: 'nationality',
            label: 'Trailer registration nationality',
            'aria-label': 'trailer registration nationality',
            type: 'autocomplete',
            clearable: true,
            openOnClick: true,
            placeholder: '',
            item: {
              value: 'id',
              label: 'nationality',
            },
            data: {
              url: '${environmentContext.referenceDataUrl}/v2/entities/nationality?sort=nationality.asc&mode=dataOnly&validDateTime=true',
            },
          },
        ],
        show_when: {
          field: '../refDataMode.modecode',
          op: 'in',
          values: [
            'rorofrac',
            'rorofrun',
          ],
        },
      },
      {
        id: 'vehicle',
        fieldId: 'vehicle',
        type: 'container',
        components: [
          {
            id: 'registration',
            fieldId: 'registration',
            label: 'Vehicle registration number',
            type: 'text',
          },
          {
            id: 'make',
            fieldId: 'make',
            label: 'Vehicle make',
            type: 'text',
          },
          {
            id: 'model',
            fieldId: 'model',
            label: 'Vehicle model',
            type: 'text',
          },
          {
            id: 'colour',
            fieldId: 'colour',
            label: 'Vehicle colour',
            type: 'text',
          },
          {
            id: 'nationality',
            fieldId: 'nationality',
            label: 'Vehicle registration nationality',
            'aria-label': 'vehicle registration nationality',
            type: 'autocomplete',
            clearable: true,
            openOnClick: true,
            placeholder: '',
            item: {
              value: 'id',
              label: 'nationality',
            },
            data: {
              url: '${environmentContext.referenceDataUrl}/v2/entities/nationality?sort=nationality.asc&mode=dataOnly&validDateTime=true',
            },
          },
        ],
        show_when: {
          field: '../refDataMode.modecode',
          op: 'in',
          values: [
            'rorofrac',
            'rorotour',
          ],
        },
      },
      {
        id: 'remarks',
        fieldId: 'remarks',
        label: 'Targeter actions / Remarks',
        type: 'text',
      },
      {
        id: 'selectorsSummary',
        fieldId: 'selectorsSummary',
        label: '',
        type: 'selectorsSummary',
        hideOptionalSuffix: 'true',
      },
    ],
    pages: [
      {
        id: 'info',
        name: 'info',
        title: 'Target Information Sheet (RoRo)',
        components: [
          {
            type: 'heading',
            size: 'm',
            content: 'Target Information',
          },
          {
            use: 'selectorsSummary',
          },
          {
            use: 'refDataMode',
          },
          {
            use: 'issuingHub',
          },
          {
            use: 'controlStrategies',
          },
          {
            use: 'category',
          },
          {
            use: 'direction',
          },
          {
            use: 'arrivalPort',
          },
          {
            use: 'departurePort',
          },
          {
            use: 'operation',
          },
          {
            use: 'targetingIndicators',
          },
          {
            use: 'interception',
          },
          {
            type: 'heading',
            size: 'm',
            content: 'Vehicle details',
            show_when: {
              field: 'refDataMode.modecode',
              op: 'in',
              values: [
                'rorofrac',
                'rorofrun',
                'rorotour',
              ],
            },
          },
          {
            use: 'vehicle',
          },
          {
            use: 'trailer',
          },
          {
            use: 'person',
          },
          {
            type: 'heading',
            size: 'm',
            content: 'Passenger details',
            show_when: {
              field: 'refDataMode.modecode',
              op: 'in',
              values: [
                'rorofrac',
                'rorotour',
              ],
            },
          },
          {
            use: 'otherPersons',
          },
          {
            use: 'goods',
          },
          {
            use: 'preArrival',
          },
          {
            type: 'heading',
            size: 'm',
            content: 'Checks completed on nominals',
          },
          {
            use: 'nominalChecks',
          },
          {
            use: 'remarks',
          },
        ],
        actions: [
          {
            type: 'cancel',
            validate: false,
            label: 'Cancel',
            classModifiers: 'secondary',
          },
          {
            type: 'next',
            validate: true,
            label: 'Next',
          },
        ],
      },
      {
        id: 'recipientDetails',
        name: 'recipientDetails',
        title: 'Recipient Details',
        components: [
          {
            use: 'teamToReceiveTheTarget',
          },
          {
            use: 'informFreightAndTourist',
          },
        ],
        actions: [
          {
            type: 'cancel',
            validate: false,
            label: 'Cancel',
            classModifiers: 'secondary',
          },
          {
            type: 'navigate',
            page: 'info',
            validate: false,
            label: 'Previous',
          },
          {
            type: 'submit',
            validate: true,
            label: 'Accept and send',
          },
        ],
      },
    ],
  };
};

const deleteAttribute = (components, componentId, attrToRemove) => {
  return components.map((component) => {
    if (component.id === componentId) {
      delete component[attrToRemove];
    }
  });
};

const roroTisCerberus = () => {
  const form = getForm();
  return {
    ...form,
    components: form.components.map((component) => {
      if (['person', 'otherPersons'].includes(component.id)) {
        deleteAttribute(component.components || component.item, 'pncOutcome', 'required');
      }
      return component;
    }),
  };
};

export default roroTisCerberus;
