import { ButtonGroup, Button, Heading, Utils } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// HOC
import withDisabledStickyContainer from '../../../../../../../../components/HOC/withDisabledStickyContainer';

const DEFAULT_CLASS = 'hods-modal';

const Modal = ({ id,
  className,
  classBlock,
  classModifiers,
  onProceed,
  onCancel,
  modalProps,
  children }) => {
  const classes = Utils.classBuilder(classBlock, classModifiers, className);

  return (
    <div id={id} className={classes()}>
      <div className="govuk-grid-column-full">
        <Heading id="modal-header" size="s">
          {modalProps.heading}
        </Heading>
        {(!!modalProps.text && (<p>{modalProps.text}</p>))}
        {children}
        <ButtonGroup>
          <Button
            className="primary"
            classModifiers="warning"
            onClick={onProceed}
          >
            {modalProps.actions.proceed}
          </Button>
          <Button
            className="secondary"
            classModifiers="secondary"
            onClick={onCancel}
          >
            {modalProps.actions.cancel}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

Modal.propTypes = {
  id: PropTypes.string,
  onProceed: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  className: PropTypes.string,
  classBlock: PropTypes.string,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  modalProps: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    text: PropTypes.string,
    actions: PropTypes.shape({
      proceed: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

Modal.defaultProps = {
  id: null,
  className: null,
  classBlock: DEFAULT_CLASS,
  classModifiers: [],
  modalProps: {
    text: null,
  },
};
export default withDisabledStickyContainer(memo(Modal));
