import React, { memo, useEffect, useMemo, useState } from 'react';

// Config(s)
import config from '../../../../../../../../utils/config';

// Services
import AxiosRequests from '../../../../../../../../api/axiosRequests';

// Context(s)
import { useKeycloak } from '../../../../../../../../context/Keycloak';
import { useTask } from '../../../../../../../../context/TaskContext';

// Components(s)
import NoteModal from './NoteModal';
import UpliftedForm from '../forms/UpliftedForm';

// Hook(s)
import { useAxiosInstance } from '../../../../../../../../utils/Axios/axiosInstance';
import { setTabTitle, TAB_TITLES } from '../../../../../../../../utils/Hooks/useSetTabTitle';

// Form
import form from '../../../../../../../../forms/uplift/editTaskNote';

// Styling
import './EditTaskNote.scss';

const EditTaskNote = ({ ...props }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { taskId, setTask, editorContentProps } = useTask();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { offsetTop, data: note, onClose } = editorContentProps;

  setTabTitle(TAB_TITLES.EDIT_TASK_NOTE);

  const onChange = async ({ editNote }) => {
    setShowDeleteModal(editNote.trim() === '');
  };

  const onSubmit = async ({ data }) => {
    await AxiosRequests.updateNote(apiClient, taskId, note.id, {
      value: data?.editNote,
    })
      .then((updatedTask) => {
        setTask(updatedTask);
        onClose();
      })
      .catch((exception) => console.error(exception.message));
  };

  const onDelete = async () => {
    await AxiosRequests.deleteNote(apiClient, taskId, note.id)
      .then((updatedTask) => {
        setTask(updatedTask);
        onClose();
      })
      .catch((exception) => console.error(exception.message));
  };

  useEffect(() => {
    return () => setTabTitle(TAB_TITLES.TASK_DETAILS);
  }, []);

  const memoizedDeleteModal = useMemo(() => {
    return showDeleteModal ? <NoteModal onProceed={onDelete} /> : null;
  }, [showDeleteModal]);

  return (
    <div {...props}>
      <UpliftedForm
        preFillData={{
          editNote: note?.content?.value,
        }}
        form={form}
        onChange={onChange}
        onSubmit={onSubmit}
        onCancel={onClose}
        offsetTop={offsetTop}
        enableScrollTop={false}
        submitModal={memoizedDeleteModal}
        overrideOnCancelConfirm
        overrideSubmit
      />
    </div>
  );
};

export default memo(EditTaskNote);
