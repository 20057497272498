import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import * as pluralise from 'pluralise';
import Table from '../../../../../../../components/Table/Table';
import { toPreviousMovementLink, toPreviousTaskLink, toPreviousTaskLinkParams } from '../common';
import { PersonUtil, MovementUtil, DocumentUtil } from '../../../../../../../utils';
import '../../../../../../../components/Table/Table.scss';

const CO_TRAVELLERS_TABLE_HEADERS = [
  'Traveller',
  'Age',
  'Document',
];

const toTravellerColumnContent = (person, version) => {
  return (
    <>
      <div className="font__bold">
        {PersonUtil.lastname(person)}, {PersonUtil.firstname(person)} {MovementUtil.status(version)}
      </div>
      <div className="font__light">
        {PersonUtil.gender(person)}, {PersonUtil.dob(person)},&nbsp;
        {PersonUtil.countryName(person)} ({PersonUtil.nationality(person)})
      </div>
      <div className="font__light">
        <span className="govuk-!-margin-bottom-0 previous-task-link">
          {toPreviousTaskLink(toPreviousTaskLinkParams(version, person))}
        </span>
        &nbsp;
        <span className="govuk-!-margin-bottom-0 previous-movement-link">
          {toPreviousMovementLink(person)}
        </span>
      </div>
    </>
  );
};

const toAgeColumnContent = (person) => {
  return (
    <div className="font__bold">{PersonUtil.age(person)}</div>
  );
};

const toDocumentColumnContent = (person, version) => {
  const document = DocumentUtil.get(person);
  return (
    <>
      <div className="font__bold">{DocumentUtil.docType(document)}</div>
      <div className="font__light">{DocumentUtil.docNumber(document)}</div>
      <div className="font__light">Issued by {DocumentUtil.docCountryCode(document)}</div>
      <div className="previous-task-link">
        {toPreviousTaskLink(toPreviousTaskLinkParams(version, document))}
      </div>
      <div className="previous-movement-link">
        {toPreviousMovementLink(document)}
      </div>
    </>
  );
};

const toRows = (allPersons, version) => {
  const rows = [];
  allPersons.map((person) => {
    rows.push([
      toTravellerColumnContent(person, version),
      toAgeColumnContent(person),
      toDocumentColumnContent(person, version),
    ]);
  });
  return rows;
};

const CoTraveller = ({ version }) => {
  const coTravellers = PersonUtil.getOthers(version);
  if (!coTravellers.length) {
    return null;
  }
  return (
    <div className="co-travellers-container font__bold govuk-!-padding-top-2 govuk-!-margin-bottom-4">
      <Heading className="govuk-!-margin-top-0" size="m">
        {pluralise.withCount(PersonUtil.othersCount(version), '% Co-traveller', '% Co-travellers', undefined)}
      </Heading>
      <Table
        className="co-travellers-table"
        headings={CO_TRAVELLERS_TABLE_HEADERS}
        rows={toRows(coTravellers, version)}
      />
    </div>
  );
};

export default CoTraveller;
