import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import * as pluralise from 'pluralise';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';

// Config(s)
import { FILTER_TAB_ID, TAB_ID, TAB_WORDING_MAP } from '../../../../../../utils/constants';

// Context(s)
import { NotificationContext } from '../../../../../../context/NotificationContext';
import { TasksContext } from '../../../../../../context/TasksContext';
import { TabContext } from '../../../../../../context/TabContext';

// Component(s)
import Checkbox from '../../../../../../components/Checkboxes/Checkbox';
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import Pagination from '../../../../../../components/Pagination/Pagination';
import TaskCardWrapper from '../../../shared/components/TaskCardWrapper';

const TasksRule = ({
  isLoading,
  tabId,
  itemsPerPage,
  totalNumberOfTasks,
  totalPages,
  redirectPath,
  activePage,
  filtersApplied,
}) => {
  dayjs.extend(utc);
  dayjs.extend(relativeTime);

  const { notification } = useContext(NotificationContext);
  const {
    removeSelection,
    removeDeleteSelection,
    selectedForDismissal,
    setSelectedForDismissal,
    selectedForDelete,
    setSelectedForDelete,
    isSelecting,
    setTaskIds,
    taskIds,
    tasks,
    taskIdsToRemove,
  } = useContext(TasksContext);
  const { isDismissTab, isNewTab, selectedFilterTab, isToolsTab } = useContext(TabContext);

  const selectAllCheckboxLabel = () => {
    return pluralise.withCount(totalNumberOfTasks, 'Select all', 'Select all % tasks', '');
  };

  const selectAllFilterCheckboxLabel = () => {
    return pluralise.withCount(totalNumberOfTasks, 'Select all', 'Select all % matches', '');
  };

  const isSelectAllChecked = () => {
    return (selectedForDismissal.length === totalNumberOfTasks)
      && selectedForDismissal.length
      && totalNumberOfTasks;
  };

  const isSelectAllFilterChecked = () => {
    return (selectedForDelete.length === totalNumberOfTasks)
      && selectedForDelete.length
      && totalNumberOfTasks;
  };

  /**
   * Because this component uses taskIds and only when the component is
   * initialized, restoring a task won't alter the list of ids especially
   * if there are no tasks selected for dismissal (removeSelection acts
   * upon selectedForDismissal). So we need to compare the list of
   * taskIdsToRemove against it.
   */
  const correctedTasks = () => {
    if (taskIdsToRemove.length > 0) {
      return taskIds.filter((taskId) => !taskIdsToRemove?.includes(taskId));
    }
    return taskIds;
  };

  /**
   * If the totalNumberOfTasks is less than the length of taskIds, then
   * we know that we need to fix the taskIds accordingly, with the updated
   * list. Then continue to remove the taskIds via TasksContext' removeSelection
   */
  useEffect(() => {
    if (totalNumberOfTasks < taskIds.length) {
      if (selectedFilterTab === FILTER_TAB_ID.FILTERS) {
        setTaskIds(correctedTasks());
        taskIdsToRemove.forEach((taskId) => removeSelection(taskId));
      }

      if (selectedFilterTab === FILTER_TAB_ID.TOOLS) {
        setTaskIds(correctedTasks());
        taskIdsToRemove.forEach((taskId) => removeDeleteSelection(taskId));
      }
    }
  }, [totalNumberOfTasks]);

  if (!notification && isLoading) {
    return <LoadingSpinner />;
  }

  if (tasks.length === 0) {
    let message = `There are no ${TAB_WORDING_MAP[tabId]} tasks`;
    if (filtersApplied && isNewTab) {
      message = 'No tasks match selected filters';
    } else if (tabId === TAB_ID.SELECTED_FOR_DISMISSAL) {
      message = 'There are no tasks selected for dismissal';
    }

    return <p className="govuk-body-l" aria-label={message}>{message}</p>;
  }

  return (
    <>
      <ComponentWrapper show={filtersApplied}>
        <Pagination
          id="pagination-top"
          totalItems={totalNumberOfTasks}
          itemsPerPage={itemsPerPage}
          activePage={activePage}
          totalPages={totalPages}
        />
      </ComponentWrapper>
      <div className="task-list-card-container">
        <ComponentWrapper show={isSelecting && isDismissTab && totalNumberOfTasks}>
          <div className="task-list-checkbox-master">
            <Checkbox
              id="checkbox-master"
              className="govuk-checkboxes--small"
              name="checkbox-master"
              aria-label={selectAllCheckboxLabel()}
              label={selectAllCheckboxLabel()}
              onChange={({ target }) => {
                if (target.checked) {
                  setSelectedForDismissal(correctedTasks());
                } else {
                  setSelectedForDismissal([]);
                }
              }}
              selected={isSelectAllChecked()}
            />
          </div>
        </ComponentWrapper>
        <ComponentWrapper show={(isToolsTab && totalNumberOfTasks && !filtersApplied)}>
          <div className="task-list-no-tasks-text">
            [ Tasks will be loaded when a rule has been selected ]
          </div>
        </ComponentWrapper>
        <ComponentWrapper show={isToolsTab && totalNumberOfTasks && filtersApplied}>
          <div className="task-list-checkbox-master">
            <Checkbox
              id="checkbox-master-tools"
              className="govuk-checkboxes--small"
              name="checkbox-master"
              aria-label={selectAllFilterCheckboxLabel()}
              label={selectAllFilterCheckboxLabel()}
              onChange={({ target }) => {
                if (target.checked) {
                  setSelectedForDelete(correctedTasks());
                } else {
                  setSelectedForDelete([]);
                }
              }}
              selected={isSelectAllFilterChecked()}
            />
          </div>
        </ComponentWrapper>
        <AnimatePresence>
          <ComponentWrapper show={filtersApplied}>
            {tasks?.map((task) => (
              <TaskCardWrapper
                key={`unique-key-${task.id}`}
                motionKey={`unique-key-${task.id}`}
                task={task}
                redirectPath={redirectPath}
                tabId={tabId}
              />
            ))}
          </ComponentWrapper>
        </AnimatePresence>
      </div>
      <ComponentWrapper show={filtersApplied}>
        <Pagination
          id="pagination-bottom"
          totalItems={totalNumberOfTasks}
          itemsPerPage={itemsPerPage}
          activePage={activePage}
          totalPages={totalPages}
        />
      </ComponentWrapper>
    </>
  );
};

export default TasksRule;

TasksRule.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tabId: PropTypes.string,
  itemsPerPage: PropTypes.number.isRequired,
  totalNumberOfTasks: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  redirectPath: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  filtersApplied: PropTypes.bool.isRequired,
};

TasksRule.defaultProps = {
  tabId: TAB_ID.NEW,
};
