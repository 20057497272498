/* eslint-disable no-prototype-builtins */
// Global import(s)
import classNames from 'classnames';
import React from 'react';
import { Link as InternalLink } from 'react-router-dom';

// Config
import { FONT_CLASSES, STRINGS, TEXT_DEFAULTS } from '../../../../../../utils/constants';

// Component(s)
import ExternalLink from '../../../../../../components/Link/ExternalLink';
import PreviousTasksCount from '../../../../../../components/PreviousTasks/PreviousTasksCount';

// Util(s)
import asUnknownIfRequired from '../../../../../../utils/Transform/valueSanitizer';
import { CommonUtil } from '../../../../../../utils';
import { getHiddenValue } from '../../../../../../utils/Hiding/hidingUtil';

export const DEFAULT_HIGHLIGHT_CLASS = 'task-versions--highlight';

export const getAllKeyValues = (data) => {
  const values = [];

  const getValues = (_data) => {
    const keys = Object.keys(_data);
    keys.forEach((k) => {
      if (typeof _data[k] === 'object') {
        getValues(_data[k]);
      } else {
        values.push(_data[k]);
      }
    });
  };

  getValues(data);
  return values;
};

export const toField = (content, hasChanged, entitySearchUrl = undefined) => {
  return {
    content,
    hasChanged,
    ...(entitySearchUrl && { entitySearchUrl }),
  };
};

const toLabelContent = (header) => {
  if (typeof header === 'object') {
    const { content, hasChanged } = header;
    return (
      <p
        className={classNames('govuk-!-margin-bottom-0 font__light', hasChanged && DEFAULT_HIGHLIGHT_CLASS)}
      >
        {content}
      </p>
    );
  }
  return <p className="govuk-!-margin-bottom-0 font__light">{header}</p>;
};

export const toEntitySearchLinkContent = (item) => {
  const { content, url } = item;
  if (url) {
    return (<ExternalLink href={url} content={content} className="entity-search-link" withIcon />);
  }
  return asUnknownIfRequired(content);
};

const toValueContent = (items) => {
  const uniqueItems = CommonUtil.uniqueId(items);
  return uniqueItems && uniqueItems.map((item, index) => {
    if (typeof item === 'object' && !item.hasOwnProperty('_owner')) {
      const { hasChanged } = item;
      return (
        <div key={item.id} className="flex-content-container">
          <span
            className={classNames(
              TEXT_DEFAULTS.CSS.GOV_MARGIN_B0,
              'govuk-!-margin-right-1',
              'font__bold',
              hasChanged && DEFAULT_HIGHLIGHT_CLASS)}
          >
            {toEntitySearchLinkContent(item)}
          </span>
        </div>
      );
    }
    return (
      <p key={index} className={classNames(TEXT_DEFAULTS.CSS.GOV_MARGIN_B0, FONT_CLASSES[index % 2])}>
        {item}
      </p>
    );
  });
};

export const renderRow = (header, item, hasChanged) => {
  const { content } = item;
  const highlight = hasChanged ? DEFAULT_HIGHLIGHT_CLASS : null;
  return (
    <div className="govuk-!-margin-bottom-2">
      <p className={classNames(TEXT_DEFAULTS.CSS.GOV_MARGIN_B0, 'font__light', highlight)}>
        {header}
      </p>
      <p className={classNames(TEXT_DEFAULTS.CSS.GOV_MARGIN_B0, 'font__bold', 'word-break', highlight)}>
        {asUnknownIfRequired(content)}
      </p>
    </div>
  );
};

const renderMultiBlock = (header, items) => {
  return (
    <div className="govuk-!-margin-bottom-2">
      {toLabelContent(header)}
      {toValueContent(items)}
    </div>
  );
};

export const toPreviousTaskLinkParams = (version, entity) => {
  return {
    movementId: version?.movement?.id ?? null,
    entity,
  };
};

export const toPreviousTaskLink = (params) => {
  if (!params) {
    return null;
  }

  if (CommonUtil.getNumberOfPreviousTasks(params.entity) === getHiddenValue()) {
    return null;
  }

  const previousTaskPageUrl = CommonUtil.getPreviousTaskLinkUrl(params);
  if (!previousTaskPageUrl) {
    return null;
  }
  const text = CommonUtil.getPreviousTaskLinkText(params.entity);
  if (CommonUtil.isPreviousTaskLinkHidden()) {
    return null;
  }

  if (CommonUtil.isPreviousTaskEnabled()) {
    return <PreviousTasksCount numberOfPreviousTasks={CommonUtil.getNumberOfPreviousTasks(params.entity)} />;
  }

  return (
    <InternalLink to={previousTaskPageUrl} target="_blank" rel={STRINGS.NOOPENER} className="govuk-!-margin-right-1">
      {text}
    </InternalLink>
  );
};

const toPreviousMovementLinkParams = (entity) => {
  return {
    movementSearchUrl: entity?.movementSearchUrl,
    movementCount: entity?.movementStats?.movementCount,
  };
};

export const toPreviousMovementLink = (entity) => {
  const { movementSearchUrl, movementCount } = toPreviousMovementLinkParams(entity);

  if (!movementSearchUrl || CommonUtil.isPreviousMovementLinkHidden()) {
    return null;
  }

  const linkText = CommonUtil.getPreviousMovementsLinkText(movementCount);
  return (
    <ExternalLink href={movementSearchUrl} content={linkText} className="link--light" />
  );
};

const renderBlock = (header, item, hasChanged = false, previousTaskLink = null, movementsLink = null) => {
  if (!header && !item) {
    return (
      <div className="govuk-!-margin-bottom-2">
        <p className="govuk-!-margin-bottom-0 font__light" />
      </div>
    );
  }

  if (Array.isArray(item)) {
    return renderMultiBlock(header, item);
  }

  const highlight = hasChanged ? DEFAULT_HIGHLIGHT_CLASS : null;
  return (
    <div className="govuk-!-margin-bottom-2">
      <p className={classNames(TEXT_DEFAULTS.CSS.GOV_MARGIN_B0, 'font__light', highlight)}>
        {header}
      </p>
      <div className="flex-content-container">
        <span className={classNames(TEXT_DEFAULTS.CSS.GOV_MARGIN_B0, 'govuk-!-margin-right-1', 'font__bold', highlight)}>{toEntitySearchLinkContent(item)}</span>
      </div>
      {previousTaskLink && (
        <p className={classNames(TEXT_DEFAULTS.CSS.GOV_MARGIN_B0, 'previous-task-link')}>
          {previousTaskLink}
        </p>
      )}
      {movementsLink && (
        <p className={classNames(TEXT_DEFAULTS.CSS.GOV_MARGIN_B0, 'previous-movement-link')}>
          {movementsLink}
        </p>
      )}
    </div>
  );
};

export default renderBlock;
