// Global import(s)
import { Heading, Link } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Config(s)
import { TIME_FORMATS, STRINGS } from '../../../../../../../../../utils/constants';

// Components
import ComponentWrapper from '../../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import DepartureStatus from '../../../../../../../../../components/DepartureStatus/DepartureStatus';
import Contents from '../../content/Contents';
import Details from '../../generics/Details';
import Documents from './Documents';
import EntitySearchModal from '../../prompts/EntitySearchModal';
import HeaderModule from '../../generics/HeaderModule';
import LabelValuePair from '../../generics/LabelValuePair';
import ValueField from '../../generics/ValueField';

// Util(s)
import BookingUtil from '../../../../../../../../../utils/Booking/bookingUtil';
import CommonUtil from '../../../../../../../../../utils/Common/Uplift/commonUtil';
import DateTimeUtil from '../../../../../../../../../utils/Datetime/Uplift/datetimeUtil';
import DocumentUtil from '../../../../../../../../../utils/Document/Uplift/documentUtil';
import FlightUtil from '../../../../../../../../../utils/Flight/Uplift/flightUtil';
import JourneyUtil from '../../../../../../../../../utils/Journey/Uplift/journeyUtil';
import PersonUtil from '../../../../../../../../../utils/Person/Uplift/personUtil';
import PersonS4Util from '../../../../../../../../../utils/Person/personS4Util';
import { getMaskedValue } from '../../../../../../../../../utils/Masking/maskingUtil';
import { isPersonFullNameMasked, toAgeAtTravel, toDateOfBirth } from './helper/common';

const Person = ({ person, booking, flight, journey, movementId, movementIds, positionedAt, s4Person }) => {
  const { taskId } = useParams();

  const adjustedPositionedAt = positionedAt + 1;
  const additionalContent = PersonUtil.additionalContent(person);
  const documents = DocumentUtil.getDocs(person);
  const fullName = PersonUtil.formattedName(PersonUtil.firstname(person))(PersonUtil.lastname(person, true));
  const { otherMovementsUrl, movementCount } = CommonUtil.otherMovementsLinkParams(person);
  const movementSearchLabel = CommonUtil.otherMovementsLabel(movementCount);
  const otherTasksListURL = CommonUtil.otherTasksListURL(CommonUtil.otherTasksLinkParams(movementId, person, taskId, movementIds));
  const otherTasksURLLabel = CommonUtil.otherTasksListLabel(person);
  const departureStatus = PersonUtil.passengerStatus(person);
  const iso2NationalityCode = PersonUtil.nationality(person);
  const formattedNationality = CommonUtil.format.nationality(iso2NationalityCode);
  const iso3NationalityCode = CommonUtil.iso3Code(iso2NationalityCode);
  const departureLocation = JourneyUtil.departureLoc(journey);
  const departureTime = JourneyUtil.departureTime(journey);
  const gender = PersonUtil.gender(person);
  const formattedGender = PersonUtil.format.gender(gender);
  const seatNumber = FlightUtil.seatNumber(flight);
  const frequentFlyerNumber = PersonUtil.frequentFlyerNumber(person);
  const dateOfBirth = PersonUtil.dob(person);
  const checkInTime = BookingUtil.checkInAt(booking);
  const typeIdentifer = PersonS4Util.typeIdentifer(s4Person);
  const portOfEmbark = PersonS4Util.portOfEmbark(s4Person);
  const distinct = PersonS4Util.distinct(s4Person);
  const typeCode = PersonS4Util.typeCode(s4Person);
  const sipURL = CommonUtil.sipEntitySearchURL(person);
  const centaurURL = CommonUtil.centaurEntitySearchURL(person);

  const toCheckInTime = () => {
    if (!departureLocation || !checkInTime) {
      return null;
    }

    if ([departureLocation, checkInTime].includes(getMaskedValue())) {
      return getMaskedValue();
    }

    const timezone = DateTimeUtil.timezone(departureLocation);
    return DateTimeUtil.format.toTimezone(checkInTime, timezone, TIME_FORMATS.BOOKING_CHECK_IN);
  };

  const toCleanedDifference = (value) => {
    const CLEAN_STRINGS = [STRINGS.DAYJS_FUTURE, STRINGS.DAYJS_PAST];
    if (value) {
      CLEAN_STRINGS.forEach((string) => {
        value = value.replace(string, '');
      });
    }
    return value;
  };

  const toCheckInDifference = () => {
    const timezone = DateTimeUtil.timezone(departureLocation);
    if (!departureLocation || !timezone || !departureTime || !checkInTime) {
      return null;
    }

    if ([departureLocation, departureTime, checkInTime].includes(getMaskedValue())) {
      return getMaskedValue();
    }

    const departureLocalDate = DateTimeUtil.local.date(departureTime, timezone);
    const checkInLocalDate = DateTimeUtil.local.date(checkInTime, timezone);
    const difference = departureLocalDate.from(checkInLocalDate);
    return `${toCleanedDifference(difference)}pre-departure`;
  };

  if (!person) {
    return null;
  }

  const toDistinct = () => {
    return distinct?.toString();
  };

  return (
    <div id={`person-${adjustedPositionedAt}-module`} className="govuk-grid-column-full">
      <HeaderModule
        id="person-details"
        items={[
          {
            content: (
              <div key="1" className="grid-item">
                <Heading id={`person-${adjustedPositionedAt}-header`} size="m">{`Passenger ${adjustedPositionedAt}`}</Heading>
                <DepartureStatus id={`person-${adjustedPositionedAt}-departure-status`} departureStatus={departureStatus} />
              </div>
            ),
          },
          {
            content: (
              <ComponentWrapper key="2" show={fullName}>
                <div className="grid-item">
                  <ComponentWrapper show={!sipURL && !centaurURL}>
                    <ValueField id={`person-${adjustedPositionedAt}-fullname`} value={fullName} />
                  </ComponentWrapper>
                  <ComponentWrapper show={sipURL || centaurURL}>
                    {!isPersonFullNameMasked(PersonUtil.firstname(person)) && !isPersonFullNameMasked(PersonUtil.lastname(person, true)) ? (
                      <EntitySearchModal
                        id={`person-${adjustedPositionedAt}-fullname`}
                        modalHeading={`Search full name ${fullName} on:`}
                        label={fullName}
                        sipURL={sipURL}
                        centaurURL={centaurURL}
                      />
                    ) : <ValueField id={`document-${positionedAt}-number`} value={fullName} />}
                  </ComponentWrapper>
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="3" show={otherMovementsUrl && movementSearchLabel}>
                <div className="grid-item">
                  <Link id={`person-${adjustedPositionedAt}-movement-link`} href={otherMovementsUrl} target="_blank" rel="noreferrer noopener">
                    {movementSearchLabel}
                  </Link>
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="5" show={otherTasksListURL && otherTasksURLLabel}>
                <div className="grid-item">
                  <Link id={`person-${positionedAt}-other-tasks-link`} href={otherTasksListURL} target="_blank" rel="noreferrer noopener">
                    {otherTasksURLLabel}
                  </Link>
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      <Details
        items={[
          {
            content: (
              <ComponentWrapper key="1" show={iso2NationalityCode && formattedNationality}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-nationality`}
                    label="Nationality"
                    value={`${formattedNationality} - ${iso3NationalityCode}`}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="2" show={gender}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-gender`}
                    label="Gender"
                    value={formattedGender}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="3" show={dateOfBirth && departureTime}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-age`}
                    label="Age at travel"
                    value={toAgeAtTravel(dateOfBirth, departureTime)}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="4" show={dateOfBirth}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-dob`}
                    label="Date of birth"
                    value={toDateOfBirth(dateOfBirth)}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="5" show={checkInTime && positionedAt === 0}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-checked-in`}
                    label="Checked in"
                    value={toCheckInTime()}
                    secondaryValue={toCheckInDifference()}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="6" show={seatNumber && positionedAt === 0}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-seat-number`}
                    label="Seat number"
                    value={seatNumber}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="7" show={frequentFlyerNumber && positionedAt === 0}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-frequent-flyer-number`}
                    label="Frequent Flyer Number"
                    value={frequentFlyerNumber}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="8" show={typeIdentifer}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-passenger-type-identifer`}
                    label="Passenger Identifier Type"
                    value={typeIdentifer}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="9" show={portOfEmbark}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-port-of-embark`}
                    label="Port of embark"
                    value={portOfEmbark}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="10" show={distinct !== null}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-distinct-flag`}
                    label="Distinct"
                    value={toDistinct()}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="11" show={typeCode}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-passenger-type-code`}
                    label="Passenger Type Code"
                    value={typeCode}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      <Contents id="person-contents" contents={additionalContent} />
      <Documents documents={documents} journey={journey} movementId={movementId} movementIds={movementIds} />
    </div>
  );
};

Person.propTypes = {
  movementId: PropTypes.string.isRequired,
  movementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  positionedAt: PropTypes.number.isRequired,
  booking: PropTypes.shape({}).isRequired,
  person: PropTypes.shape({}),
  flight: PropTypes.shape({}).isRequired,
  journey: PropTypes.shape({}).isRequired,
  s4Person: PropTypes.shape({}),
};

Person.defaultProps = {
  person: null,
  s4Person: null,
};

export default Person;
