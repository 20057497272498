// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Context(s)
import { useTask } from '../../../../../../../../../context/TaskContext';
import ComponentWrapper from '../../../../../../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import { isIdpSubMode } from '../../../../../../../../../utils/Task/taskUtil';

const OutcomeCaption = ({ recipients, withdrawnRecipients }) => {
  const { subMode } = useTask();

  if (!recipients || !recipients.length || isIdpSubMode(subMode)) {
    return null;
  }

  // Default to commodities
  return (
    <>
      {recipients.map((targetRecipient) => {
        return (
          <div key={targetRecipient.customName} className="outcome-target-recipient">
            <strong>{targetRecipient.customName}</strong>
            <ComponentWrapper show={targetRecipient.telephone}>
              <>
                <br />
                <strong>{`Tel: ${targetRecipient.telephone}`}</strong>
              </>
            </ComponentWrapper>
          </div>
        );
      })}
      <ComponentWrapper show={withdrawnRecipients.length}>
        <div>
          <Heading id="withdrawn-outcome-header" size="xl">Target withdrawn for</Heading>
          {withdrawnRecipients.map((recipient) => (
            <div key={recipient.customName} className="outcome-withdrawn-recipient">
              <strong>{recipient.customName}</strong>
              <ComponentWrapper show={recipient.telephone}>
                <>
                  <br />
                  <strong>{`Tel: ${recipient.telephone}`}</strong>
                </>
              </ComponentWrapper>
            </div>
          ))}
        </div>
      </ComponentWrapper>
    </>
  );
};

OutcomeCaption.propTypes = {
  recipients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  withdrawnRecipients: PropTypes.arrayOf(PropTypes.shape({})),
};

OutcomeCaption.defaultProps = {
  withdrawnRecipients: [],
};

export default memo(OutcomeCaption);
