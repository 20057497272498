// Global import(s)
import PropTypes from 'prop-types';
import React from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

// Component(s)
import TitleSection from './TitleSection';
import PreviousTaskDetails from './PreviousTaskDetails';
import ComponentWrapper from '../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import {
  getMovementMode,
  getCreatedAt,
  getSource,
  isTaskLive,
} from '../../../../utils/PreviousTask/previousTaskUtil';

import './PreviousTaskListCard.scss';

const PreviousTaskListCard = ({ task }) => {
  const location = useLocation();
  const queryParams = qs.parse(location?.search, { ignoreQueryPrefix: true });
  const currentTaskId = queryParams?.['current-task-id'];
  const isLiveTask = isTaskLive(task);
  const isTaskFromWhichHaveNavigatedFrom = currentTaskId === getSource(task)?.taskId;

  return (
    <div className="previous-task-list-card govuk-!-margin-bottom-5">
      <TitleSection movementMode={getMovementMode(task)} createdAt={getCreatedAt(task)} source={getSource(task)} isLiveTask={isLiveTask} />
      <ComponentWrapper show={isTaskFromWhichHaveNavigatedFrom}>
        <div className="previous-task-list-card-green-divider" />
      </ComponentWrapper>
      <PreviousTaskDetails task={task} />
    </div>
  );
};

PreviousTaskListCard.propTypes = {
  task: PropTypes.shape({}).isRequired,
};

export default PreviousTaskListCard;
