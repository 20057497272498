import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import classNames from 'classnames';

import { RisksUtil } from '../../../../../../../../utils';

import './TargetingIndicators.scss';

const toTargetingIndicatorsBlock = (indicators) => {
  return indicators.map((indicator, index) => {
    const classModifiers = index !== indicators.length - 1
      ? 'govuk-task-details-grid-row bottom-border'
      : 'govuk-task-details-grid-row';
    return (
      <div key={indicator?.id} className={classNames(classModifiers)}>
        <ul className="govuk-!-margin-bottom-0 govuk-!-padding-left-3">
          <li className="govuk-grid-key list-bullet font__light">
            {RisksUtil.indicatorDescription(indicator)}
          </li>
        </ul>
        <span className="govuk-grid-value font__bold">
          {RisksUtil.indicatorScore(indicator)}
        </span>
      </div>
    );
  });
};

const toHiddenIndicators = (indicators) => {
  if (!indicators?.length) {
    return undefined;
  }

  return (
    <details className="govuk-details govuk-!-padding-bottom-1" data-module="govuk-details">
      <summary className="govuk-details__summary">
        <span className="govuk-details__summary-text">Show more</span>
      </summary>
      {toTargetingIndicatorsBlock(indicators)}
    </details>
  );
};

const TargetingIndicators = ({ version, classModifiers }) => {
  const targetingIndicators = RisksUtil.targetingIndicators(RisksUtil.getRisks(version));
  const indicators = RisksUtil.indicators(targetingIndicators);

  const visibleIndicators = indicators.slice(0, 5); // Display the first 5
  const hiddenIndicators = indicators.slice(5);
  return (
    <>
      <Heading className="govuk-!-margin-top-0" size="m">Targeting indicators</Heading>
      <div className={classNames('govuk-task-details-indicator-container', 'govuk-!-margin-bottom-2', classModifiers)}>
        <div className="govuk-task-details-grid-row bottom-border">
          <span className="govuk-grid-key font__light">Indicators</span>
          <span className="govuk-grid-value font__light">Total score</span>
        </div>
        <div className="govuk-task-details-grid-row govuk-!-padding-bottom-1">
          <span className="govuk-grid-key font__bold">{RisksUtil.indicatorCount(targetingIndicators)}</span>
          <span className="govuk-grid-key font__bold">{RisksUtil.indicatorScore(targetingIndicators)}</span>
        </div>
        {visibleIndicators && visibleIndicators?.length ? (
          <>
            <div className="govuk-task-details-grid-row bottom-border">
              <span className="govuk-grid-key font__bold">Indicator</span>
              <span className="govuk-grid-value font__bold">Score</span>
            </div>
            <div className="task-details-container">
              {toTargetingIndicatorsBlock(visibleIndicators)}
              {toHiddenIndicators(hiddenIndicators)}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default TargetingIndicators;
