// Global import(s)
import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import LinkButton from '../../../../../../../../components/Buttons/LinkButton';

// Hook(s)
import useScreenSize from '../../../../../../../../utils/Hooks/useScreenSize';

const AddTaskNoteLinkButton = ({ id, label, onClick, ...props }) => {
  return (
    <LinkButton
      id={id}
      className="govuk-link ho-link-body"
      onClick={onClick}
      {...props}
    >
      {label}
    </LinkButton>
  );
};

const AddTaskNoteButton = ({ onClick }) => {
  const id = 'add-task-note-button';
  const label = 'Add task note';
  const ariaLabel = 'add note to task';

  const { isNineteenTwentyOrMorePixels } = useScreenSize();

  if (!isNineteenTwentyOrMorePixels) {
    return <AddTaskNoteLinkButton id={id} label={label} onClick={onClick} aria-label={ariaLabel} />;
  }

  return (
    <Button
      id={id}
      classModifiers="secondary"
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {label}
    </Button>
  );
};

AddTaskNoteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddTaskNoteButton;
