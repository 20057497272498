import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import LinkButton from '../../../../../../../../../../components/Buttons/LinkButton';
import Table from '../../../../../../../../../../components/Table/Table';

// Styling
import './IdpTargetRecipients.scss';

const IdpTargetRecipients = ({ formData, onChangeRecipient, onRemoveRecipient, ...props }) => {
  const TABLE_HEADINGS = ['Recipient', 'Telephone', 'Actions'];
  const tr = formData?.targetRecipients || [];

  if (!Array.isArray(tr) || tr.filter((item) => item !== undefined).length === 0) {
    return null;
  }

  const replaceSpacesWithDashes = (email) => {
    return email.split('@').join('-');
  };

  const toRows = () => {
    return formData?.targetRecipients.map((targetRecipient) => [
      targetRecipient.email,
      targetRecipient.telephone,
      <LinkButton
        key={targetRecipient.email}
        id={`change-recipient-${replaceSpacesWithDashes(targetRecipient.email)}`}
        className="govuk-link"
        data-recipientname={targetRecipient.email}
        data-email={targetRecipient.email}
        onClick={(e) => {
          const { dataset } = e.target;
          onChangeRecipient(dataset.email);
        }}
      >
        Change
      </LinkButton>,
      (formData?.targetRecipients.length > 0 ? (
        <LinkButton
          id={`remove-recipient-${replaceSpacesWithDashes(targetRecipient.email)}`}
          className="govuk-link"
          data-recipientname={targetRecipient.email}
          data-email={targetRecipient.email}
          onClick={(e) => {
            const { dataset } = e.target;
            onRemoveRecipient(dataset.email);
          }}
        >
          Remove
        </LinkButton>
      ) : null),
    ].filter((item) => !!item));
  };

  return (
    <Table
      {...props}
      className="target-recipients"
      headings={TABLE_HEADINGS}
      rows={toRows()}
    />
  );
};

IdpTargetRecipients.propTypes = {
  formData: PropTypes.shape({}),
  onChangeRecipient: PropTypes.func.isRequired,
  onRemoveRecipient: PropTypes.func.isRequired,
};

IdpTargetRecipients.defaultProps = {
  formData: null,
};

export default IdpTargetRecipients;
