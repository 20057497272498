// Config(s)
import DEFAULTS, { SORT_PARAMS } from '../../constants';
import { DATE_FORMATS, VIEW } from '../../../../../utils/constants';
import { SORT_ORDER } from '../../../../Task/TaskList/shared/constants';

// Util(s)
import DatetimeUtil from '../../../../../utils/Datetime/Uplift/datetimeUtil';

const toTaskStatuses = (selectedTab, view) => {
  return DEFAULTS[view]?.tabs?.find((tab) => tab.id === selectedTab)?.statuses;
};

const toMovementModes = (appliedFilters, view) => {
  return appliedFilters?.movementModes?.length
    ? appliedFilters?.movementModes
    : DEFAULTS[view]?.filters?.movementModes;
};

const toGroupCodes = (appliedFilters, groupCodes) => {
  return appliedFilters?.groupCodes?.length ? appliedFilters?.groupCodes : groupCodes;
};

const toTeamCodes = (teamGroup) => {
  return teamGroup?.keycloakgrouppath ? [teamGroup.keycloakgrouppath.replace('/', '')] : [];
};

const toAssignees = (currentUser) => {
  return [currentUser];
};

const shouldAddGroupsCodes = (view) => {
  return DEFAULTS[view]?.filterByGroupCodes;
};

const shouldAddAssignees = (view) => {
  return DEFAULTS[view]?.filterByAssignees;
};

const toJourneyDirections = (appliedFilters) => {
  return appliedFilters?.journeyDirections?.length ? appliedFilters?.journeyDirections : [];
};

const toCategories = (appliedFilters) => {
  return appliedFilters?.categories?.length ? appliedFilters?.categories : [];
};

const toSortParams = (sortParamsOrder) => {
  return [
    {
      field: SORT_PARAMS.EVENT_TIME,
      order: sortParamsOrder,
    },
    {
      field: SORT_PARAMS.JOURNEY_DIRECTION,
      order: SORT_ORDER.DESC,
    },
    {
      field: SORT_PARAMS.SELECTOR_CATEGORY,
      order: SORT_ORDER.ASC,
    },
  ];
};

const toEventRange = (appliedFilters) => {
  const FORM_DATE_FORMAT = 'D-M-YYYY';

  if (!appliedFilters?.rangeFrom || !appliedFilters?.rangeTo) {
    return null;
  }

  return {
    startDate: DatetimeUtil.format.asUTC(
      appliedFilters.rangeFrom,
      FORM_DATE_FORMAT,
      DATE_FORMATS.UTC,
    ),
    endDate: DatetimeUtil.endOfDay(appliedFilters.rangeTo, FORM_DATE_FORMAT, DATE_FORMATS.UTC),
  };
};

/**
 * Creates the required params required for fetching targets.
 *
 * @param {*} options The filter options.
 * @returns A filter param object.
 */
const toFilterParams = ({
  itemsPerPage,
  activePage,
  appliedFilters,
  sortParamsOrder,
  currentUser,
  teamGroup,
  groupCodes,
  selectedTab,
  view,
}) => {
  return {
    filterParams: {
      movementModes: toMovementModes(appliedFilters, view),
      statuses: toTaskStatuses(selectedTab, view),
      recipients: {
        groupCodes: shouldAddGroupsCodes(view) ? toGroupCodes(appliedFilters, groupCodes) : [],
        users: [currentUser],
      },
      teamCodes: shouldAddAssignees(view) ? toTeamCodes(teamGroup) : [],
      assignees: shouldAddAssignees(view) ? toAssignees(currentUser) : [],
      journeyDirections: toJourneyDirections(appliedFilters),
      event: toEventRange(appliedFilters),
      categories: toCategories(appliedFilters),
    },
    sortParams: toSortParams(sortParamsOrder),
    pageParams: {
      limit: itemsPerPage,
      offset: (activePage - 1) * itemsPerPage,
    },
  };
};

/**
 * Creates the required params required for fetching target group counts.
 *
 * @param {*} options The filter options.
 * @returns A filter param object.
 */
const toGroupCountParams = ({
  appliedFilters,
  currentUser,
  teamGroup,
  groupCodes,
  selectedTab,
  view,
}) => {
  return {
    ...toFilterParams({
      appliedFilters,
      currentUser,
      teamGroup,
      groupCodes,
      selectedTab,
      view,
    }).filterParams,
    recipients: {
      groupCodes,
    },
  };
};

/**
 * Creates the required params required for fetching targets status counts.
 *
 * @param {*} options The filter options.
 * @returns A filter param object.
 */
const toStatusCountParams = ({
  appliedFilters,
  currentUser,
  teamGroup,
  groupCodes,
  selectedTab,
  view,
}) => {
  // #17952 Becuase navigation occurs before page loads, the view is
  // returning as undefined;
  const correctedView = (view === 'RORO' || view === undefined)
    ? VIEW.RORO_IO : view;
  return DEFAULTS[correctedView]?.tabs?.map((tab) => {
    return {
      ...toFilterParams({
        appliedFilters,
        currentUser,
        teamGroup,
        groupCodes,
        selectedTab,
        view,
      }).filterParams,
      statuses: tab?.statuses,
    };
  });
};

export default toFilterParams;

export { toGroupCountParams, toStatusCountParams };
