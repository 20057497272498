import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import Highlight from '../../../../../../../../components/Highlight/Highlight';

// Styling
import './ContactText.scss';

const ContactTextItem = ({ contactText }) => {
  return (
    <>
      <span id={`contact-text-content-${contactText.text}`}>
        {contactText.matched
          ? <Highlight>{contactText.text}</Highlight>
          : contactText.text}
      </span>
    </>
  );
};

ContactTextItem.propTypes = {
  contactText: PropTypes.shape({
    text: PropTypes.string.isRequired,
    matched: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ContactTextItem;
