import { Details } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Util(s)
import { CommonUtil } from '../../../../../../../../utils';
import toFormattedIndicatorMatch from './helper/toFormattedIndicatorMatch';

// Styling
import './ThreatType.scss';

const ThreatTypeDefault = ({ rule, index }) => {
  return (
    <div key={`${rule?.name}-${index}`} className="govuk-grid-row matched-rule">
      <div className="govuk-grid-column-full">
        <Details id={`matched-rule-${rule?.name}`} summary={rule?.name}>
          <div className="govuk-grid-row govuk-!-margin-bottom-4">
            <div className="govuk-grid-column-one-half">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <p id={`rule-${rule?.name}-description-header`} className="rule-field-header">
                    Description
                  </p>
                </div>
              </div>
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <p id={`rule-${rule?.name}-description-value`}>
                    {rule?.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="govuk-grid-column-one-half">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <p id={`rule-${rule?.name}-threat-header`} className="rule-field-header">
                    Threat
                  </p>
                </div>
              </div>
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <p id={`rule-${rule?.name}-threat-value`}>
                    {rule?.threatType}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-one-half">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <p id={`rule-${rule?.name}-risk-indicators-header`} className="rule-field-header">
                    Risk Indicators
                  </p>
                </div>
              </div>
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <ol className="rule-risk-indicators">
                    {rule?.indicatorMatches && CommonUtil
                      .uniqueId(rule?.indicatorMatches)
                      .map((indicatorMatch) => (
                        <li
                          key={`rule-${rule?.name}-${index}-risk-indicator-${indicatorMatch?.id}`}
                          id={`rule-${rule?.name}-${index}-risk-indicator-value-${indicatorMatch?.id}`}
                        >
                          {toFormattedIndicatorMatch(indicatorMatch)}
                        </li>
                      ))}
                  </ol>
                </div>
              </div>
            </div>
            <div className="govuk-grid-column-one-quarter">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full">
                      <p id={`rule-${rule?.name}-agency-header`} className="rule-field-header">
                        Agency
                      </p>
                    </div>
                  </div>
                  <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full">
                      <p id={`rule-${rule?.name}-agency-value`} className="rule-agency">
                        {rule?.targetingTeam}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="govuk-grid-column-one-quarter">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-full">
                  <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full">
                      <p id={`rule-${rule?.name}-version-header`} className="rule-field-header">
                        Rule Version
                      </p>
                    </div>
                  </div>
                  <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full">
                      <p id={`rule-${rule?.name}-version-value`}>
                        {rule?.version}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Details>
      </div>
    </div>
  );
};

ThreatTypeDefault.propTypes = {
  rule: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
};
export default ThreatTypeDefault;
