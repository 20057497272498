import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import RenderForm from '../../../../../../../../components/RenderForm/RenderForm';

// Styling
import './FullScreenForm.scss';

const FullScreenForm = ({ form,
  viewOnly,
  preFillData,
  onSubmit,
  onChange,
  onCancel,
  uploadDocument,
  overrideSubmit,
  modal,
  ...props }) => {
  return (
    <div className="full-screen-form-container" {...props}>
      <div className="full-screen-form">
        <RenderForm
          form={form}
          viewOnly={viewOnly}
          preFillData={preFillData}
          onSubmit={onSubmit}
          onChange={onChange}
          onCancel={onCancel}
          uploadDocument={uploadDocument}
          overrideSubmit={overrideSubmit}
        />
        <ComponentWrapper show={!!modal}>
          {modal}
        </ComponentWrapper>
      </div>
    </div>
  );
};

FullScreenForm.propTypes = {
  form: PropTypes.object.isRequired,
  viewOnly: PropTypes.bool.isRequired,
  preFillData: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  uploadDocument: PropTypes.bool,
  modal: PropTypes.node,
};

FullScreenForm.defaultProps = {
  preFillData: null,
  uploadDocument: false,
  modal: null,
};

export default FullScreenForm;
