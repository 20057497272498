// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import classNames from 'classnames';
import React from 'react';

// Config(s)
import FIELDS from '../../Fields';
import { DATE_FORMATS } from '../../../../../../../../utils/constants';

// Utils
import BookingUtil from '../../../../../../../../utils/Booking/bookingUtil';
import renderBlock, { getAllKeyValues, toField, toPreviousTaskLink, toPreviousTaskLinkParams } from '../../common';
import { BookerUtil, CommonUtil, DateTimeUtil, JourneyUtil, TaskVersionUtil } from '../../../../../../../../utils';

const Booking = ({ version, versionDiff, classModifiers }) => {
  const booking = BookingUtil.get(version);
  const booker = BookerUtil.get(version);
  const address = CommonUtil.address(booker);
  const bookerDiff = BookerUtil.get(versionDiff);
  const bookingDiff = BookingUtil.get(versionDiff);
  const tickets = BookingUtil.tickets(booking);
  const journey = JourneyUtil.get(version);
  const journeyDiff = JourneyUtil.get(versionDiff);
  const departureTime = JourneyUtil.departureTime(journey);
  const patchedTickets = TaskVersionUtil.patch(tickets, BookingUtil.tickets(bookingDiff, true));
  const bookingJourneyDiff = { ...bookingDiff, ...journeyDiff?.departure };
  const bookingType = BookingUtil.bookingType(booking);
  const bookingReference = BookingUtil.bookingRef(booking);
  const formattedTicketNumbers = BookingUtil.format.ticketNumbers(tickets);
  const bookedAt = BookingUtil.bookedAt(booking);
  const formattedBookedAt = DateTimeUtil.format(bookedAt, DATE_FORMATS.LONG);
  const bookingTimeDifference = BookingUtil.format.bookingTimeDifference(bookedAt, departureTime);
  const checkInAt = BookingUtil.checkInAt(booking);
  const formattedCheckInDate = DateTimeUtil.format(checkInAt, DATE_FORMATS.LONG);
  const checkInTimeDifference = BookingUtil.format.bookingTimeDifference(checkInAt, departureTime);
  const bookingCountryIso2Code = BookingUtil.bookingCountry(booking);
  const bookingCountryIso3Code = CommonUtil.iso3Code(bookingCountryIso2Code);
  const formattedBookingCountryName = BookingUtil.format.countryName(bookingCountryIso2Code);
  const formattedTicketTypes = BookingUtil.format.ticketTypes(tickets);
  const formattedTicketPrices = BookingUtil.format.ticketPrices(tickets);
  const paymentMethod = BookingUtil.paymentMethod(booking);

  return (
    <div className={classNames('task-details-container', classModifiers)}>
      <Heading className="govuk-!-margin-top-0" size="m">Booking and check-in</Heading>
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Reference',
          {
            content: bookingReference,
          },
          TaskVersionUtil.hasAny(bookingDiff, [FIELDS.reference]),
        )}
        {renderBlock(
          'Ticket number',
          {
            content: formattedTicketNumbers,
          },
          !TaskVersionUtil.isSame(BookingUtil.format.ticketNumbers(tickets), BookingUtil.format.ticketNumbers(patchedTickets)),
        )}
        {renderBlock(
          'Type',
          {
            content: bookingType,
          },
          TaskVersionUtil.hasAny(bookingDiff, [FIELDS.type]),
        )}
        {renderBlock(
          'Name',
          {
            content: BookerUtil.name(booker),
          },
          TaskVersionUtil.hasAny(bookerDiff, [FIELDS.name]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, booker)),
        )}
        {renderBlock(
          'Address',
          {
            content: BookerUtil.address(booker),
          },
          TaskVersionUtil.hasAny(bookerDiff?.address, [...getAllKeyValues(FIELDS.address)]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, address)),
        )}
        {renderBlock(
          toField('Booking date and time', TaskVersionUtil.hasAny(bookingDiff, [FIELDS.bookedAt])),
          [
            toField(formattedBookedAt,
              TaskVersionUtil.hasAny(bookingDiff, [FIELDS.bookedAt])),
            toField(bookingTimeDifference,
              TaskVersionUtil.hasAny(bookingDiff, [FIELDS.bookedAt])),
          ],
        )}
        {renderBlock(
          'Country',
          {
            content: `${formattedBookingCountryName} (${bookingCountryIso3Code})`,
          },
          TaskVersionUtil.hasAny(bookingDiff, [FIELDS.country]),
        )}
        {renderBlock(
          'Payment method',
          {
            content: paymentMethod,
          },
          TaskVersionUtil.hasAny(bookingDiff, [FIELDS.paymentMethod]),
        )}
        {renderBlock(
          'Ticket price',
          {
            content: formattedTicketPrices,
          },
          !TaskVersionUtil.isSame(BookingUtil.format.ticketPrices(tickets), BookingUtil.format.ticketPrices(patchedTickets)),
        )}
        {renderBlock(
          'Ticket type',
          {
            content: formattedTicketTypes,
          },
          !TaskVersionUtil.isSame(BookingUtil.format.ticketTypes(tickets), BookingUtil.format.ticketTypes(patchedTickets)),
        )}
        {renderBlock(
          toField('Check-in date and time',
            TaskVersionUtil.hasAny(bookingJourneyDiff, [FIELDS.checkInAt, FIELDS.time])),
          [
            toField(formattedCheckInDate,
              TaskVersionUtil.hasAny(bookingJourneyDiff, [FIELDS.checkInAt])),
            toField(checkInTimeDifference,
              TaskVersionUtil.hasAny(bookingJourneyDiff, [FIELDS.checkInAt, FIELDS.time])),
          ],
        )}
      </div>
    </div>
  );
};

export default Booking;
