// Config(s)
import { COMPONENT_IDS, SYSTEM_CHECKS, SYSTEM_CHECKS_TO_COMPONENT_IDS } from '../constants';
import { DEFAULT_COMPONENT_ERROR, NOT_REQUIRED_ERROR } from './constants';
import { STRINGS } from '../../../../../../../../../utils/constants';

const validateTrace = (system, componentId, entityData, entityIndex) => {
  const errors = [];
  if (!entityData?.otherChecks?.[system]?.trace) {
    errors.push({
      componentId: `${componentId}/${entityIndex}`,
      message: DEFAULT_COMPONENT_ERROR,
    });
  }

  if (entityData?.otherChecks?.[system]?.trace === 'TRACE' && !entityData?.otherChecks?.[system]?.comment) {
    errors.push({
      componentId: `${componentId}Comment/${entityIndex}`,
      message: DEFAULT_COMPONENT_ERROR,
    });
  }

  return errors;
};

const validateOtherSystemsChecks = (entityData, entityIndex) => {
  const errors = [];

  entityData?.otherChecks?.[SYSTEM_CHECKS.OTHER]?.forEach((systemCheck, index) => {
    const individualSystemName = COMPONENT_IDS.OTHER.slice(0, -1);

    if (!systemCheck?.systemChecked && !systemCheck?.trace) {
      errors.push({
        componentId: `${individualSystemName}Checked/${index}-${entityIndex}Group`,
        message: DEFAULT_COMPONENT_ERROR,
      });
      return;
    }

    if (!systemCheck?.systemChecked) {
      errors.push({
        componentId: `${individualSystemName}Checked/${index}-${entityIndex}`,
        message: DEFAULT_COMPONENT_ERROR,
      });
    }

    if (!systemCheck?.trace) {
      errors.push({
        componentId: `${individualSystemName}Trace/${index}-${entityIndex}`,
        message: DEFAULT_COMPONENT_ERROR,
      });
    }

    if (systemCheck?.trace === 'TRACE' && !systemCheck?.comment) {
      errors.push({
        componentId: `${individualSystemName}TraceComment/${index}-${entityIndex}`,
        message: DEFAULT_COMPONENT_ERROR,
      });
    }
  });

  return errors;
};

const validateNotRequiredChecks = (entityData, entityIndex) => {
  if (!entityData?.otherChecks?.[COMPONENT_IDS.NOT_REQUIRED]) {
    return [{
      componentId: `${COMPONENT_IDS.NOT_REQUIRED}/${entityIndex}`,
      message: NOT_REQUIRED_ERROR,
    }];
  }

  return [];
};

const validateTraceChecks = (system, entityData, entityIndex) => {
  if (system === SYSTEM_CHECKS.OTHER) {
    return [];
  }

  return SYSTEM_CHECKS_TO_COMPONENT_IDS[system]
    ? validateTrace(system, SYSTEM_CHECKS_TO_COMPONENT_IDS[system], entityData, entityIndex)
    : [];
};

const validateOtherChecks = (entityData, entityIndex) => {
  const errors = [];
  entityData?.otherChecks?.checks.forEach((check) => {
    if (check === STRINGS.FORM.NOT_REQUIRED) {
      errors.push(...validateNotRequiredChecks(entityData, entityIndex));
    }

    if (check === STRINGS.FORM.OTHER) {
      errors.push(...validateOtherSystemsChecks(entityData, entityIndex));
    }

    errors.push(...validateTraceChecks(check, entityData, entityIndex));
  });
  return errors;
};

export default validateOtherChecks;
