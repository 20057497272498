import PropTypes from 'prop-types';
import React from 'react';

// Config
import { CONTENT_TYPE } from '../../../../../../../../utils/constants';

// Component(s)
import Content from './Content';

const Contents = ({ id, contents }) => {
  if (!contents || !contents?.length) {
    return null;
  }

  return (
    <div id={id} className="govuk-grid-row">
      {contents.map((content) => <Content key={content.id} content={content} />)}
    </div>
  );
};

Contents.propTypes = {
  id: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: (props, propName, componentName) => {
          if (props[propName] !== CONTENT_TYPE.TEXT) {
            return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
          }
        },
        id: PropTypes.string.isRequired,
        user: PropTypes.shape({
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
        }),
        updatedAt: PropTypes.string.isRequired,
        editable: PropTypes.bool.isRequired,
        content: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        user: PropTypes.shape({
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
        }),
        updatedAt: PropTypes.string.isRequired,
        editable: PropTypes.bool.isRequired,
        type: (props, propName, componentName) => {
          if (props[propName] !== CONTENT_TYPE.PHOTO) {
            return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
          }
        },
        url: PropTypes.string,
        source: PropTypes.string,
        approximateDateTaken: PropTypes.string,
      }),
    ]),
  ),
};

Contents.defaultProps = {
  contents: [],
};
export default Contents;
