import React from 'react';
import PropTypes from 'prop-types';

const RulesHit = ({ rules }) => {
  return (
    <div>
      <p className="govuk-!-font-weight-bold">Rules/Selectors hit:</p>
      {rules?.map((rule) => (
        <p key={rule}>{rule}</p>
      ))}
    </div>
  );
};

RulesHit.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.string),
};

RulesHit.defaultProps = {
  rules: [],
};

export default RulesHit;
