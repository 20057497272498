import { FormRenderer } from '@ukhomeoffice/cop-react-form-renderer';
import React, { useEffect, useState } from 'react';

// Config(s)
import config from '../../../../../../utils/config';

// Component(s)
import FormIO from '../../../../../../components/RenderForm/Forms/FormIO';

// Hook(s)
import { useKeycloak } from '../../../../../../context/Keycloak';

// Util(s)
import FormUtils from '../../../../../../utils/Form/ReactForm';
import getRenderer, { Renderers } from '../../../../../../utils/Form/ReactForm/getRenderer';
import { updateTokenIfRequired } from '../../../../../../utils/Axios/axiosInstance';

const CaseCheckYourAnswers = ({ submission }) => {
  const keycloak = useKeycloak();
  const [form, setForm] = useState(null);

  useEffect(() => {
    if (getRenderer(submission.form) === Renderers.REACT) {
      if (['task-list', 'form-with-task-list'].includes(submission.form.type)) {
        setForm({
          ...submission.form,
          type: 'task-list-cya',
          cya: {
            ...submission.form.cya,
            hide_page_titles: false,
            hide_actions: true,
          },
        });
      } else {
        setForm({
          ...submission.form,
          type: 'hub-and-spoke',
          cya: {
            ...submission.form.cya,
            hide_page_titles: true,
            hide_actions: true,
          },
          hub: {
            format: 'CYA',
          },
        });
      }
      return;
    }

    setForm(submission.form);
  }, [submission]);

  if (!form) {
    return null;
  }

  return (
    <div className="cya" id={`${submission.form.name}`}>
      {Renderers.FORM_IO === submission.renderer ? (
        <div className="govuk-grid-column-full-width form-io-border">
          <FormIO
            form={form}
            formattedPreFillData={{ data: submission.data }}
            options={{
              readOnly: true,
              noAlerts: true,
              buttonSettings: {
                showPrevious: true,
                showNext: true,
                showSubmit: false,
                showCancel: false,
              },
            }}
            onNextPage={() => {
              // This is intentional
            }}
            onPrevPage={() => {
              // This is intentional
            }}
          />
        </div>
      ) : (
        <FormRenderer
          {...form}
          data={{
            environmentContext: {
              referenceDataUrl: config.refdataApiUrl,
            },
            ...submission.data,
          }}
          hide_title
          noChangeAction
          newPageId="cya"
          hooks={{
            onRequest: async (req) => {
              const token = await updateTokenIfRequired(keycloak);
              return FormUtils.formHooks.onRequest(req, token);
            },
          }}
        />
      )}
    </div>
  );
};

export default CaseCheckYourAnswers;
