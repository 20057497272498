import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';

import { DATE_FORMATS } from '../../../../../../../utils/constants';

import { FlightUtil, JourneyUtil, MovementUtil, TaskVersionUtil } from '../../../../../../../utils';
import renderBlock, { toField } from '../common';

import FIELDS from '../Fields';

const Voyage = ({ version, versionDiff }) => {
  const journey = JourneyUtil.get(version);
  const journeyDiff = JourneyUtil.get(versionDiff);
  const flight = MovementUtil.movementFlight(version);
  const flightDiff = MovementUtil.movementFlight(versionDiff);

  return (
    <div className="task-details-container bottom-border-thin govuk-!-margin-bottom-2">
      <Heading className="govuk-!-margin-top-0" size="m">Voyage</Heading>
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          toField('Departure from', TaskVersionUtil.hasAny(journeyDiff?.departure, [FIELDS.location])),
          [
            toField(JourneyUtil.departureLoc(journey),
              TaskVersionUtil.hasAny(journeyDiff?.departure, [FIELDS.location])),
            toField(MovementUtil.formatLoc(JourneyUtil.departureLoc(journey)),
              TaskVersionUtil.hasAny(journeyDiff?.departure, [FIELDS.location])),
          ],
        )}
        {renderBlock(
          'Departure time',
          {
            content: JourneyUtil.formatDepartureTime(journey, DATE_FORMATS.LONG_DAY_DATE),
          },
          TaskVersionUtil.hasAny(journeyDiff?.departure, [FIELDS.time]),
        )}
        {renderBlock(
          toField('Arrival at',
            TaskVersionUtil.hasAny(journeyDiff?.arrival, [FIELDS.location])),
          [
            toField(JourneyUtil.arrivalLoc(journey),
              TaskVersionUtil.hasAny(journeyDiff?.arrival, [FIELDS.location])),
            toField(MovementUtil.formatLoc(JourneyUtil.arrivalLoc(journey)),
              TaskVersionUtil.hasAny(journeyDiff?.arrival, [FIELDS.location])),
          ],
        )}
        {renderBlock(
          'Arrival time',
          {
            content: JourneyUtil.formatArrivalTime(journey, DATE_FORMATS.LONG_DAY_DATE),
          },
          TaskVersionUtil.hasAny(journeyDiff?.arrival, [FIELDS.time]),
        )}
        {renderBlock(
          'Scheduled flight time',
          {
            content: JourneyUtil.formatFlightTime(journey),
          },
          TaskVersionUtil.hasAny(TaskVersionUtil.delete(journeyDiff, FIELDS.itinerary), [FIELDS.duration]),
        )}
        {renderBlock(
          'Flight code',
          {
            content: FlightUtil.flightNumber(flight),
          },
          TaskVersionUtil.hasAny(flightDiff, [FIELDS.number]),
        )}
        {renderBlock(
          'Operator',
          {
            content: MovementUtil.airlineName(flight),
          },
          TaskVersionUtil.hasAny(flightDiff, [FIELDS.operator]),
        )}
      </div>
    </div>
  );
};

export default Voyage;
