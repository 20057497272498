import React from 'react';

import { useKeycloak } from '../../../../../../context/Keycloak';

// Services
import AxiosRequests from '../../../../../../api/axiosRequests';

// Config
import config from '../../../../../../utils/config';

// Components
import RenderForm from '../../../../../../components/RenderForm/RenderForm';

// Hook(s)
import { useAxiosInstance } from '../../../../../../utils/Axios/axiosInstance';
import useSetTabTitle, { TAB_TITLES } from '../../../../../../utils/Hooks/useSetTabTitle';

// Form
import completeTask from '../../../../../../forms/completeTaskCerberus';
import { API_VERSIONS } from '../../../../../../utils/constants';

const CompleteTask = ({ taskId, isSubmitted, onCancel, setSubmitted, children }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);

  useSetTabTitle(TAB_TITLES.ASSESSMENT_COMPLETE);

  if (isSubmitted) {
    return children;
  }

  return (
    <RenderForm
      form={completeTask}
      preFillData={{ taskId }}
      onSubmit={async ({ data: {
        addANote,
        otherReasonForCompletion,
        reasonForCompletion,
      } }) => {
        await AxiosRequests.completeTask(apiClient, taskId, {
          reason: reasonForCompletion,
          otherReasonDetail: otherReasonForCompletion,
          note: addANote,
        },
        API_VERSIONS.V2);
        setSubmitted();
      }}
      onCancel={onCancel}
    />
  );
};

export default CompleteTask;
