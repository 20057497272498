import React from 'react';
import PropTypes from 'prop-types';

// Contexts
import { useKeycloak } from '../../../../../../../../context/Keycloak';

// Utils
import { PersonUtil } from '../../../../../../../../utils';

const TaskAssessedBy = ({ assessor }) => {
  const keycloak = useKeycloak();
  const userEmail = keycloak.tokenParsed.email;

  if (!assessor) {
    return null;
  }

  const taskAssessedBy = () => {
    return userEmail === assessor.email ? 'Me' : PersonUtil.formattedName(assessor.firstName)(assessor.lastName);
  };

  return (
    <span id="task-assessor" className="font--grey">
      {`Assessed by: ${taskAssessedBy()}`}
    </span>
  );
};

TaskAssessedBy.propTypes = {
  actioner: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
};

TaskAssessedBy.defaultProps = {
  actioner: null,
};

export default TaskAssessedBy;
