import { v4 as uuidv4 } from 'uuid';

import EntityUtil from '../../../../../../utils/Entity/entityUtil';

const addAdditionalContentToTask = (task, additionalContent, user, dateTimeStamp, addActivity, targetSheetAddition = false) => {
  const addTaskActivity = (activities) => {
    const activity = {
      id: uuidv4(),
      content: 'additional details added',
      user,
      timestamp: dateTimeStamp,
    };

    activities?.unshift(activity);
  };

  // #19801 The reason this is here is because
  //   tasks have additionalContent array (singular)
  //   targets has additionalContents array (plural)
  // We added the targetSheetAddition prop so we can differentiate between
  // these if we need to, like when the preview target sheet needs the
  // task data but with target structure;
  const type = targetSheetAddition ? 'additionalContents' : 'additionalContent';
  additionalContent?.forEach((addition) => {
    if (addition.type === 'BOOKING') {
      task.movement.booking[type].unshift({
        id: addition.content.id,
        type: addition.content.type,
        content: addition.content.content,
        user,
        timestamp: dateTimeStamp,
        editable: true,
      });
    } else if (addition.type === 'BAGGAGE') {
      task.movement.baggage[type].unshift({
        id: addition.content.id,
        type: addition.content.type,
        content: addition.content.content,
        user,
        timestamp: dateTimeStamp,
        editable: true,
      });
    } else if (addition.type === 'VOYAGE') {
      task.movement.journey[type].unshift({
        id: addition.content.id,
        type: addition.content.type,
        content: addition.content.content,
        user,
        timestamp: dateTimeStamp,
        editable: true,
      });
    } else if (addition.type === 'PASSENGER') {
      const personsToUpdate = [task.movement.person, ...task.movement.otherPersons];
      if (addition.content.type === 'PHOTO') {
        personsToUpdate.forEach((person) => {
          if (EntityUtil.isMatchingEntity(person.entityId, addition.entityId)) {
            if (!person[type] && targetSheetAddition) {
              person[type] = person.additionalContent;
            }
            person[type].unshift({
              id: addition.content.id,
              type: addition.content.type,
              url: addition.content.url,
              filename: addition.content.filename,
              source: addition.content.source,
              approximateDateTaken: addition.content?.approximateDateTaken,
              user,
              timestamp: dateTimeStamp,
              editable: true,
            });

            if (targetSheetAddition) {
              person.photograph = {
                approxPhotoTaken: addition.content?.approximateDateToken || null,
                uri: addition.content.url,
              };
            }
          }
        });
      } else {
        personsToUpdate.forEach((person) => {
          if (EntityUtil.isMatchingEntity(person.entityId, addition.entityId)) {
            person[type].unshift({
              id: addition.content.id,
              type: addition.content.type,
              content: addition.content.content,
              user,
              timestamp: dateTimeStamp,
              editable: true,
            });
          }
        });
      }
      task.movement.person = personsToUpdate[0];
      task.movement.otherPersons = personsToUpdate.slice(1);
    } else if (addition.type === 'DOCUMENT') {
      const personsToUpdate = [task.movement.person, ...task.movement.otherPersons];

      personsToUpdate.forEach((person) => {
        if (person.documents) {
          person.documents.forEach((document) => {
            if (EntityUtil.isMatchingEntity(document.entityId, addition.entityId)) {
              document[type].unshift({
                id: addition.content.id,
                type: addition.content.type,
                content: addition.content.content,
                user,
                timestamp: dateTimeStamp,
                editable: true,
              });
            }
          });
        }
      });
      task.movement.person = personsToUpdate[0];
      task.movement.otherPersons = personsToUpdate.slice(1);
    }
  });

  if (addActivity) {
    addTaskActivity(task?.activities);
  }

  return task;
};

const addAdditionalContent = (task, submissionPayload, user, dateTime, addTaskActivity, targetSheetAddition) => {
  const updatedTask = addAdditionalContentToTask(task, submissionPayload, user, dateTime, addTaskActivity, targetSheetAddition);
  return updatedTask;
};

export default addAdditionalContent;
