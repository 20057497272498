import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { forwardRef, memo, useEffect, useState } from 'react';

// Config
import { TASK_STATUS } from '../../../../../../../../utils/constants';

// Context(s)
import { useScrim } from '../../../../../../../../context/ScrimContext';
import { useTask } from '../../../../../../../../context/TaskContext';

// Component(s)
import AddTaskDetailButton from '../buttons/AddTaskDetailButton';
import AddTaskNoteButton from '../buttons/AddTaskNoteButton';
import CancelButton from '../buttons/CancelButton';
import CloseTaskButton from '../buttons/CloseTaskButton';
import CompleteAssessmentButton from '../buttons/CompleteAssessmentButton';
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import ContinueButton from '../buttons/ContinueButton';
import CreateAlertButton from '../buttons/CreateAlertButton';
import CreateTargetButton from '../buttons/CreateTargetButton';
import EditAlertButton from '../buttons/EditAlertButton';
import EditTargetButton from '../buttons/EditTargetButton';
import RelistTaskButton from '../buttons/RelistTaskButton';
import SaveAndCloseButton from '../buttons/SaveAndCloseButton';
import TaskActionToolbarMenu from './TaskActionToolbarMenu';
import UpdateButton from '../buttons/UpdateButton';
import UpdateTargetButton from '../buttons/UpdateTargetButton';
import UpdateTaskButton from '../buttons/UpdateTaskButton';

// Animations
import toolbarAnimations from './animations/toolbarAnimations';

// Util
import { isGaSubMode } from '../../../../../../../../utils/Task/taskUtil';

// Styling
import './TaskActionsToolbar.scss';

const TOOLBAR_DISPLAY_STATE = {
  HIDDEN: 'HIDDEN',
  VISIBLE: 'VISIBLE',
};

const TaskActions = memo(({ isAddNoteHiddenInViewport, isAddDetailHiddenInViewport, actions }) => {
  const { canShowAddNoteButton,
    canShowAddDetailButton,
    onAddTaskNote,
    onAddTaskDetail,
    onAddEditTaskDetail,
    onSaveAndClose,
    onCloseTask,
    onContinueOrCreateTarget,
    onUpdateTask,
    onCompleteAssessment,
    onEditTarget,
    onCancelling } = actions;
  const { task, isAddDetail, isEditTarget, taskState: { isClaimedByUser, isTargetIssued, isDismissedByTargeter }, isUpdateTask, subMode } = useTask();

  return (
    <div id="task-actions-controls" className="task-actions-controls">
      <ComponentWrapper show={(isAddNoteHiddenInViewport || isAddDetailHiddenInViewport) && (isClaimedByUser || isTargetIssued || isUpdateTask)}>
        <div id="add-note-and-detail-buttons-container" className="add-note-and-detail-buttons-container">
          <ComponentWrapper show={isAddNoteHiddenInViewport && (isClaimedByUser || isTargetIssued || isUpdateTask)}>
            <div id="add-note-buttons-container">
              <ComponentWrapper show={canShowAddNoteButton}>
                <AddTaskNoteButton
                  isNineteenTwentyOrMorePixels
                  onClick={onAddTaskNote}
                />
              </ComponentWrapper>
            </div>
          </ComponentWrapper>
          <ComponentWrapper show={isAddDetailHiddenInViewport && (isClaimedByUser || isTargetIssued || isUpdateTask)}>
            <div id="add-detail-buttons-container">
              <ComponentWrapper show={canShowAddDetailButton}>
                <AddTaskDetailButton
                  label={!isGaSubMode(subMode) ? 'Add task detail' : 'Add/edit task detail'}
                  isNineteenTwentyOrMorePixels
                  onClick={!isGaSubMode(subMode) ? onAddTaskDetail : onAddEditTaskDetail}
                />
              </ComponentWrapper>
            </div>
          </ComponentWrapper>
        </div>
      </ComponentWrapper>
      <div id="other-task-action-buttons-container">
        <ComponentWrapper show={isClaimedByUser && !isTargetIssued}>
          <SaveAndCloseButton onClick={onSaveAndClose} />
        </ComponentWrapper>
        <ComponentWrapper show={((!isClaimedByUser || isTargetIssued || isEditTarget) && !isAddDetail)}>
          <CloseTaskButton onClick={onCloseTask} />
        </ComponentWrapper>
        <ComponentWrapper show={isAddDetail && isTargetIssued}>
          <ContinueButton
            onClick={onContinueOrCreateTarget}
          />
        </ComponentWrapper>
        <ComponentWrapper show={isClaimedByUser && !isTargetIssued && !isGaSubMode(subMode)}>
          <CreateTargetButton
            onClick={onContinueOrCreateTarget}
          />
        </ComponentWrapper>
        <ComponentWrapper show={isClaimedByUser && !isTargetIssued && isGaSubMode(subMode)}>
          <CreateAlertButton
            onClick={onContinueOrCreateTarget}
          />
        </ComponentWrapper>
        <ComponentWrapper show={false}>
          <UpdateButton onClick={null} />
        </ComponentWrapper>
        <ComponentWrapper show={(isTargetIssued && !isAddDetail && task.status !== TASK_STATUS.COMPLETE)}>
          <UpdateTaskButton onClick={onUpdateTask} />
        </ComponentWrapper>
        <ComponentWrapper show={false}>
          {/* TODO: Find out what this button does. */}
          <UpdateTargetButton onClick={() => {}} />
        </ComponentWrapper>
        <ComponentWrapper show={isDismissedByTargeter}>
          {/* TODO: Find out what this button does. */}
          <RelistTaskButton onClick={() => {}} />
        </ComponentWrapper>
        <ComponentWrapper show={isClaimedByUser && !isTargetIssued}>
          <CompleteAssessmentButton
            onClick={onCompleteAssessment}
          />
        </ComponentWrapper>
        <ComponentWrapper show={!isGaSubMode(subMode) && (isTargetIssued && !isAddDetail && task.status !== TASK_STATUS.COMPLETE)}>
          <EditTargetButton
            onClick={onEditTarget}
          />
        </ComponentWrapper>
        <ComponentWrapper show={isGaSubMode(subMode) && isTargetIssued && !isUpdateTask}>
          <EditAlertButton
            onClick={onEditTarget}
          />
        </ComponentWrapper>
        <ComponentWrapper show={isAddDetail && (task.status === TASK_STATUS.ISSUED || task.status === TASK_STATUS.RECEIVED)}>
          <CancelButton onClick={onCancelling} />
        </ComponentWrapper>
      </div>
    </div>
  );
});

const TaskActionsToolbar = forwardRef(({ isAddNoteHiddenInViewport, isAddDetailHiddenInViewport, actions, isScrollingUp }, ref) => {
  const { HIDDEN, VISIBLE } = TOOLBAR_DISPLAY_STATE;
  const [menuOpen, setMenuOpen] = useState(false);
  const [visibleState, setVisibleState] = useState(HIDDEN);
  const { isScrimOpen } = useScrim();

  useEffect(() => {
    if (isScrollingUp && isScrimOpen) {
      setVisibleState(HIDDEN);
      setMenuOpen(false);
      return;
    }
    if (isScrollingUp) {
      setVisibleState(VISIBLE);
      return;
    }

    setVisibleState(HIDDEN);
    setMenuOpen(false);
  }, [isScrollingUp, isScrimOpen]);

  return (
    <AnimatePresence>
      {visibleState === VISIBLE && (
        <>
          <motion.div
            className="actions-toolbar-container__wrapper"
            key="actions-toolbar-container__wrapper"
            {...toolbarAnimations}
          />
          <motion.div
            key="actions-toolbar-container"
            className="actions-toolbar-container"
            ref={ref}
            {...toolbarAnimations}
          >
            <div className="actions-toolbar-content__container">
              <div id="task-actions-title">
                <p className="govuk-body-l govuk-!-margin-bottom-0 govuk-!-margin-top-0">Task details</p>
              </div>
              <TaskActions isAddNoteHiddenInViewport={isAddNoteHiddenInViewport} isAddDetailHiddenInViewport={isAddDetailHiddenInViewport} actions={actions} />
              <TaskActionToolbarMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            </div>
            <ComponentWrapper show={menuOpen}>
              <div id="actions-toolbar-menu-container" className="actions-toolbar-menu-container">
                <div id="actions-toolbar-menu" className="actions-toolbar-menu">
                  <TaskActions isAddNoteHiddenInViewport={isAddNoteHiddenInViewport} isAddDetailHiddenInViewport={isAddDetailHiddenInViewport} actions={actions} />
                </div>
              </div>
            </ComponentWrapper>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
});

TaskActionsToolbar.propTypes = {
  isAddNoteHiddenInViewport: PropTypes.bool.isRequired,
  isAddDetailHiddenInViewport: PropTypes.bool.isRequired,
  actions: PropTypes.shape({}).isRequired,
};

TaskActions.propTypes = {
  actions: PropTypes.shape({}).isRequired,
  isAddNoteHiddenInViewport: PropTypes.bool.isRequired,
  isAddDetailHiddenInViewport: PropTypes.bool.isRequired,
};

export default TaskActionsToolbar;
