import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { MOVEMENT_MODES } from '../../../../utils/constants';

// Component(s)
import AirpaxTargetingInformation from './airpax/AirpaxTargetingInformation';
import RoRoTargetingInformation from './roro/RoRoTargetingInformation';

// Util(s)
import TargetMovementUtil from '../../../../utils/Movement/Target/targetMovementUtil';
import TargetPersonUtil from '../../../../utils/Person/Target/targetPersonUtil';

const TargetInformation = ({ informationSheet }) => {
  const getComponent = () => {
    const persons = TargetPersonUtil.getAll(informationSheet);
    const mode = TargetMovementUtil.mode(informationSheet);

    switch (mode) {
      case MOVEMENT_MODES.AIR_PASSENGER: {
        return <AirpaxTargetingInformation persons={persons} />;
      }
      case MOVEMENT_MODES.TOURIST:
      case MOVEMENT_MODES.UNACCOMPANIED_FREIGHT:
      case MOVEMENT_MODES.ACCOMPANIED_FREIGHT: {
        return <RoRoTargetingInformation informationSheet={informationSheet} />;
      }
      default: {
        return null;
      }
    }
  };

  return getComponent();
};

TargetInformation.propTypes = {
  informationSheet: PropTypes.shape({
    movement: PropTypes.shape({
      mode: PropTypes.string,
    }),
  }).isRequired,
  persons: PropTypes.arrayOf(PropTypes.shape({})),
};

TargetInformation.defaultProps = {
  persons: [],
};

export default memo(TargetInformation);
