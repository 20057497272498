/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { lazy, Suspense } from 'react';
import { Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom';

// Config(s)
import config from '../../utils/config';
import { PATHS } from '../../utils/constants';

// Context(s)
import { TaskProvider } from '../../context/TaskContext';

// Components/ Pages
import BulkDismissTask from './TaskList/roro/BulkDismissTask';
import Layout from '../../components/Layout/Layout';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Redirect from '../../components/Navigate/Redirect';
import PnrAccessRequest from './Access/PnrAccessRequest';
import PreviousTaskDetailsPage from './TaskDetails/PreviousTaskDetails/PreviousTaskDetailsPage';
import PreviousTaskListPage from './PreviousTaskList/PreviousTaskListPage';
import TaskDetailsPage from './TaskDetails/CurrentTaskDetails/roro/TaskDetailsPage';
import TaskListPage from './TaskList/TaskListPage';
import TaskMessageHistory from './TaskDetails/CurrentTaskDetails/air/components/shared/history/TaskMessageHistory';
import UpliftedTaskDetailsPage from './TaskDetails/CurrentTaskDetails/air/TaskDetailsPage';

// HOC(s)
import withWebSocketSession from '../../components/HOC/withWebSocketSession';

// Lazy Component(s)
const CreateGeneralAviationTask = lazy(() => import('./GATask/CreateGeneralAviationTask'));

const TaskRouter = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <PnrAccessRequest>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {/* Redirects */}
          {[
            {
              path: PATHS.RORO_V1,
              redirect: PATHS.RORO,
              enabled: true,
            },
            {
              path: `${PATHS.RORO_V1}/:taskId`,
              redirect: `${PATHS.RORO}/:taskId`,
              enabled: true,
            },
            // TODO: Remove once we implement uplifted roro
            {
              path: PATHS.UPLIFT.RORO,
              redirect: PATHS.RORO,
              enabled: true,
            },
            {
              path: `${PATHS.UPLIFT.RORO}/:taskId`,
              redirect: `${PATHS.RORO}/:taskId`,
              enabled: true,
            },
            {
              path: `${PATHS.AIRPAX}${PATHS.BULK_DISMISSALS}`,
              redirect: `${PATHS.UPLIFT.AIRPAX}${PATHS.BULK_DISMISSALS}`,
              enabled: !config.legacyAirpaxTaskListEnabled, // If legacy airpax task list is enabled, turn off the redirect
            },
            {
              path: `${PATHS.AIRPAX}/:taskId`,
              redirect: `${PATHS.UPLIFT.AIRPAX}/:taskId`,
              enabled: !config.legacyAirpaxTaskListEnabled, // If legacy airpax task list is enabled, turn off the redirect
            },
            {
              path: `${PATHS.DEPRECATED.AIRPAX}/:taskId`,
              redirect: `${PATHS.AIRPAX}/:taskId`,
              enabled: true,
            },
            {
              path: `${PATHS.DEPRECATED.RORO}/:taskId`,
              redirect: `${PATHS.RORO}/:taskId`,
              enabled: true,
            },
            {
              path: PATHS.AIRPAX,
              redirect: PATHS.UPLIFT.AIRPAX,
              enabled: !config.legacyAirpaxTaskListEnabled, // If legacy airpax task list is enabled, turn off the redirect
            },
            {
              path: PATHS.DEPRECATED.AIRPAX,
              redirect: PATHS.AIRPAX,
              enabled: true,
            },
            {
              path: PATHS.DEPRECATED.RORO,
              redirect: PATHS.RORO,
              enabled: true,
            },
          ].filter((item) => !!item.enabled)
            .map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={<Redirect to={item.redirect} />}
                replace
              />
            ))}
          {[
            {
              path: PATHS.RORO,
              enabled: true,
            },
            {
              path: PATHS.UPLIFT.AIRPAX,
              enabled: true,
            },
            {
              path: PATHS.GENERAL_AVIATION,
              enabled: config.generalAviationUIEnabled,
            },
          ].filter((item) => !!item.enabled)
            .map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={<Layout><TaskListPage key={item.path} /></Layout>}
              />
            ))}
          {[
            {
              path: `${PATHS.RORO}/:taskId`,
              taskListPath: PATHS.RORO,
              enabled: true,
            },
            {
              path: `${PATHS.AIRPAX}/:taskId`,
              taskListPath: PATHS.AIRPAX,
              enabled: config.legacyAirpaxTaskListEnabled,
            },
          ].filter((item) => !!item.enabled)
            .map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={(
                  <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" to={item.taskListPath}>Back to task list</Link>}>
                    <TaskDetailsPage />
                  </Layout>
                )}
              />
            ))}
          <Route
            path={PATHS.PREVIOUS_TASKS.TASKS_LIST}
            element={(
              <Layout>
                <PreviousTaskListPage />
              </Layout>
            )}
          />
          {[
            {
              path: `${PATHS.PREVIOUS_TASKS.AIRPAX}/:taskId`,
              enabled: true,
            },
            {
              path: `${PATHS.PREVIOUS_TASKS.RORO}/:taskId`,
              enabled: true,
            },
          ].filter((item) => !!item.enabled)
            .map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={(
                  <TaskProvider>
                    <PreviousTaskDetailsPage />
                  </TaskProvider>
                )}
              />
            ))}
          {[
            {
              path: `${PATHS.GENERAL_AVIATION}${PATHS.BULK_DISMISSALS}`,
              enabled: config.generalAviationUIEnabled,
            },
            {
              path: `${PATHS.UPLIFT.AIRPAX}${PATHS.BULK_DISMISSALS}`,
              enabled: true,
            },
            {
              path: `${PATHS.AIRPAX}${PATHS.BULK_DISMISSALS}`,
              enabled: config.legacyAirpaxTaskListEnabled,
            },
            {
              path: `${PATHS.RORO}${PATHS.BULK_DISMISSALS}`,
              enabled: true,
            },
          ].filter((item) => !!item.enabled)
            .map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={<Layout><BulkDismissTask /></Layout>}
                replace
              />
            ),
            )}
          <Route path="/v2" element={<Navigate to={PATHS.UPLIFT.AIRPAX} replace />} />
          {[
            {
              path: `${PATHS.UPLIFT.AIRPAX}/:taskId`,
              taskListPath: PATHS.UPLIFT.AIRPAX,
              enabled: true,
            },
            {
              path: `${PATHS.GENERAL_AVIATION}/:taskId`,
              taskListPath: PATHS.GENERAL_AVIATION,
              enabled: config.generalAviationUIEnabled,
            },
          ].filter((path) => !!path.enabled)
            .map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={(
                  <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" to={item.taskListPath}>Back to task list</Link>}>
                    <TaskProvider>
                      <UpliftedTaskDetailsPage />
                    </TaskProvider>
                  </Layout>
              )}
              />
            ))}
          <Route
            path={`${PATHS.GENERAL_AVIATION}${PATHS.CREATE_GENERAL_AVIATION_TASK}`}
            element={(
              <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={goBack}>Back to task list</Link>}>
                <CreateGeneralAviationTask />
              </Layout>
            )}
          />
          <Route
            path={`${PATHS.UPLIFT.AIRPAX}/:taskId${PATHS.UPLIFT.TASK_MESSAGE_HISTORY}`}
            element={(
              <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={goBack}>Back to task details</Link>}>
                <TaskProvider>
                  <TaskMessageHistory />
                </TaskProvider>
              </Layout>
            )}
          />
        </Routes>
      </Suspense>
    </PnrAccessRequest>
  );
};

export default withWebSocketSession(TaskRouter);
