import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from '@ukhomeoffice/cop-react-components';

// Components
import SourceSection from './SourceSection';
import ComponentWrapper from '../../../../components/ComponentWrapper/ComponentWrapper';

// Constants
import { MOVEMENT_MODE_NAMES, MOVEMENT_MODES } from '../../../../utils/constants';

const TitleSection = ({ movementMode, createdAt, source, isLiveTask }) => {
  return (
    <section>
      <div className="task-list-voyage-summary title-container">
        <div>
          <span className="govuk-heading-m govuk-!-display-inline previous-task-created-at">{createdAt}</span>
          {/* TODO: get direction information */}
          <span style={{ paddingLeft: '30px' }}>DIRECTION</span>
        </div>
        <div className="previous-task-movement-mode">
          {
            MOVEMENT_MODES.AIR_PASSENGER === movementMode
              ? <Tag classBlock="tag" classModifiers="black" className="grid-center">{MOVEMENT_MODE_NAMES[MOVEMENT_MODES.AIR_PASSENGER]}</Tag>
              : <Tag classBlock="tag" classModifiers="grey" className="grid-center">{MOVEMENT_MODE_NAMES[MOVEMENT_MODES[movementMode.substring(5)]]}</Tag>
          }
          <ComponentWrapper show={isLiveTask}>
            <Tag className="tag tag--green">Live Task</Tag>
          </ComponentWrapper>
        </div>
        <SourceSection movementMode={movementMode} source={source} />
      </div>
    </section>
  );
};

TitleSection.propTypes = {
  isLiveTask: PropTypes.bool,
  createdAt: PropTypes.string.isRequired,
  movementMode: PropTypes.string.isRequired,
  source: PropTypes.shape({}).isRequired,
};

TitleSection.defaultProps = {
  isLiveTask: false,
};

export default TitleSection;
