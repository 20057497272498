import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import classNames from 'classnames';

import renderBlock, { toPreviousTaskLinkParams, toPreviousTaskLink, toPreviousMovementLink } from '../../../common';
import { CommonUtil, DocumentUtil, EnrichmentUtil, PersonUtil, TaskVersionUtil } from '../../../../../../../../../utils';

import FIELDS from '../../../Fields';

import EnrichmentCount from '../../shared/EnrichmentCount';

const PrimaryTraveller = ({ version, versionDiff, classModifiers }) => {
  const person = PersonUtil.get(version);
  const personDiff = PersonUtil.get(versionDiff);
  const document = DocumentUtil.get(person);
  const documentDiff = DocumentUtil.get(personDiff);

  return (
    <div className={classNames('task-details-container', 'govuk-!-margin-bottom-2', classModifiers)}>
      <Heading className="govuk-!-margin-top-0" size="m">Primary traveller</Heading>
      <EnrichmentCount
        movementCount={EnrichmentUtil.movementCount(CommonUtil.movementStats(person))}
        examinationCount={EnrichmentUtil.examinationCount(CommonUtil.movementStats(person))}
        seizureCount={EnrichmentUtil.seizureCount(CommonUtil.movementStats(person))}
      />
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Name',
          {
            content: PersonUtil.fullname(person),
            url: CommonUtil.entitySearchUrl(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.name]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, person)),
          toPreviousMovementLink(person),
        )}
        {renderBlock(
          'Date of birth',
          {
            content: PersonUtil.dob(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.dateOfBirth]),
        )}
        {renderBlock(
          'Gender',
          {
            content: PersonUtil.gender(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.gender]),
        )}
        {renderBlock(
          'Nationality',
          {
            content: PersonUtil.nationality(person),
          },
          TaskVersionUtil.hasAny(personDiff, [FIELDS.nationality]),
        )}
        {renderBlock(
          'Travel document type',
          {
            content: DocumentUtil.docType(document),
          },
          TaskVersionUtil.hasAny(documentDiff, [FIELDS.type]),
        )}
        {renderBlock(
          'Travel document number',
          {
            content: DocumentUtil.docNumber(document),
            url: CommonUtil.entitySearchUrl(document),
          },
          TaskVersionUtil.hasAny(documentDiff, [FIELDS.number]),
        )}
        {renderBlock(
          'Travel document expiry',
          {
            content: DocumentUtil.docExpiry(document, true),
          },
          TaskVersionUtil.hasAny(documentDiff, [FIELDS.expiry]),
        )}
      </div>
    </div>
  );
};

export default PrimaryTraveller;
