// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import ComponentWrapper from '../../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import Details from '../../generics/Details';
import HeaderModule from '../../generics/HeaderModule';
import LabelValuePair from '../../generics/LabelValuePair';
import ValueField from '../../generics/ValueField';

// Util(s)
import VehicleUtil from '../../../../../../../../../utils/Vehicle/vehicleUtil';

const Aircraft = ({ vehicle }) => {
  const registration = VehicleUtil.registration(vehicle);
  const type = VehicleUtil.type(vehicle);

  return (
    <div id="aircraft-module" className="govuk-grid-column-full">
      <HeaderModule
        id="aircraft-details"
        items={[
          {
            content: (
              <div key="1" className="grid-item">
                <Heading id="aircraft-header" size="m">Aircraft</Heading>
              </div>
            ),
          },
          {
            content: (
              <ComponentWrapper show={registration}>
                <div key="2" className="grid-item grid-item-span-3">
                  <ValueField id="aircraft-registration-header" value={registration} />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      <Details
        items={[
          {
            content: (
              <ComponentWrapper show={registration}>
                <div key="1" className="grid-item">
                  <LabelValuePair id="aircraft-registration" label="Aircraft registration" value={registration} />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper show={type}>
                <div key="2" className="grid-item">
                  <LabelValuePair id="aircraft-type" label="Aircraft type" value={type} />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
    </div>
  );
};

Aircraft.propTypes = {
  vehicle: PropTypes.shape({}).isRequired,
};

export default Aircraft;
