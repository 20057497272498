import PropTypes from 'prop-types';
import React from 'react';

// Context(s)
import { useKeycloak } from '../../../../../../../../context/Keycloak';

// Util(s)
import PersonUtil from '../../../../../../../../utils/Person/Uplift/personUtil';

const TaskClaimedBy = ({ claimedBy }) => {
  const keycloak = useKeycloak();
  const userEmail = keycloak.tokenParsed.email;

  if (!claimedBy) {
    return (
      <span id="task-assignee" className="font--grey">Task not claimed</span>
    );
  }

  const taskClaimedBy = () => {
    return userEmail === claimedBy?.email ? 'Me' : PersonUtil.formattedName(claimedBy?.firstName)(claimedBy?.lastName);
  };

  return (
    <span id="task-assignee" className="font--grey">
      {`Claimed by: ${taskClaimedBy()}`}
    </span>
  );
};

TaskClaimedBy.propTypes = {
  claimedBy: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
};

TaskClaimedBy.defaultProps = {
  claimedBy: null,
};

export default TaskClaimedBy;
