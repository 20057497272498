// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

// Config(s)
import { PATHS, TAB_ID, STRINGS, LOCAL_STORAGE_KEYS, FILTER_TAB_ID, VIEW } from '../../utils/constants';
import DEFAULTS from '../../routes/Task/TaskList/shared/constants';

// Context(s)
import { usePermission } from '../../context/PermissionContext';
import { useTabs } from '../../context/TabContext';
import { useTasks } from '../../context/TasksContext';
import { useView } from '../../context/ViewContext';

// Component
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import CreateTaskButton from '../../routes/Task/TaskList/air/components/shared/buttons/CreateTaskButton';
import LabelField from '../../routes/Task/TaskDetails/CurrentTaskDetails/air/components/shared/generics/LabelField';
import ShowHideFiltersButton from '../../routes/Task/TaskList/roro/components/ShowHideFilters';

// Hook
import useSetTabTitle from '../../utils/Hooks/useSetTabTitle';

// Styling
import './TaskManagementHeader.scss';

const TaskManagementHeader = ({ headerLabel, size = 'xl', links = [], ...props }) => {
  const { hasRole } = usePermission();
  const { setSelectedTab, setTabIndex, setSelectedFilterTab } = useTabs();
  const { setSelectedForDelete, setRules } = useTasks();
  const { view, setView } = useView();
  const navigate = useNavigate();

  useSetTabTitle(headerLabel);

  const newLinks = links;
  const idp = newLinks.find((l) => l.label === 'IDP');
  if (idp) {
    idp.label = 'Documents';
  }

  const onClick = (linkView) => {
    if (![STRINGS.TASK_LINK_HEADERS.ADMIN, STRINGS.TASK_LINK_HEADERS.AIRPAX].includes(linkView)) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.VIEW_SWAP, linkView);
    }

    const viewLink = newLinks.find((l) => l.view === linkView);

    setView(viewLink.view);
    setTabIndex(0);
    setSelectedTab(TAB_ID.NEW);

    setSelectedFilterTab(FILTER_TAB_ID.FILTERS);
    setSelectedForDelete([]);
    setRules(null);
    localStorage.removeItem(DEFAULTS[view].filters.key);

    navigate(viewLink.url);
  };

  const toSelectComponent = (link) => {
    if (!link?.show) {
      return null;
    }

    if (!hasRole(link?.rbac?.readRole)) {
      return null;
    }

    return (
      <option key={link.view} value={link.view} disabled={view === link.view}>
        {link.label}
      </option>
    );
  };

  const onChange = (e) => {
    const selectedIndex = e?.target?.selectedIndex;
    const data = e?.target?.[selectedIndex];
    return onClick(data.value);
  };

  const currentView = () => {
    return view === VIEW.IDP ? 'Documents' : view;
  };

  const optionsToShow = () => {
    const a = newLinks
      .map(toSelectComponent)
      .filter((item) => !!item);
    return a;
  };

  return (
    <div className="heading-container" {...props}>
      <Heading id="task-management-header" size={size}>
        {headerLabel}
      </Heading>
      <ShowHideFiltersButton />
      <ComponentWrapper show={view === VIEW.GENERAL_AVIATION}>
        <CreateTaskButton
          className="govuk-!-font-weight-bold float-right"
          onClick={() => navigate(`${DEFAULTS[view].redirectPath}${PATHS.CREATE_GENERAL_AVIATION_TASK}`)}
        />
      </ComponentWrapper>
      <ComponentWrapper show={newLinks.length > 1}>
        <div className="grid-item select-task-group">
          <LabelField
            aria-label="view mode"
            className="select-labels"
            id="select-task-label"
            value="View mode"
          />
          <select
            aria-labelledby="select-task-label"
            aria-label="dropdown list of modes"
            defaultValue={currentView()}
            id="select-task"
            name="select-task"
            onChange={onChange}
          >
            <option disabled>{currentView()}</option>
            {optionsToShow()}
          </select>
        </div>
      </ComponentWrapper>
    </div>
  );
};

export default memo(TaskManagementHeader);
