import { Heading, Tag } from '@ukhomeoffice/cop-react-components';
import React from 'react';

// Config(s)
import { TASK_STATUS, VIEW } from '../../../../../utils/constants';

// Context(s)
import { useView } from '../../../../../context/ViewContext';
import { useTasks } from '../../../../../context/TasksContext';

// Component(s)
import ClaimUnclaimTask from '../../../Common/ClaimUnclaimTask';
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';
import DismissUnDismissTask from '../../shared/components/DismissUnDismissTask';

// Util(s)
import { getTaskId } from '../../../../../utils/Task/taskUtil';
import { MovementUtil, RisksUtil } from '../../../../../utils';

// Styling
import './TitleSection.scss';

const TitleSection = ({ targetTask, currentUser, redirectPath }) => {
  const { view } = useView();
  const { taskIdsToRemove } = useTasks();
  const taskId = getTaskId(targetTask);
  const { highestThreatLevel, tagValue, formattedRisks } = RisksUtil.formatHighestThreat(targetTask);

  const shouldShowClaimUnclaimButton = () => {
    return ![TASK_STATUS.SELECTED_FOR_DISMISSAL, TASK_STATUS.ISSUED, TASK_STATUS.COMPLETE, TASK_STATUS.RECEIVED].includes(targetTask.status);
  };

  const shouldShowDismissUnDismissButton = () => {
    return [TASK_STATUS.NEW, TASK_STATUS.SELECTED_FOR_DISMISSAL].includes(targetTask.status);
  };

  const toClassNameIfTaskHasNotBeenIssued = () => {
    if (![TASK_STATUS.ISSUED, TASK_STATUS.RECEIVED, TASK_STATUS.COMPLETE].includes(targetTask.status)) {
      return 'govuk-grid-column-one-half';
    }
    return 'govuk-grid-column-full';
  };

  const shouldShowIfTaskBeenNotBeenIssued = () => {
    return [TASK_STATUS.NEW, TASK_STATUS.SELECTED_FOR_DISMISSAL, TASK_STATUS.IN_PROGRESS].includes(targetTask.status);
  };

  return (
    <section>
      <div className="govuk-grid-row">
        <div className={toClassNameIfTaskHasNotBeenIssued()}>
          <div className="task-title-container govuk-!-padding-top-2 govuk-!-padding-left-2">
            <Heading id={`task-heading-${taskId}`} size="s" useHodsTag>{taskId}</Heading>
          </div>
          <ComponentWrapper show={view === VIEW.RORO}>
            <div className="govuk-grid-column govuk-!-padding-left-2">
              <Heading id={`task-highest-risk-${taskId}`} size="s" useHodsTag>
                {highestThreatLevel}{' '}
                <Tag className="tag tag--risk-tier" text={tagValue} />
                <span className="govuk-body task-risk-statement">{formattedRisks}</span>
              </Heading>
            </div>
          </ComponentWrapper>
          <div className="govuk-grid-column govuk-!-padding-left-2">
            {MovementUtil.updatedStatus(targetTask)}
            {MovementUtil.relistStatus(targetTask)}
            {MovementUtil.withdrawnStatus(targetTask)}
            {targetTask.status === TASK_STATUS.COMPLETE
              && MovementUtil.outcomeStatusTag(MovementUtil.outcome(targetTask))}
          </div>
        </div>
        <ComponentWrapper show={shouldShowIfTaskBeenNotBeenIssued()}>
          <div className="govuk-grid-column-one-half govuk-!-padding-top-2 govuk-!-padding-right-3">
            <div id={`action-buttons-container-${taskId}`}>
              <ComponentWrapper show={!taskIdsToRemove.includes(targetTask.id)}>
                <ComponentWrapper show={shouldShowDismissUnDismissButton()}>
                  <DismissUnDismissTask
                    taskId={taskId}
                    taskStatus={targetTask.status}
                  />
                </ComponentWrapper>
                <ComponentWrapper show={shouldShowClaimUnclaimButton()}>
                  <ClaimUnclaimTask
                    currentUser={currentUser}
                    assignee={targetTask?.claimedBy?.email}
                    source={redirectPath}
                    buttonType="button"
                    taskId={targetTask.id}
                    taskStatus={targetTask.status}
                    isTaskDetails={false}
                  />
                </ComponentWrapper>
              </ComponentWrapper>
            </div>
          </div>
        </ComponentWrapper>
      </div>
    </section>
  );
};

export default TitleSection;
