import { Panel } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';

// Component(s)
import LinkButton from '../../../../../../../../components/Buttons/LinkButton';

// Hook
import useSetTabTitle, { setTabTitle } from '../../../../../../../../utils/Hooks/useSetTabTitle';

// Styling
import './OutcomeMessage.scss';

const OutcomeMessage = ({ title, caption, description, onBackToTask, onBackToTaskList, tabTitle, onCloseTabTitle, ...props }) => {
  useSetTabTitle(tabTitle);

  useEffect(() => {
    return () => setTabTitle(onCloseTabTitle);
  }, []);

  return (
    <div id="task-outcome-panel-container">
      <Panel id="task-outcome-panel" {...props} title={title}>
        {caption}
      </Panel>
      {description}
      <div id="actions" className="govuk-grid-row">
        <div className="govuk-grid-column-full">
          <LinkButton
            id="back-to-task-link"
            className="govuk-link"
            onClick={onBackToTask}
          >
            Back to the task
          </LinkButton>
        </div>
        <div className="govuk-grid-column-full">
          <LinkButton
            id="back-to-task-list"
            className="govuk-link"
            onClick={onBackToTaskList}
          >
            Back to task list
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

OutcomeMessage.propTypes = {
  caption: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.string.isRequired,
  onBackToTaskList: PropTypes.func.isRequired,
  onBackToTask: PropTypes.func.isRequired,
  tabTitle: PropTypes.string,
  onCloseTabTitle: PropTypes.string,
};

OutcomeMessage.defaultProps = {
  caption: null,
  description: null,
  tabTitle: null,
  onCloseTabTitle: null,
};

export default memo(OutcomeMessage);
