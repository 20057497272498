import React from 'react';
import { AccountUtil, BookingUtil, GoodsUtil, HaulierUtil, JourneyUtil, PersonUtil, VehicleUtil, CommonUtil } from '../../../../../utils';
import TrailerUtil from '../../../../../utils/Trailer/trailerUtil';
import DatetimeUtil from '../../../../../utils/Datetime/datetimeUtil';
import { MOVEMENT_MODES } from '../../../../../utils/constants';

import AccompaniedMovementSection from '../components/AccompaniedMovementSection';
import UnaccompaniedMovementSection from '../components/UnaccompaniedMovementSection';
import TouristMovementSection from '../components/TouristMovementSection';

const getMovementComponent = (mode, targetTask) => {
  const person = PersonUtil.get(targetTask);
  const booking = BookingUtil.get(targetTask);
  const journey = JourneyUtil.get(targetTask);
  const otherPersons = PersonUtil.getOthers(targetTask);
  const vehicle = VehicleUtil.get(targetTask);
  const trailer = TrailerUtil.get(targetTask);
  const haulier = HaulierUtil.get(targetTask);
  const account = AccountUtil.get(targetTask);
  const goods = GoodsUtil.get(targetTask);
  const bookingDepartureTime = DatetimeUtil.toList(BookingUtil.bookedAt(booking), JourneyUtil.departureTime(journey));

  switch (mode) {
    case MOVEMENT_MODES.ACCOMPANIED_FREIGHT: {
      return (
        <AccompaniedMovementSection
          person={person}
          vehicle={vehicle}
          trailer={trailer}
          haulier={haulier}
          account={account}
          booking={booking}
          goods={goods}
          bookingDepartureTime={bookingDepartureTime}
          othersCount={otherPersons.length}
          othersPreviousTaskCount={CommonUtil.getTotalNumberOfPreviousTasks(otherPersons)}
        />
      );
    }
    case MOVEMENT_MODES.UNACCOMPANIED_FREIGHT: {
      return (
        <UnaccompaniedMovementSection
          trailer={trailer}
          haulier={haulier}
          account={account}
          booking={booking}
          goods={goods}
          bookingDepartureTime={bookingDepartureTime}
        />
      );
    }
    case MOVEMENT_MODES.TOURIST: {
      return (
        <TouristMovementSection targetTask={targetTask} />
      );
    }
    default: {
      return null;
    }
  }
};

export default getMovementComponent;
