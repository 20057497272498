const toBehaviour = (behavior) => {
  if (!behavior) {
    return 'instant';
  }

  return behavior;
};

/**
 * Scroll to element according to tokens provided.
 *
 * @param {string} elementId The id of the element to scroll to.
 * @param {string | null} behavior The scroll behaviour
 */
export const scrollToElement = (elementId, behavior = 'smooth') => {
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }
  element.scrollIntoView({ behavior: toBehaviour(behavior) });
};

/**
 * Scroll to element according to tokens provided.
 *
 * @param {string} elementId The id of the element to scroll to
 * @param {string} topOffset Top offset
 * @param {string | null} behavior The scroll behaviour
 */
export const scrollToElementWithTopOffset = (elementId, topOffset, behavior = 'smooth') => {
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }
  element.scrollIntoView({ behavior: toBehaviour(behavior) });
  setTimeout(() => {
    window.scrollBy({
      top: topOffset,
      left: 0,
      behavior: toBehaviour(behavior),
    });
  }, 500);
};

export const scrollTopSmooth = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const scrollTo = (x, y, behaviour) => {
  window.scrollTo({
    top: y,
    left: x,
    ...{ ...behaviour && { behavior: behaviour } },
  });
};

export default scrollTo;
