import React from 'react';
import PropTypes from 'prop-types';

const PreviousTaskDetailsSection = ({ children, id }) => (
  <div id={id} className="previous-task-list-details-section">
    {children}
  </div>
);

PreviousTaskDetailsSection.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

export default PreviousTaskDetailsSection;
