import { Link } from '@ukhomeoffice/cop-react-components';
import React from 'react';

// Config
import { EUROPE_LONDON, TIME_FORMATS } from '../../../../../../utils/constants';

// Utils
import DateTimeUtil from '../../../../../../utils/Datetime/Uplift/datetimeUtil';
import PersonUtil from '../../../../../../utils/Person/Uplift/personUtil';

const hasValue = (value) => {
  return !!value;
};

const isActive = (user) => {
  return !!user.active;
};

export const toContent = (entity) => {
  if (!entity?.content) {
    return null;
  }

  if (typeof entity?.content === 'object') {
    return entity?.content?.value;
  }

  return entity?.content;
};

export const toTimestamp = (entity, format = TIME_FORMATS.NOTES) => {
  if (!entity?.timestamp || !DateTimeUtil.validate.date(entity.timestamp)) {
    return null;
  }

  return DateTimeUtil.local.format(entity.timestamp, EUROPE_LONDON, format);
};

const hasNoUserDetails = (entity) => {
  return !hasValue(entity?.user?.firstName) && !hasValue(entity?.user?.lastName) && !hasValue(entity?.user?.email);
};

export const toCreator = (entity) => {
  if (hasNoUserDetails(entity)) {
    return null;
  }

  //  At this point, a user has a firstname, last name and an email but user is not active
  if (!isActive(entity.user)) {
    return (
      <>
        {PersonUtil.formattedName(entity.user.firstName)(entity.user.lastName) || entity.user.email}
      </>
    );
  }

  return (
    <Link
      href={`mailto:${entity.user.email}`}
      title="Clicking this link opens the default email client to let the user send a new email to this email address"
    >
      {PersonUtil.formattedName(entity.user.firstName)(entity.user.lastName) || entity.user.email}
    </Link>
  );
};
