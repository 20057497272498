import React, { useContext, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import pluralise from 'pluralise';
import { useKeycloak } from '../../../../context/Keycloak';
import { useAxiosInstance } from '../../../../utils/Axios/axiosInstance';

// Config
import config from '../../../../utils/config';
import { DISMISS_REASON_DESCRIPTIONS } from '../shared/constants';
import { TAB_ID } from '../../../../utils/constants';
import QUERY_KEYS from '../../../../utils/Hooks/constants';

// Contexts
import { TabContext } from '../../../../context/TabContext';
import { TasksContext } from '../../../../context/TasksContext';

// Services
import AxiosRequests from '../../../../api/axiosRequests';

// Components
import OutcomeMessage from './components/OutcomeMessage';
import RenderForm from '../../../../components/RenderForm/RenderForm';

// Hook
import { TAB_TITLES } from '../../../../utils/Hooks/useSetTabTitle';

// Utils
import { CommonUtil } from '../../../../utils';

// Form
import dismissTask from '../../../../forms/bulkDismissTaskCerberus';

const BulkDismissTask = () => {
  const keycloak = useKeycloak();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const [submitted, setSubmitted] = useState(false);
  const { setSelectedTab, setTabIndex } = useContext(TabContext);
  const { selectedForDismissal, setSelectedForDismissal, setIsSelecting } = useContext(TasksContext);
  const [outcomeDescription, setOutcomeDescription] = useState(null);
  // We need to decide this now because selectedForDismissal gets cleared after the form is submitted
  const outcomeMessage = useMemo(() => {
    return pluralise.withCount(
      selectedForDismissal.length,
      '% task dismissed',
      '% tasks dismissed',
    );
  }, []);

  const invalidateQueries = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TASKS] }),
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TASKS_FILTER_COUNTS] }),
    ]);
  };

  const navigateToDismissedTab = () => {
    setSelectedTab(TAB_ID.SELECTED_FOR_DISMISSAL);
    setTabIndex(1);
    navigate(CommonUtil.getListPath(location?.pathname));
  };

  const updateDescription = ({ reason, otherReason }) => {
    setOutcomeDescription(DISMISS_REASON_DESCRIPTIONS[reason] || otherReason);
  };

  const onSubmit = async ({ data }) => {
    await AxiosRequests.dismissTasks(apiClient, {
      reason: data?.reason || null,
      otherReasonDetail: data?.otherReason || null,
      note: data?.note,
      taskIds: selectedForDismissal,
    })
      .then(() => {
        setSelectedForDismissal([]);
        setSubmitted(true);
        setIsSelecting(false);
      });
  };

  const onCancel = () => {
    setIsSelecting(false);
    setSelectedForDismissal([]);
    navigateToDismissedTab();
  };

  if (submitted) {
    return (
      <OutcomeMessage
        newTabTitle={TAB_TITLES.TASKS_DISMISSED}
        message={outcomeMessage}
        caption={outcomeDescription}
        onFinish={async () => {
          navigateToDismissedTab();
          invalidateQueries();
        }}
      />
    );
  }

  if (!selectedForDismissal.length) {
    navigateToDismissedTab();
    return null;
  }

  return (
    <RenderForm
      form={dismissTask(selectedForDismissal.length)}
      onChange={updateDescription}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

export default BulkDismissTask;
