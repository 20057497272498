import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// Config(s)
import { SCRIM_TYPE } from '../../../../../../../../utils/constants';

// Contexts
import { useScrim } from '../../../../../../../../context/ScrimContext';

// Component(s)
import Modal from '../prompts/Modal';

// Util(s)
import { scrollToElement } from '../../../helper/scrollTo';

// Styling
import './NoteModal.scss';

const NoteModal = ({ onProceed, onCancel }) => {
  const { openScrim, closeScrim } = useScrim();

  useEffect(() => {
    openScrim(SCRIM_TYPE.FULL);
    scrollToElement('root', null);

    return () => {
      closeScrim();
    };
  }, []);

  return (
    <Modal
      id="add-note-modal"
      classBlock="govuk-grid-row"
      onCancel={onCancel}
      onProceed={() => {
        onProceed();
      }}
      modalProps={{
        heading: 'Task note will be deleted',
        actions: {
          proceed: 'Proceed and delete',
          cancel: 'Cancel',
        },
      }}
    />
  );
};

NoteModal.propTypes = {
  onProceed: PropTypes.func,
  onCancel: PropTypes.func,
};

NoteModal.defaultProps = {
  onProceed: () => {},
  onCancel: () => {},
};

export default NoteModal;
