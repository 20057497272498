// Global import(s)
import Keycloak from 'keycloak-js';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Config(s)
import config from '../utils/config';
import DEFAULTS from '../routes/Task/TaskList/shared/constants';
import { LOCAL_STORAGE_KEYS, PATHS } from '../utils/constants';

// Util(s)
import StorageUtil from '../utils/Storage/storageUtil';

const KeycloakContext = createContext();

export const onSessionIdChange = async (sessionId) => {
  const storedSessionId = StorageUtil.get(LOCAL_STORAGE_KEYS.SESSION_ID);
  const keycloakSessionId = sessionId;

  if (!storedSessionId || (storedSessionId !== keycloakSessionId)) {
    Object.keys(DEFAULTS).forEach((view) => StorageUtil.remove(DEFAULTS[view].filters.key));
    StorageUtil.add(LOCAL_STORAGE_KEYS.SESSION_ID, keycloakSessionId);
  }
};

const KeycloakProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [keycloak, setKeycloak] = useState(null);
  const [prevPathIsAirpaxOrRoroPath, setPrevPathIsAirpaxOrRoroPath] = useState(null);
  const [keycloakInstance, setKeycloakInstance] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const isAirpaxOrRoroPath = [PATHS.TARGETS.AIRPAX, PATHS.TARGETS.RORO].some((path) => location?.pathname?.includes(path));

    if (prevPathIsAirpaxOrRoroPath === isAirpaxOrRoroPath) {
      return;
    }

    setPrevPathIsAirpaxOrRoroPath(isAirpaxOrRoroPath);

    const clientConfig = isAirpaxOrRoroPath ? config.keycloak.clientConfig.cop : config.keycloak.clientConfig.cerberus;
    if (!keycloakInstance || keycloakInstance.clientId !== clientConfig.clientId) {
      setKeycloakInstance(new Keycloak(clientConfig));
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (keycloak) {
      onSessionIdChange(keycloak.sessionId)
        .then(() => setIsReady(true));
    }
  }, [keycloak]);

  useEffect(() => {
    keycloakInstance?.init(config.keycloak.initOptions)
      .then((authenticated) => {
        if (authenticated) {
          setKeycloak(keycloakInstance);
        } else {
          keycloakInstance.login();
        }
      });
  }, [keycloakInstance]);

  if (!isReady) {
    return null;
  }

  return (
    <KeycloakContext.Provider value={keycloak}>
      {children}
    </KeycloakContext.Provider>
  );
};

const useKeycloak = () => useContext(KeycloakContext);

export { KeycloakContext, KeycloakProvider, useKeycloak };
