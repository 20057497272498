import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import Tabs from '../../../../../components/Tabs/Tabs';

// Util(s)
import toTargetSheetTab from '../../Shared/helpers/toTargetSheetTab';
import toBorderEventTab from '../../Shared/helpers/toBorderEventTab';

// Styling
import './DetailsTab.scss';

const DetailsTab = ({ isWithdrawnByTargeter, targetSheet, borderEvent, hasBorderEvent, children }) => {
  if (!targetSheet) {
    return children;
  }

  return (
    <Tabs
      className="hods-tabs"
      items={[
        {
          id: 'tab-task-detail',
          label: 'Task detail',
          panel: children,
        },
        toTargetSheetTab(targetSheet, isWithdrawnByTargeter),
        toBorderEventTab(hasBorderEvent, borderEvent),
      ].filter((tab) => !!tab)}
    />
  );
};

DetailsTab.propTypes = {
  isWithdrawnByTargeter: PropTypes.bool.isRequired,
  hasBorderEvent: PropTypes.bool.isRequired,
  targetSheet: PropTypes.shape({}),
  borderEvent: PropTypes.shape({}),
};

DetailsTab.defaultProps = {
  targetSheet: null,
  borderEvent: null,
};

export default DetailsTab;
