import React from 'react';
import { useKeycloak } from '../../../../../../context/Keycloak';
import { useAxiosInstance } from '../../../../../../utils/Axios/axiosInstance';

// Config
import config from '../../../../../../utils/config';

// Components
import RenderForm from '../../../../../../components/RenderForm/RenderForm';

// JSON
import noteCerberus from '../../../../../../forms/noteCerberus';
import AxiosRequests from '../../../../../../api/axiosRequests';

const TaskNotes = ({ displayForm, taskId, setRefreshTaskData }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);

  return (
    <>
      {displayForm && (
        <RenderForm
          preFillData={{ taskId }}
          onSubmit={
            async ({ data }) => {
              await AxiosRequests.saveNote(apiClient, taskId, [{
                value: data.note,
              }]);
              setRefreshTaskData();
            }
          }
          form={noteCerberus}
        />
      )}
    </>
  );
};

export default TaskNotes;
