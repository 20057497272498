import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { PNC_OUTCOMES } from '../../../../../../utils/TargetInformation/constants';

// Component(s)
import AccordionRow from '../../../../../../components/AccordionCustom/AccordionRow';

// Util(s)
import { CredibilityChecksUtil } from '../../../../../../utils';
import { toFormattedOutcome, toFormattedValue, toRecordsFound } from '../../../../../../utils/PNC/adaptPncContent';

const PncCheck = ({ person }) => {
  const pnc = CredibilityChecksUtil.getPnc(person);

  const toContent = () => {
    const { outcome } = pnc.result;
    if (outcome !== PNC_OUTCOMES.POTENTIALLY_RELEVANT_INFORMATION_FOUND) {
      return <p id="pnc-outcome" className="govuk-body govuk-!-margin-bottom-0">{toFormattedOutcome(CredibilityChecksUtil.outcome(pnc))}</p>;
    }

    return (
      <dl className="govuk-summary-list">
        <AccordionRow
          label="PNC ID"
          value={toFormattedValue(CredibilityChecksUtil.pncId(pnc))}
          id="pnc-id"
        />
        <AccordionRow
          label="PNC records found"
          value={toRecordsFound(CredibilityChecksUtil.categories(pnc))}
          id="pnc-records-found"
        />
        <AccordionRow
          label="Other concerns for the safety of frontline staff"
          value={toFormattedValue(CredibilityChecksUtil.staffConcerns(pnc))}
          id="staff-safety-concern"
        />
        <AccordionRow
          label="Other concerns for the person of interest"
          value={toFormattedValue(CredibilityChecksUtil.poiConcerns(pnc))}
          id="poi-safety-concern"
        />
      </dl>
    );
  };

  if (!pnc?.result) {
    return null;
  }

  return (
    <>
      <Heading className="govuk-!-padding-top-3" size="s">Police National Computer (PNC) check</Heading>
      {toContent()}
    </>
  );
};

export default PncCheck;

PncCheck.propTypes = {
  person: PropTypes.shape({}).isRequired,
};
