import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Config
import { TARGET_OPERATION } from '../../../../../../../../../utils/constants';

// Component(s)
import LoadingSpinner from '../../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import RenderForm from '../../../../../../../../../components/RenderForm/RenderForm';

// Hook
import { setTabTitle, TAB_TITLES } from '../../../../../../../../../utils/Hooks/useSetTabTitle';

// Util(s)
import addPreviousSelection from '../helper/addPreviousSelection';
import containsRecipient from '../validate/containsRecipient';
import { Renderers } from '../../../../../../../../../utils/Form/ReactForm';

// Form(s)
import targetRecipient, { TARGET_RECIPIENT_ID_GA } from '../../../../../../../../../forms/uplift/targetRecipientGA';

const GaAddRecipient = ({ actionType, previouslySelectedRecipient, onSave, formData, setFormData, onCancel, targetRecipientOptions }) => {
  const [form, setForm] = useState(null);

  setTabTitle(TAB_TITLES.ADD_TARGET_SHEET_RECIPIENT);

  const onInternalSaveAndClose = (data) => {
    if (containsRecipient(formData, data)) {
      // Do not save the data, instead call the onSave callback.
      if (typeof onSave === 'function') {
        onSave();
        return;
      }
    }

    setFormData((prev) => {
      return {
        ...prev,
        targetRecipients: [
          ...(prev?.targetRecipients ? prev.targetRecipients : []),
          data.targetRecipient,
        ],
      };
    });

    if (typeof onSave === 'function') {
      onSave();
    }
  };

  useEffect(() => {
    if (!previouslySelectedRecipient) {
      // Setup component options
      const _form = {
        ...targetRecipient,
      };
      _form.pages = _form.pages.map((page) => {
        return {
          ...page,
          components: page.components.map((component) => {
            if (component.id !== TARGET_RECIPIENT_ID_GA) {
              return component;
            }

            return {
              ...component,
              data: {
                options: targetRecipientOptions,
              },
            };
          }),
        };
      });
      setForm(_form);
      return;
    }

    setForm(addPreviousSelection(targetRecipient, targetRecipientOptions, TARGET_RECIPIENT_ID_GA, targetRecipientOptions, previouslySelectedRecipient));
  }, []);

  useEffect(() => {
    return () => setTabTitle(actionType === TARGET_OPERATION.CREATE ? TAB_TITLES.CREATE_TARGET : TAB_TITLES.EDIT_TARGET);
  }, []);

  if (!form) {
    return <LoadingSpinner />;
  }

  return (
    <div id="add-recipient" className={classNames('govuk-grid-row', previouslySelectedRecipient && 'option-divider')}>
      <div className="govuk-grid-column-one-third">
        <RenderForm
          form={form}
          viewOnly={false}
          onSubmit={async ({ data }) => onInternalSaveAndClose(data)}
          renderer={Renderers.REACT}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};

GaAddRecipient.propTypes = {
  actionType: PropTypes.string.isRequired,
  previouslySelectedRecipient: PropTypes.string,
  formData: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  targetRecipientOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

GaAddRecipient.defaultProps = {
  previouslySelectedRecipient: null,
  targetRecipientOptions: [],
};

export default GaAddRecipient;
