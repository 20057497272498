import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import PropTypes from 'prop-types';
import { withCount } from 'pluralise';

// Config
import { ALL_SECURITY_LABELS, SECURITY_LABELS, VIEW } from '../../../../../../../../utils/constants';

// Component
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import HeaderModule from '../generics/HeaderModule';
import ThreatTypeS4 from './ThreatTypeS4';
import ThreatTypeDefault from './ThreatTypeDefault';

// Context
import { useView } from '../../../../../../../../context/ViewContext';

// Utils
import { getMatchedRules, getS4RulesTotalScore } from '../../../../../../../../utils/Risks/Uplift/risksUtil';

// Styling
import './ThreatType.scss';

const ThreatType = ({ task }) => {
  const { view } = useView();

  // General Aviation has no threats;
  if (view === VIEW.GENERAL_AVIATION) {
    return null;
  }

  let rules = getMatchedRules(task);

  const removeNonViewTriggeredRules = (basicRules) => {
    if (!view) {
      return basicRules;
    }

    const invalidLabels = ALL_SECURITY_LABELS.filter((label) => SECURITY_LABELS[view]?.includes(label));

    const newRules = basicRules.reduce((prev, current) => {
      const newLabels = current.securityLabels?.filter((label) => !invalidLabels.includes(label));
      if (newLabels.length > 0) {
        prev.push(current);
      }
      return prev;
    }, []);

    return newRules;
  };

  if (rules && rules.length > 0) {
    rules = removeNonViewTriggeredRules(rules);
  }

  const getRulesHeaderText = () => {
    const rulesLength = rules?.length || 0;
    return `${withCount(rulesLength, '% rule', '% rules')} matched`;
  };

  const containsS4RuleEntity = () => {
    return rules.some((rule) => !!rule?.s4Rule);
  };

  const hasS4RulesTotalScore = (value) => {
    return !!value;
  };

  const toS4TotalScoreText = () => {
    return `Total score : ${getS4RulesTotalScore(task)}`;
  };

  const hasAnyS4Data = (rule) => {
    return rule?.s4Rule?.date || rule?.s4Rule?.score || rule?.s4Rule?.status || (rule?.s4Rule?.replayMatch !== undefined && rule?.s4Rule?.replayMatch !== null);
  };

  return (
    <div>
      <Heading id="threat-section-header" size="l">Threat/abuse type</Heading>
      <div className="govuk-grid-column-full">
        <HeaderModule
          id="rules-matched-header"
          items={[
            {
              content: (
                <>
                  <div key="1" className="grid-item-span-4">
                    <Heading id="rules-matched-count" size="m">{getRulesHeaderText()}</Heading>
                  </div>
                  <ComponentWrapper show={containsS4RuleEntity() && hasS4RulesTotalScore(task.risks?.s4RulesTotalScore)}>
                    <div key="2">
                      <p id="rules-total-score">{toS4TotalScoreText()}</p>
                    </div>
                  </ComponentWrapper>
                </>
              ),
            },
          ]}
        />
      </div>
      {rules.length > 0 && rules?.map((rule, idx) => (
        hasAnyS4Data(rule)
          ? <ThreatTypeS4 key={`${rule.id}-${idx}`} rule={rule} index={idx} />
          : <ThreatTypeDefault key={`${rule.id}-${idx}`} rule={rule} index={idx} />
      ),
      )}
    </div>
  );
};

ThreatType.propTypes = {
  task: PropTypes.shape({}).isRequired,
};
export default ThreatType;
