import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import LinkButton from '../../../../../../../../../components/Buttons/LinkButton';
import Table from '../../../../../../../../../components/Table/Table';

// Styling
import './CommoditiesTargetRecipients.scss';

const GaTargetRecipients = ({ formData, onChangeRecipient, onRemoveRecipient, ...props }) => {
  const TABLE_HEADINGS = ['Recipient', 'Telephone', 'Actions'];

  if (!Array.isArray(formData?.targetRecipients) || !formData?.targetRecipients?.length) {
    return null;
  }

  const replaceSpacesWithDashes = (recipientName) => {
    return recipientName.split(' ').join('-');
  };

  const toRows = () => {
    return formData.targetRecipients.map((targetRecipient) => [
      targetRecipient.customName,
      targetRecipient.telephone,
      <LinkButton
        key={targetRecipient.customName}
        id={`change-recipient-${replaceSpacesWithDashes(targetRecipient.customName)}`}
        className="govuk-link"
        data-recipientname={targetRecipient.customName}
        onClick={(e) => {
          const { dataset } = e.target;
          onChangeRecipient(dataset.recipientname);
        }}
      >
        Change
      </LinkButton>,
      (formData.targetRecipients.length > 1 ? (
        <LinkButton
          id={`remove-recipient-${replaceSpacesWithDashes(targetRecipient.customName)}`}
          className="govuk-link"
          data-recipientname={targetRecipient.customName}
          onClick={(e) => {
            const { dataset } = e.target;
            onRemoveRecipient(dataset.recipientname);
          }}
        >
          Remove
        </LinkButton>
      ) : null),
    ].filter((item) => !!item));
  };

  return (
    <Table
      {...props}
      className="target-recipients"
      headings={TABLE_HEADINGS}
      rows={toRows()}
    />
  );
};

GaTargetRecipients.propTypes = {
  formData: PropTypes.shape({}),
  onChangeRecipient: PropTypes.func.isRequired,
  onRemoveRecipient: PropTypes.func.isRequired,
};

GaTargetRecipients.defaultProps = {
  formData: null,
};

export default GaTargetRecipients;
