import React from 'react';
import PropTypes from 'prop-types';

// Contexts
import { useKeycloak } from '../../../../../../../../context/Keycloak';

// Utils
import { PersonUtil } from '../../../../../../../../utils';

const TaskActionedBy = ({ actioner }) => {
  const keycloak = useKeycloak();
  const userEmail = keycloak.tokenParsed.email;

  if (!actioner) {
    return null;
  }

  const toTaskActionedBy = () => {
    return userEmail === actioner.email ? 'Me' : PersonUtil.formattedName(actioner.firstName)(actioner.lastName);
  };

  return (
    <span id="task-actioner" className="font--grey">
      {`Actioned by: ${toTaskActionedBy()}`}
    </span>
  );
};

TaskActionedBy.propTypes = {
  actioner: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
};

TaskActionedBy.defaultProps = {
  actioner: null,
};

export default TaskActionedBy;
