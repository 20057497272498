// Global import(s)
import * as pluralise from 'pluralise';
import React from 'react';

// Config(s)
import { ICON, ICON_MAPPING, MOVEMENT_MODES, STRINGS } from '../../../../../../utils/constants';

// Component(s)
import Icon, { ShipIcon } from '../../../../../../components/Icon/Icon';

// Util(s)
import asUnknownIfRequired from '../../../../../../utils/Transform/valueSanitizer';
import { JourneyUtil, MovementUtil, PersonUtil, StringUtil, TrailerUtil, VehicleUtil, VesselUtil } from '../../../../../../utils';

// Styling
import '../../../CurrentTaskDetails/roro/assets/TaskSummary.scss';

const toFreightDescriptionContent = (vehicle, trailer, person) => {
  const vehicleRegistration = asUnknownIfRequired(VehicleUtil.registration(vehicle));
  return (
    <>
      {vehicleRegistration || ''}
      {vehicle && trailer ? <span className="govuk-!-font-weight-regular"> {STRINGS.DESCRIPTIONS.PREPOSITIONS.WITH} </span> : ''}
      {trailer ? TrailerUtil.registration(trailer) : ''}
      {vehicle && person ? <span className="govuk-!-font-weight-regular"> driven by {PersonUtil.fullname(person)}</span> : ''}
    </>
  );
};

const toFreightDescription = (vehicle, trailer) => {
  let description = '';
  description += vehicle ? STRINGS.DESCRIPTIONS.VEHICLE : '';
  description += vehicle && trailer ? ` ${STRINGS.DESCRIPTIONS.PREPOSITIONS.WITH} ` : '';
  description += trailer ? STRINGS.DESCRIPTIONS.TRAILER : '';
  return description;
};

const toTouristDescription = (vehicle, totalPersons) => {
  let description = '';
  description += vehicle && totalPersons >= 0 ? STRINGS.DESCRIPTIONS.VEHICLE : '';
  description += !vehicle && totalPersons === 1 ? STRINGS.DESCRIPTIONS.SINGLE_PASSENGER : '';
  description += !vehicle && totalPersons > 1 ? STRINGS.DESCRIPTIONS.GROUP : '';
  return description;
};

const toMovementDescriptionContent = (mode, vehicle, trailer, person, totalPersons, iconFromDescription) => {
  switch (mode) {
    case MOVEMENT_MODES.UNACCOMPANIED_FREIGHT:
    case MOVEMENT_MODES.ACCOMPANIED_FREIGHT: {
      return toFreightDescriptionContent(vehicle, trailer, person);
    }
    case MOVEMENT_MODES.TOURIST: {
      const vehicleRegistration = asUnknownIfRequired(VehicleUtil.registration(vehicle));
      if (iconFromDescription === ICON.CAR) {
        return (
          <>
            {vehicleRegistration || ''}
            {vehicle && person ? <span className="govuk-!-font-weight-regular"> driven by {PersonUtil.fullname(person)}</span> : ''}
          </>
        );
      }
      if (iconFromDescription === ICON.INDIVIDUAL || iconFromDescription === ICON.GROUP) {
        return (
          <span className="govuk-!-font-weight-bold">
            {pluralise.withCount(totalPersons, `% ${STRINGS.DESCRIPTIONS.FOOT_PASSENGER}`, `% ${STRINGS.DESCRIPTIONS.FOOT_PASSENGER}s`, null)}
          </span>
        );
      }
      return null;
    }
    default: {
      return null;
    }
  }
};

const toMovementDescription = (mode, vehicle, trailer, totalPersons) => {
  switch (mode) {
    case MOVEMENT_MODES.UNACCOMPANIED_FREIGHT:
    case MOVEMENT_MODES.ACCOMPANIED_FREIGHT: {
      return toFreightDescription(vehicle, trailer);
    }
    case MOVEMENT_MODES.TOURIST: {
      return toTouristDescription(vehicle, totalPersons);
    }
    default: {
      return null;
    }
  }
};

const RoRoTaskSummary = ({ version }) => {
  const mode = MovementUtil.movementMode(version);
  const description = MovementUtil.iconDescription(version);
  const iconFromDescription = ICON_MAPPING[mode]?.[description];
  const journey = JourneyUtil.get(version);
  const vessel = VesselUtil.get(version);
  const vehicle = VehicleUtil.get(version);
  const person = PersonUtil.get(version);
  const trailer = TrailerUtil.get(version);
  const arrivalTime = JourneyUtil.arrivalTime(journey);
  const totalPersons = PersonUtil.totalPersons(version);
  return (
    <div id="details-task-summary" className="details-task-summary overflow-hidden">
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-one-third voyage-description-container">
          <div id="voyage-mode-type-icon">
            <Icon name={ICON_MAPPING[mode]?.[description]} />
          </div>
          <div id="voyage-movement-description">
            <p className="govuk-body-s govuk-!-margin-bottom-0">
              {toMovementDescription(mode, vehicle, trailer, totalPersons)}
            </p>
            <p className="govuk-body-s govuk-!-margin-bottom-0 govuk-!-font-weight-bold">
              {toMovementDescriptionContent(mode, vehicle, trailer, person, totalPersons, iconFromDescription)}
            </p>
          </div>
        </div>
        <div className="govuk-grid-column-two-thirds voyage-details-container">
          <div id="voyage-movement-details">
            <p className="govuk-body-s govuk-!-margin-bottom-0">
              {`${VesselUtil.operator(vessel)} voyage of ${VesselUtil.name(vessel)}`}
            </p>
            <p className="govuk-body-s content-line-two govuk-!-margin-bottom-0">
              <span>{`${JourneyUtil.formatDepartureTime(journey)}`}</span>
              <span className="dot" />
              <span className="govuk-!-font-weight-bold">{JourneyUtil.departureLoc(journey)}</span>
              <span className="right-arrow"> &#8594; </span>
              <span className="govuk-!-font-weight-bold"> {JourneyUtil.arrivalLoc(journey)}</span>
              <span className="dot" />
              <span>{JourneyUtil.formatArrivalTime(journey)}</span>
              <br />
              <span>{StringUtil.voyageText(arrivalTime)}</span>
            </p>
          </div>
          <div id="voyage-movement-icon">
            <ShipIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoRoTaskSummary;
