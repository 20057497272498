// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useWindowSize } from 'react-use';

// Config(s)
import { SYSTEM_CHECKS } from '../../../../../Task/TaskDetails/CurrentTaskDetails/air/components/shared/checks/constants';
import { SYSTEM_CHECKS_LABELS } from '../../../../TargetsListPage/constants';
import { VIEW } from '../../../../../../utils/constants';

// Context
import { useView } from '../../../../../../context/ViewContext';

// Component(s)
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import ExtendedListRow from '../generics/ExtendedListRow';
import SummaryListRow from '../generics/SummaryListRow';

// Util(s)
import CommonUtil from '../../../../../../utils/Common/commonUtil';
import { isMobilePortrait } from '../../../../../../utils/TargetSheet/targetSheetUtil';

const SystemChecksCompleted = ({ checksCompleted, preview }) => {
  const { view } = useView();
  const { width } = useWindowSize();

  if (!checksCompleted?.length) {
    return null;
  }

  const checkIfAirpaxView = () => {
    return [VIEW.AIRPAX_IO, VIEW.AIRPAX_HO, VIEW.AIRPAX_RCCU, VIEW.AIRPAX, VIEW.AIRPAX_V2, VIEW.COMMODITIES, VIEW.OSDT, VIEW.CTBP, VIEW.IDP].includes(view);
  };

  const toTraceValue = ({ type, result }) => {
    if (!type) {
      return null;
    }

    const { trace, comment } = result;

    if (!trace) {
      return '';
    }

    return comment;
  };

  let panelData = (() => {
    return CommonUtil.uniqueId(checksCompleted)
      .filter((checkCompleted) => !!checkCompleted.result)
      .map((checkCompleted, index) => ({
        id: `check-completed-${index + 1}`,
        label: checkCompleted.type !== SYSTEM_CHECKS.OTHER
          ? SYSTEM_CHECKS_LABELS[checkCompleted.system]
          : (checkCompleted?.result?.systemChecked || ''),
        result: checkCompleted?.result?.trace ? 'Trace' : 'No trace',
        value: checkCompleted?.result ? toTraceValue(checkCompleted) : 'No trace',
      }));
  })();

  if (preview) {
    panelData = (() => {
      return CommonUtil.uniqueId(checksCompleted)
        .filter((checkCompleted) => checkCompleted?.result)
        .map((checkCompleted, index) => ({
          id: `check-completed-${index + 1}`,
          label: SYSTEM_CHECKS_LABELS[checkCompleted.system],
          result: checkCompleted?.result?.trace ? 'Trace' : 'No trace',
          value: checkCompleted?.result ? toTraceValue(checkCompleted) : 'No trace',
        }));
    })();
  }

  if (!panelData?.length) {
    return null;
  }

  const getTitle = () => {
    if (checkIfAirpaxView()) {
      return 'Other checks completed';
    }
    return 'System checks completed';
  };

  return (
    <div className="system-checks-completed">
      <Heading
        aria-label={getTitle()}
        className="govuk-!-padding-top-3"
        size="s"
      >
        {getTitle()}
      </Heading>

      <ComponentWrapper show={!isMobilePortrait(width)}>
        <div className="govuk-summary-list">
          <ComponentWrapper show={checkIfAirpaxView()}>
            <ExtendedListRow
              id="system-checks-completed-header"
              type="body"
              values={[
                'Check',
                'Result',
                'Comment',
              ]}
            />
          </ComponentWrapper>
          {panelData.map((item) => (
            <React.Fragment key={item.id}>
              <ComponentWrapper show={!checkIfAirpaxView()}>
                <SummaryListRow key={item.id} id={item.id} label={item.label} value={item.value} />
              </ComponentWrapper>
              <ComponentWrapper show={checkIfAirpaxView()}>
                <ExtendedListRow
                  id={item.id}
                  type="body"
                  values={[
                    item.label,
                    item.result,
                    item.value,
                  ]}
                />
              </ComponentWrapper>
            </React.Fragment>
          ))}
        </div>
      </ComponentWrapper>

      <ComponentWrapper show={isMobilePortrait(width)}>
        <div className="govuk-summary-list">
          {panelData.map((item, index) => (
            <React.Fragment key={item.id}>
              <ComponentWrapper show={!checkIfAirpaxView()}>
                <SummaryListRow key={item.id} id={item.id} label={item.label} value={item.value} />
              </ComponentWrapper>
              <ComponentWrapper show={checkIfAirpaxView()}>
                <ExtendedListRow
                  activeClass="mobile"
                  id={`upper-item-${item.id}-${index}`}
                  mobile
                  type="body"
                  values={[
                    item.label,
                    item.result,
                  ]}
                />
                <ComponentWrapper show={item.value}>
                  <ExtendedListRow
                    id={`lower-item-${item.id}-${index}`}
                    mobile
                    type="body"
                    values={[
                      item.value,
                    ]}
                  />
                </ComponentWrapper>
              </ComponentWrapper>
            </React.Fragment>
          ))}
        </div>
      </ComponentWrapper>
    </div>
  );
};

SystemChecksCompleted.propTypes = {
  checksCompleted: PropTypes.arrayOf(PropTypes.shape({})),
  preview: PropTypes.bool,
};

SystemChecksCompleted.defaultProps = {
  checksCompleted: [],
  preview: false,
};

export default memo(SystemChecksCompleted);
