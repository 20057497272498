import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { MOVEMENT_MODES } from '../../../../utils/constants';
import { DIRECTION } from '../../../../utils/TargetInformation/constants';

// Util(s)
import { DateTimeUtil, TargetJourneyUtil, TargetMovementUtil } from '../../../../utils';

const TargetInfoHeader = ({ informationSheet }) => {
  const mode = TargetMovementUtil.mode(informationSheet);
  const journey = TargetJourneyUtil.get(informationSheet);
  const direction = TargetJourneyUtil.direction(journey);

  const toDirectionHeader = () => {
    if (direction === DIRECTION.INBOUND) {
      return `Arriving ${DateTimeUtil.format(TargetJourneyUtil.arrivalDate(journey), 'DD MMMM YYYY')}`
        + ` at ${DateTimeUtil.format(TargetJourneyUtil.arrivalDate(journey), 'HH:mm')}`;
    }

    return `Departing ${DateTimeUtil.format(TargetJourneyUtil.departureDate(journey), 'DD MMMM YYYY')}`
      + ` at ${DateTimeUtil.format(TargetJourneyUtil.departureDate(journey), 'HH:mm')}`;
  };

  const toArrivalLocation = () => {
    return TargetJourneyUtil.format.airportCity(TargetJourneyUtil.arrivalLocation(journey));
  };

  const toAirPassengerHeader = () => {
    return (
      <>
        {`${TargetJourneyUtil.id(journey)} ${TargetJourneyUtil.departureLocation(journey)}`
          + ` to ${TargetJourneyUtil.arrivalLocation(journey)} (${toArrivalLocation()})`}
        <br />
        {toDirectionHeader()}
      </>
    );
  };

  const toRoRoHeader = () => {
    return (
      <>
        {TargetMovementUtil.vesselName(informationSheet)}
        <br />
        {toDirectionHeader()}
      </>
    );
  };

  const getHeading = () => {
    if (mode === MOVEMENT_MODES.AIR_PASSENGER) {
      return toAirPassengerHeader();
    }

    return toRoRoHeader();
  };

  if (!informationSheet) {
    return null;
  }

  return (
    <Heading id="target-info-header" size="l">
      {getHeading()}
    </Heading>
  );
};

export default TargetInfoHeader;

TargetInfoHeader.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};
