// Global import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Components
import CrewPassengerCount from '../components/CrewPassengerCount';
import FlightCode from '../components/FlightCode';
import JourneyDetail from '../components/JourneyDetail';
import TimeCountDown from '../../../../../../../../../components/Uplift/Countdown/TimeCountDown';

// Hooks
import useSummary from '../hooks/useSummary.hooks';

// Utils
import JourneyUtil from '../../../../../../../../../utils/Journey/Uplift/journeyUtil';
import OccupantsUtil from '../../../../../../../../../utils/Occupants/occupantsUtil';
import toEventDate from '../../../../../../../TaskList/air/helper/toEventDate';
import { toDepartureArrivalLabel } from '../helper/common';

// Styling
import './Summary.scss';

const Summary = ({ task }) => {
  const summaryClasses = useSummary();
  const journey = JourneyUtil.get(task);
  const direction = JourneyUtil.direction(journey);
  const eventDate = toEventDate(journey);
  const occupants = OccupantsUtil.get(task);
  const totalOccupants = OccupantsUtil.occupantsTotal(occupants);
  const totalCrew = OccupantsUtil.crewTotal(occupants);
  const totalPassengers = OccupantsUtil.passengersTotal(occupants);

  return (
    <div id="ga-task-summary-container">
      <div id="ga-task-summary" className={summaryClasses()}>
        <div className="govuk-grid-row govuk-grid-column-full task-summary-line-five">
          <div className="govuk-grid-column-one-quarter text-align__left">
            <FlightCode flightNumber={task.movement?.vehicle?.registration} />
          </div>
          <div className="govuk-grid-column-two-quarters text-align__center">
            <JourneyDetail
              journey={journey}
              locationClassName="ho-heading-s"
              timeClassName="ho-body"
            />
          </div>
          <div className="govuk-grid-column-one-quarter text-align__right">
            <p id="summary-movement-due-countdown">
              <span id="summary-movement-due-description">
                {toDepartureArrivalLabel(direction)}
              </span>
              <span id="summary-event-countdown" className="govuk-!-font-weight-bold">
                <TimeCountDown dateTime={eventDate} summary />
              </span>
            </p>
          </div>
        </div>
        <div className="govuk-grid-row govuk-grid-column-full task-summary-line-six">
          <div className="govuk-grid-column-full text-align__center">
            <CrewPassengerCount totalCount={totalOccupants} crewCount={totalCrew} passengerCount={totalPassengers} />
          </div>
        </div>
      </div>
    </div>
  );
};

Summary.propTypes = {
  task: PropTypes.shape({}).isRequired,
};

export default Summary;
