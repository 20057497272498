// Global import(s)
import { Button } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import QUERY_KEYS from '../../../../../../../utils/Hooks/constants';

// Config(s)
import config from '../../../../../../../utils/config';

// Context(s)
import { useKeycloak } from '../../../../../../../context/Keycloak';
import { useTasks } from '../../../../../../../context/TasksContext';

// Services
import AxiosRequests from '../../../../../../../api/axiosRequests';

// Component(s)
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';

// Hook(s)
import { useAxiosInstance } from '../../../../../../../utils/Axios/axiosInstance';

// Styling
import '../assets/Button.scss';

const UnclaimTaskButton = ({ taskId, isTaskDetails, ...props }) => {
  const queryClient = useQueryClient();
  const { removeTasksByIds } = useTasks();
  const keycloak = useKeycloak();
  const [inProgress, setInProgress] = useState(false);
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);

  const removeTask = async (taskIdParam) => {
    removeTasksByIds(taskIdParam);
  };

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TASKS_FILTER_COUNTS] });
  };

  const onUnclaim = async () => {
    setInProgress(true);
    await AxiosRequests.unclaim(apiClient, taskId)
      .then(() => {
        if (isTaskDetails) {
          window.location.reload(false);
        } else {
          removeTask(taskId);
          invalidateQueries();
        }
        setInProgress(false);
      })
      .catch(() => setInProgress(false));
  };

  return (
    <>
      <ComponentWrapper show={inProgress}>
        <span id="in-progress-dialog" className="ho-body">Please wait...</span>
      </ComponentWrapper>
      <ComponentWrapper show={!inProgress}>
        <Button aria-label={`unclaim task ${taskId}`} onClick={onUnclaim} {...props}>Unclaim</Button>
      </ComponentWrapper>
    </>
  );
};

UnclaimTaskButton.propTypes = {
  taskId: PropTypes.string.isRequired,
};

export default UnclaimTaskButton;
