// Global import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import ComponentWrapper from '../../../../components/ComponentWrapper/ComponentWrapper';
import PreviousTaskAction from './PreviousTaskAction';
import PreviousTaskDetailsSection from './PreviousTaskDetailsSection';
import RulesHit from './RulesHit';
import SeizuresSection from './SeizuresSection';

// Util(s)
import { getRisks, getTargeterAction, getFrontLineAction, getSeizures, isTaskLive } from '../../../../utils/PreviousTask/previousTaskUtil';
import { TARGETER_OUTCOME, TASK_STATUS } from '../../../../utils/constants';

const PreviousTaskDetails = ({ task }) => {
  const hasOutcome = !!getFrontLineAction(task)?.outcome;

  const isDismissedByTargeter = getTargeterAction(task)?.outcome === TARGETER_OUTCOME.DISMISSED;
  const isWithdrawnByTargeter = getTargeterAction(task)?.outcome === TARGETER_OUTCOME.WITHDRAWN;
  const isLiveTask = isTaskLive(task);

  // TODO task status not available in stub... Have done best to reverse engineer the status
  let taskStatus = TASK_STATUS.NEW;
  if (getTargeterAction(task)?.outcome === TARGETER_OUTCOME.TARGET_ISSUED) {
    taskStatus = TASK_STATUS.ISSUED;
  } else if (!isLiveTask) {
    taskStatus = TASK_STATUS.COMPLETE;
  }

  return (
    <section>
      <PreviousTaskDetailsSection id="previous-task-list-details-section">
        <RulesHit rules={getRisks(task)} />
        <PreviousTaskAction
          actionType="targeterAction"
          action={getTargeterAction(task)}
          taskStatus={taskStatus}
          isDismissedByTargeter={isDismissedByTargeter}
          isWithdrawnByTargeter={isWithdrawnByTargeter}
        />
      </PreviousTaskDetailsSection>
      <ComponentWrapper show={hasOutcome}> {/* Has outcome, therefore task is completed */}
        <div style={{ padding: '0px 25px' }}>
          <hr />
        </div>
        <PreviousTaskDetailsSection id="previous-task-list-details-outcome">
          <PreviousTaskAction
            actionType="frontlineAction"
            action={getFrontLineAction(task)}
            taskStatus={TASK_STATUS.COMPLETE}
            isDismissedByTargeter={isDismissedByTargeter}
            isWithdrawnByTargeter={isWithdrawnByTargeter}
          />
          <SeizuresSection seizures={getSeizures(task)} />
        </PreviousTaskDetailsSection>
      </ComponentWrapper>
    </section>
  );
};

PreviousTaskDetails.propTypes = {
  task: PropTypes.shape({}).isRequired,
};

export default PreviousTaskDetails;
