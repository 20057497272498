import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Occupant from '../../shared/Occupant';
import OccupantCount from '../../shared/OccupantCount';

import { JourneyUtil, MovementUtil, PersonUtil, TaskVersionUtil } from '../../../../../../../../../utils';

const GroupOccupants = ({ version, versionDiff, classModifiers }) => {
  // This object is the current version that has been patched with changed data from previous version.
  const patchedPreviousVersion = TaskVersionUtil.patchPrevious(version, versionDiff);
  const mode = MovementUtil.movementMode(version);

  // Data from current version
  const journey = JourneyUtil.get(version);
  const departureTime = JourneyUtil.departureTime(journey);
  const primaryTraveller = PersonUtil.get(version);
  const otherPersons = PersonUtil.getOthers(version);
  const secondaryCoTraveller = otherPersons[0] || undefined;
  const tertiaryCoTravellers = otherPersons?.slice(1);
  const occupantCounts = MovementUtil.occupantCounts(version);

  // Data from previous version
  const journeyFromPreviousVersion = JourneyUtil.get(patchedPreviousVersion);
  const occupantCountsFromPreviousVersion = MovementUtil.occupantCounts(patchedPreviousVersion);

  // Diff
  const journeyDiff = TaskVersionUtil.diff(journey, journeyFromPreviousVersion);

  return (
    <div className={classNames('task-details-container', classModifiers)}>
      <OccupantCount
        mode={mode}
        primaryTraveller={primaryTraveller}
        coTravellers={otherPersons}
        occupantCounts={occupantCounts}
        occupantCountsDiff={occupantCountsFromPreviousVersion}
        classModifiers={primaryTraveller ? ['govuk-!-padding-bottom-1'] : []}
        label="Occupants"
      />

      {secondaryCoTraveller ? (
        <>
          <Heading className="govuk-!-margin-top-0" size="m">Other travellers</Heading>
          <Occupant
            personPosition={2}
            departureTime={departureTime}
            journeyDiff={journeyDiff}
            classModifiers={tertiaryCoTravellers?.length
              ? ['govuk-!-padding-top-0', 'bottom-border-thin'] : ['govuk-!-padding-top-0']}
            version={version}
            previousVersion={patchedPreviousVersion}
          />
        </>
      ) : null}

      {tertiaryCoTravellers?.length ? (
        <details className="govuk-details govuk-!-padding-top-2" data-module="govuk-details">
          <summary className="govuk-details__summary">
            <span className="govuk-details__summary-text">Show more</span>
          </summary>
          <div className="govuk-hidden-passengers">
            {tertiaryCoTravellers.map((_person, index) => {
              return (
                <Occupant
                  key={_person?.entityId?.poleV1Id || _person?.entityId?.poleV2Id}
                  personPosition={index + 3}
                  departureTime={departureTime}
                  journeyDiff={journeyDiff}
                  classModifiers={index !== tertiaryCoTravellers.length - 1
                    ? ['govuk-!-padding-top-4', 'bottom-border-thin'] : ['govuk-!-padding-top-4']}
                  version={version}
                  previousVersion={patchedPreviousVersion}
                />
              );
            })}
          </div>
        </details>
      ) : null}
    </div>
  );
};

GroupOccupants.propTypes = {
  version: PropTypes.object.isRequired,
  versionDiff: PropTypes.object,
  classModifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

GroupOccupants.defaultProps = {
  classModifiers: [],
  versionDiff: {},
};

export default GroupOccupants;
