import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import { useParams } from 'react-router-dom';

// Config(s)
import { EUROPE_LONDON, TIME_FORMATS } from '../../../../../../../../utils/constants';

// Context(s)
import { useTask } from '../../../../../../../../context/TaskContext';

// Components
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import LoadingSpinner from '../../../../../../../../components/LoadingSpinner/LoadingSpinner';

// Hooks
import useFetchTask from '../../../../../../../../utils/Hooks/useFetchTask';

// Utils
import PersonS4Util from '../../../../../../../../utils/Person/personS4Util';
import JourneyS4Util from '../../../../../../../../utils/Journey/journeyS4Util';
import DateTimeUtil from '../../../../../../../../utils/Datetime/Uplift/datetimeUtil';

// Styling
import './TaskMessageHistory.scss';

const TaskMessageHistory = () => {
  const { taskId } = useParams();
  const { task } = useTask();
  const { isLoading } = useFetchTask(taskId, null);

  const s4Person = PersonS4Util.get(task);
  const s4Journey = JourneyS4Util.get(task);
  const sentAt = PersonS4Util.sentAt(s4Person);
  const formattedSentAt = DateTimeUtil.local.format(sentAt, EUROPE_LONDON, TIME_FORMATS.VOYAGE.DEFAULT);
  const originalSentAt = JourneyS4Util.originalSentAt(s4Journey);
  const formattedOriginalSentAt = DateTimeUtil.local.format(originalSentAt, EUROPE_LONDON, TIME_FORMATS.VOYAGE.DEFAULT);

  const toTaskCreatedAt = () => {
    return DateTimeUtil.local.format(task?.createdAt, EUROPE_LONDON, TIME_FORMATS.VOYAGE.DEFAULT);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div id="task-message-history">
      <div className="govuk-grid-column-full">
        <Heading id="page-title" size="xl" className="task-message-history">Task and message history</Heading>
      </div>
      <div className="govuk-grid-row govuk-!-margin-bottom-4">
        <div className="govuk-grid-column-one-quarter">
          <p>
            <span id="task-id-label" className="field-header">Task ID: </span>
            <span id="task-id-value" className="field-value">{taskId}</span>
          </p>
        </div>
        <div className="govuk-grid-column-one-quarter">
          <p>
            <span id="task-created-label" className="field-header">Task created: </span>
            <span id="task-created-value" className="field-value">{toTaskCreatedAt()}</span>
          </p>
        </div>
      </div>
      <div className="govuk-grid-row govuk-!-margin-bottom-4">
        <ComponentWrapper show={formattedOriginalSentAt}>
          <div className="govuk-grid-column-one-quarter">
            <p>
              <span id="original-sent-label" className="field-header">Original sent: </span>
              <span id="original-sent-value" className="field-value">{formattedOriginalSentAt}</span>
            </p>
          </div>
        </ComponentWrapper>
        <ComponentWrapper show={formattedSentAt}>
          <div className="govuk-grid-column-one-quarter">
            <p>
              <span id="pax-sent-label" className="field-header">Pax sent: </span>
              <span id="pax-sent-value" className="field-value">{formattedSentAt}</span>
            </p>
          </div>
        </ComponentWrapper>
      </div>
    </div>
  );
};

export default TaskMessageHistory;
