// Global import(s)
import { AnimatePresence, motion } from 'framer-motion';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Heading } from '@ukhomeoffice/cop-react-components';
import { isEqual, omit } from 'lodash';
import qs from 'qs';
import React, { useMemo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Config(s)
import DEFAULTS, { FILTER_TAB_SELECTION, FILTER_TABS, TASKS_TABS } from '../shared/constants';
import {
  LOCAL_STORAGE_KEYS,
  PATHS,
  TAB_ID,
  TASK_DETAIL_PAGE_UPDATING,
  VIEW,
} from '../../../../utils/constants';

// Context(s)
import { useErrorAlert } from '../../../../context/ErrorAlertContext';
import { useFilterToggle } from '../../../../context/FilterToggleContext';
import { useKeycloak } from '../../../../context/Keycloak';
import { useNotification } from '../../../../context/NotificationContext';
import { usePermission } from '../../../../context/PermissionContext';
import { useTabs } from '../../../../context/TabContext';
import { useTasks } from '../../../../context/TasksContext';
import { useView } from '../../../../context/ViewContext';

// Component(s)
import ComponentWrapper from '../../../../components/ComponentWrapper/ComponentWrapper';
import DeleteButton from '../../../../components/Buttons/DeleteButton';
import DismissButton from '../../../../components/Buttons/DismissButton';
import DismissFilteredButton from '../../TaskDetails/CurrentTaskDetails/air/components/shared/buttons/DismissFilteredButton';
import Filter from '../../../../components/Filter/Filter';
import FilterTab from './FilterTab';
import InternalHeader from '../shared/components/InternalHeader';
import RuleToolsTab from './components/shared/RuleToolsTab';
import Tabs from '../../../../components/Tabs/Tabs';
import TasksRule from './components/shared/TasksRule';
import TasksTab from '../shared/components/TasksTab';
import BulkDeleteTask from './components/airpax/BulkDeleteTask';

// Animations
import taskCardAnimations from '../shared/taskCardAnimations';

// Hooks
import useFetchSingleTask from '../../../../utils/Hooks/useFetchSingleTask';
import useFetchTasks from '../../../../utils/Hooks/useFetchTasks';
import useFetchTaskIds from '../../../../utils/Hooks/useFetchTaskIds';
import useScreenSize from '../../../../utils/Hooks/useScreenSize';
import useTaskListPage from '../shared/hooks/useTaskListPage.hooks';

// Utils
import { CommonUtil, StorageUtil } from '../../../../utils';
import { shouldIncludeIssuedByUsingId, toFilterPayload, toTasksPayload, toFilterCounts } from '../shared/adaptFilters';
import { toMainContentClassname } from '../../../../components/Utils/Component/toClassName';
import { savePreviousLocation } from '../../../../components/Utils/lastKnownLocationCheck';
import useFetchTaskFilterCounts from '../../../../utils/Hooks/useFetchTaskFilterCounts';

// Styling
import './InternalAirTaskListPage.scss';

const InternalAirTaskListPage = () => {
  const keycloak = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = keycloak.tokenParsed.email;
  const { trackPageView } = useMatomo();
  const { setErrors } = useErrorAlert();
  const { isNineteenTwentyOrMorePixels } = useScreenSize();
  const { canUpdateTask, canBulkDelete } = usePermission();
  const { toggleState } = useFilterToggle();
  const { isDismissTab, isNewTab, isToolsTab, selectedTab, setSelectedTab, tabIndex, setTabIndex, selectedFilterTab } = useTabs();
  const { view } = useView();
  const {
    selectedForDismissal,
    setSelectedForDismissal,
    selectedForDelete,
    setSelectedForDelete,
    setIsSelecting,
    tasks,
    taskIds,
    isLoadingTasks,
    rules,
    setRules,
    taskFilterCounts,
  } = useTasks();
  const { setNotification } = useNotification();
  const [appliedFilters, setAppliedFilters] = useState(StorageUtil.get(DEFAULTS[view].filters.key) || DEFAULTS[view].filters.default || {});
  const [isBulkDeleteTaskFormOpen, setIsBulkDeleteTaskFormOpen] = useState(false);
  const [activePage, setActivePage] = useState(0);

  const isFiltersApplied = useMemo(() => {
    return !isEqual(appliedFilters, DEFAULTS[view].filters.default);
  }, [appliedFilters]);

  // PAGINATION SETTINGS
  const index = activePage - 1;
  const itemsPerPage = DEFAULTS[view].maxResults;
  const offset = index * itemsPerPage < 0 ? 0 : index * itemsPerPage;
  const filterParams = useMemo(() => toTasksPayload({
    appliedFilters,
    tabIndex,
    view,
    itemsPerPage,
    offset,
    currentUser,
    rules,
  }), [appliedFilters, tabIndex, view, itemsPerPage, offset, currentUser, rules]);

  useFetchTaskFilterCounts(filterParams, [VIEW.AIRPAX, VIEW.AIRPAX_V2, VIEW.COMMODITIES, VIEW.CTBP, VIEW.IDP, VIEW.OSDT, VIEW.GENERAL_AVIATION].includes(view));

  const targetingTeams = taskFilterCounts?.targetingTeams?.map((targetingTeam) => { return targetingTeam.value; });
  const rulesMatched = taskFilterCounts?.rules?.map((rule) => { return rule.value; });
  const taskCountsByStatus = Object.fromEntries((taskFilterCounts?.taskStatuses?.map((taskStatus) => ([taskStatus.value, taskStatus.count]))) || []);

  const filterCounts = toFilterCounts(taskFilterCounts, filterParams);

  useFetchTasks(filterParams);
  useFetchSingleTask(filterParams, (offset + itemsPerPage));
  useFetchTaskIds(filterParams.filterParams, (isDismissTab || (isNewTab && isFiltersApplied)));
  useTaskListPage();

  const handleAssigneeFilter = async (tabId) => {
    setAppliedFilters({
      ...appliedFilters,
      assignees: ((tabId === TAB_ID.IN_PROGRESS)
        && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
      issuedBy: (shouldIncludeIssuedByUsingId(tabId)
        && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
      claimedIssuedDismissedOrAssessmentCompleteBy: ((tabId === TAB_ID.COMPLETE)
          && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
    });
  };

  const restorePreFilterPage = () => {
    // Return the user back to the page they were on prior to applying the filters.
    const pageBeforeSearch = StorageUtil.get(`${LOCAL_STORAGE_KEYS.FILTER_PAGE_TRACKER}-${view}`);
    if (pageBeforeSearch) {
      setActivePage(pageBeforeSearch);
      navigate(pageBeforeSearch === 1
        ? location.pathname : `${location.pathname}?page=${pageBeforeSearch}`);
      StorageUtil.remove(`${LOCAL_STORAGE_KEYS.FILTER_PAGE_TRACKER}-${view}`);
    }
  };

  const storePreFilterPage = () => {
    // Store the page number the user is currently on
    if (StorageUtil.get(`${LOCAL_STORAGE_KEYS.FILTER_PAGE_TRACKER}-${view}`) === null) {
      // If activePage is 0, nothing is stored. The 0 value is handled by save function within storage util
      StorageUtil.add(`${LOCAL_STORAGE_KEYS.FILTER_PAGE_TRACKER}-${view}`, activePage);
      navigate(location.pathname);
    }
    setActivePage(1);
  };

  // Used for when filters are applied via the filter component
  const handleOnApplyFilter = async (payload) => {
    setSelectedForDelete([]);
    payload = toFilterPayload(payload, DEFAULTS[view].filters.default, selectedTab, rules);
    StorageUtil.add(DEFAULTS[view].filters.key, JSON.stringify(payload));
    storePreFilterPage();
    setAppliedFilters(payload);
  };

  const handleOnFilterResetInternal = async () => {
    localStorage.removeItem(DEFAULTS[view].filters.key);
    restorePreFilterPage();
    setAppliedFilters(DEFAULTS[view].filters.default);
    setRules(null);
  };

  const handleOnFilterToolTabReset = async () => {
    setSelectedTab(TAB_ID.NEW);
    setTabIndex(0);
    setSelectedForDelete([]);
    handleOnFilterResetInternal();
  };

  const handleOnFilterReset = async (e) => {
    e.preventDefault();
    handleOnFilterResetInternal();
  };

  const enableSelectionIfRequired = (tabId) => {
    setIsSelecting(tabId === TAB_ID.SELECTED_FOR_DISMISSAL);
  };

  const clearSearchUrl = () => {
    if (location.search) {
      location.search = null;
      window.history.pushState({}, null, location.pathname);
    }
  };

  const onTabClick = async (item, itemIndex) => {
    if (selectedTab !== item.id) {
      setTabIndex(itemIndex);
      setSelectedTab(item.id);
      clearSearchUrl();
      setNotification(null);
      setSelectedForDismissal([]);
      setIsSelecting(item.id === TAB_ID.SELECTED_FOR_DISMISSAL);
      await handleAssigneeFilter(item.id);
      enableSelectionIfRequired(item.id);
    }
  };

  const isFiltersWithoutClaimantTheSame = () => {
    return isEqual(omit(appliedFilters, ['claimedByMe']), DEFAULTS[view].filters.default);
  };

  const onFilterTabClick = (item) => {
    if (selectedFilterTab !== item.id) {
      localStorage.setItem(FILTER_TAB_SELECTION, item.id === 'filters' ? 0 : 1);
      handleOnFilterToolTabReset();
    }
  };

  const showDismissFilteredTasksButton = () => {
    return isNewTab && !isFiltersWithoutClaimantTheSame() && selectedForDismissal.length && tasks.length && !isLoadingTasks && canUpdateTask;
  };

  const showDeleteTasksButton = () => {
    return isToolsTab;
  };

  const isAirPaxView = () => {
    return [VIEW.AIRPAX_V2, VIEW.COMMODITIES, VIEW.OSDT, VIEW.CTBP, VIEW.IDP].includes(view);
  };

  const isOsdtView = () => {
    return VIEW.OSDT === view;
  };

  const isCtbpView = () => {
    return VIEW.CTBP === view;
  };

  const getRuleName = () => {
    return rules;
  };

  const getContentWidthClass = () => {
    return toggleState === 'hide'
      ? 'task-list-grid-column govuk-grid-column govuk-grid-column-full-page-width'
      : `task-list-grid-column govuk-grid-column-three-quarters ${isToolsTab ? 'task-list-verticle-line' : ''}`;
  };

  useEffect(() => {
    trackPageView();
    savePreviousLocation();

    localStorage.removeItem(TASK_DETAIL_PAGE_UPDATING);
  }, []);

  useEffect(() => {
    const { page } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const newActivePage = parseInt(page || 1, 10);
    setActivePage(newActivePage);
  }, [location.search]);

  useEffect(() => {
    enableSelectionIfRequired(TASKS_TABS[tabIndex].id);
  }, [tabIndex]);

  useEffect(() => {
    if (isFiltersApplied && isNewTab) {
      setSelectedForDismissal(taskIds);
      return;
    }

    setSelectedForDismissal([]);
  }, [isFiltersApplied, taskIds]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.TASK_LIST_PAGE, view);
  }, [view]);

  const displayToolsTab = canBulkDelete && (isCtbpView() || isOsdtView());

  return (
    <div className="task-list">
      <div className="internal-header-container govuk-grid-row govuk-!-margin-bottom-8">
        <div className={`govuk-grid-column${toMainContentClassname(isNineteenTwentyOrMorePixels)} govuk-grid-column-trimmed`}>
          <section className="govuk-grid-column">
            <InternalHeader
              headerLabel={DEFAULTS?.[view]?.headers?.title}
              links={DEFAULTS?.[view]?.headers?.links}
              size="xl"
            />
          </section>
        </div>
      </div>
      <div className="govuk-grid-row itlp-grid-row">
        <div className={`govuk-grid-column${toMainContentClassname(isNineteenTwentyOrMorePixels)} govuk-grid-column-trimmed govuk-grid-column-nbtc`}>
          <AnimatePresence mode="sync">
            {toggleState === 'show' && (
              <motion.section
                key="filters-left-side-tab"
                className="govuk-grid-column-one-quarter"
                {...taskCardAnimations}
              >
                <ComponentWrapper show={displayToolsTab}>
                  <FilterTab
                    onTabClick={onFilterTabClick}
                    items={[
                      {
                        id: FILTER_TABS[0]?.id,
                        label: FILTER_TABS[0]?.label,
                        panel: (
                          <Filter
                            currentUser={currentUser}
                            data={appliedFilters}
                            filterCounts={filterCounts}
                            customOptions={{
                              rules: rulesMatched?.map((rule) => ({
                                value: rule,
                                label: rule,
                              })),
                              targetingTeams: targetingTeams?.map((targetingTeam) => ({
                                value: targetingTeam,
                                label: targetingTeam,
                              })),
                            }}
                            onApply={handleOnApplyFilter}
                            onClear={handleOnFilterReset}
                          />
                        ),
                      },
                      {
                        id: FILTER_TABS[1]?.id,
                        label: FILTER_TABS[1]?.label,
                        panel: (
                          <RuleToolsTab
                            data={appliedFilters}
                            customOptions={{
                              rules: rulesMatched?.map((rule) => ({
                                value: rule,
                                label: rule,
                              })),
                            }}
                            filterControlStyles={{
                              clearFiltersText: 'Clear selection',
                            }}
                            showFilterControlsAtTop={false}
                            onApply={handleOnApplyFilter}
                            onClear={handleOnFilterToolTabReset}
                          />
                        ),
                      },
                    ]}
                  />
                </ComponentWrapper>
                <ComponentWrapper show={!displayToolsTab}>
                  <Filter
                    currentUser={currentUser}
                    data={appliedFilters}
                    filterCounts={filterCounts}
                    customOptions={{
                      rules: rulesMatched?.map((rule) => ({
                        value: rule,
                        label: rule,
                      })),
                      targetingTeams: targetingTeams?.map((targetingTeam) => ({
                        value: targetingTeam,
                        label: targetingTeam,
                      })),
                    }}
                    onApply={handleOnApplyFilter}
                    onClear={handleOnFilterReset}
                  />
                </ComponentWrapper>
              </motion.section>
            )}
          </AnimatePresence>
          <section className={getContentWidthClass()}>
            <ComponentWrapper show={isToolsTab && isAirPaxView()}>
              <div id="task-list-header-container" className="task-list-header-container">
                <Heading size="m" aria-label="unclaimed matches for">Unclaimed matches for:</Heading>
                <ComponentWrapper show={showDeleteTasksButton()}>
                  <DeleteButton
                    aria-label="delete selected rule matches"
                    label="Delete selected rule matches"
                    onClick={() => {
                      setErrors([]);
                      if (!selectedForDelete.length && selectedForDelete.length === 0) {
                        setErrors([{
                          id: 'delete-button',
                          error: 'You must select a task',
                        }]);
                        return;
                      }
                      setIsBulkDeleteTaskFormOpen(true);
                    }}
                  />
                </ComponentWrapper>
              </div>
              <p className="ho-body-l">{getRuleName()}</p>
              <TasksRule
                isLoading={isLoadingTasks}
                itemsPerPage={itemsPerPage}
                totalNumberOfTasks={taskCountsByStatus?.[TASKS_TABS[tabIndex].taskStatus]}
                totalPages={Math.ceil(taskCountsByStatus?.[TASKS_TABS[tabIndex].taskStatus] / itemsPerPage)}
                redirectPath={DEFAULTS[view].redirectPath}
                activePage={activePage}
                setActivePage={setActivePage}
                filtersApplied={isFiltersApplied}
              />
            </ComponentWrapper>
            <ComponentWrapper show={!isToolsTab}>
              <Tabs
                title="Title"
                id="tasks-tabs"
                className="hods-tasks-tab"
                onTabClick={onTabClick}
                items={TASKS_TABS.map((tab) => {
                  return {
                    id: tab.id,
                    label: `${tab.label} (${taskCountsByStatus?.[tab.taskStatus] ?? 0})`,
                    panel: (
                      <>
                        <div id="task-list-header-container" className="task-list-header-container">
                          <ComponentWrapper show={isNewTab && !isFiltersWithoutClaimantTheSame()}>
                            <Heading size="l" aria-label="filtered tasks" useHodsTag>Filtered tasks</Heading>
                          </ComponentWrapper>
                          <ComponentWrapper show={(isNewTab && isFiltersWithoutClaimantTheSame()) || !isNewTab}>
                            <Heading size="l" aria-label={tab.heading} useHodsTag>{tab.heading}</Heading>
                          </ComponentWrapper>
                          <ComponentWrapper show={isDismissTab && tasks.length && canUpdateTask}>
                            <DismissButton
                              aria-label="dismiss selected tasks"
                              label="Dismiss selected tasks"
                              onClick={() => {
                                if (!selectedForDismissal.length) {
                                  setErrors([{
                                    id: 'dismiss-button',
                                    error: 'You must select a task',
                                  }]);
                                  return;
                                }
                                navigate(`${DEFAULTS[view].redirectPath}${PATHS.BULK_DISMISSALS}`);
                              }}
                            />
                          </ComponentWrapper>
                          <ComponentWrapper show={showDismissFilteredTasksButton()}>
                            <DismissFilteredButton />
                          </ComponentWrapper>
                        </div>
                        <TasksTab
                          isLoading={isLoadingTasks}
                          tabId={tab.id}
                          itemsPerPage={itemsPerPage}
                          totalNumberOfTasks={taskCountsByStatus?.[TASKS_TABS[tabIndex].taskStatus]}
                          totalPages={Math.ceil(taskCountsByStatus?.[TASKS_TABS[tabIndex].taskStatus] / itemsPerPage)}
                          redirectPath={DEFAULTS[view].redirectPath}
                          activePage={activePage}
                          setActivePage={setActivePage}
                          filtersApplied={isFiltersApplied}
                        />
                      </>
                    ),
                  };
                })}
              />
            </ComponentWrapper>
            <ComponentWrapper show={isBulkDeleteTaskFormOpen}>
              <BulkDeleteTask id="bulk-delete-task-container" onClose={() => setIsBulkDeleteTaskFormOpen(false)} numberOfTasksToDelete={selectedForDelete?.length ?? 0} ruleName={getRuleName()} />
            </ComponentWrapper>
          </section>
        </div>
      </div>
    </div>
  );
};

export default InternalAirTaskListPage;
