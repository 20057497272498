import { FormGroup, Radios, VisuallyHidden } from '@ukhomeoffice/cop-react-components';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

// Component(s)
import Comment from './Comment';
import ComponentWrapper from '../../../../../../../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import toTransformedFieldName from './helper/toTransformedFieldName';

const AdditionalCheck = ({ componentId, uniqueId, passengerPositionedAt, onChange, containerId, value: _value, errors }) => {
  const [value, setValue] = useState(_value || { dataId: uniqueId });

  const groupedCheckId = `${componentId}Checked/${uniqueId}-${passengerPositionedAt}Group`;
  const systemCheckedId = `${componentId}Checked/${uniqueId}-${passengerPositionedAt}`;
  const traceId = `${componentId}Trace/${uniqueId}-${passengerPositionedAt}`;
  const traceCommentId = `${componentId}TraceComment/${uniqueId}-${passengerPositionedAt}`;

  const reportChange = (event) => {
    const { target } = event;
    const cleanedName = toTransformedFieldName(target.name.slice(0, -3));

    setValue((prev) => ({
      ...prev,
      [cleanedName]: target.value,
      dataId: uniqueId,
    }));
  };

  useDeepCompareEffect(() => {
    setValue(_value || { dataId: uniqueId });
  }, [_value]);

  useDeepCompareEffect(() => {
    onChange(value, uniqueId);
  }, [value]);

  return (
    <FormGroup
      id={`${systemCheckedId}Group`}
      label={<></>}
      required
      error={errors?.[groupedCheckId]}
    >
      <VisuallyHidden id={`${systemCheckedId}Group`} aria-hidden />
      <Comment
        id={`${componentId}Checked`}
        uniqueId={`${uniqueId}-${passengerPositionedAt}`}
        onChange={reportChange}
        label="System/database checked"
        containerId={containerId}
        formData={_value}
        required
        sourceValuePath="systemChecked"
        error={errors?.[systemCheckedId]}
      />
      <FormGroup
        id={traceId}
        label={<></>}
        required
        error={errors?.[traceId]}
      >
        <VisuallyHidden id={traceId} aria-hidden />
        <Radios
          id={traceId}
          fieldId={traceId}
          classModifiers="inline"
          options={[
            {
              value: 'TRACE',
              label: 'Trace',
            },
            {
              value: 'NO_TRACE',
              label: 'No trace',
            },
          ]}
          onChange={reportChange}
          value={get(value, 'trace')}
        />
        <ComponentWrapper show={get(value, 'trace') === 'TRACE'}>
          <Comment
            id={`${componentId}TraceComment`}
            uniqueId={`${uniqueId}-${passengerPositionedAt}`}
            onChange={reportChange}
            containerId={containerId}
            formData={value}
            required
            error={errors?.[traceCommentId]}
            sourceValuePath="comment"
          />
        </ComponentWrapper>
      </FormGroup>
    </FormGroup>
  );
};

AdditionalCheck.propTypes = {
  componentId: PropTypes.string.isRequired,
  uniqueId: PropTypes.number,
  passengerPositionedAt: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  containerId: PropTypes.string.isRequired,
  value: PropTypes.shape({}),
  errors: PropTypes.shape({}),
};

AdditionalCheck.defaultProps = {
  value: null,
  errors: null,
  uniqueId: null,
};

export default memo(AdditionalCheck);
