import { Link } from '@ukhomeoffice/cop-react-components';
import React from 'react';

// Component
import ComponentWrapper from '../../../../components/ComponentWrapper/ComponentWrapper';

// Hook
import useSetTabTitle from '../../../../utils/Hooks/useSetTabTitle';

const Header = ({ previousTaskCount, description, url }) => {
  const headerLabel = `${previousTaskCount} tasks for `;

  useSetTabTitle(`${headerLabel} ${description}`);

  return (
    <div className="heading-container govuk-!-margin-bottom-8">
      <h1 className="govuk-heading-xl govuk-!-margin-bottom-0 govuk-!-padding-right-1">
        {headerLabel}
        <ComponentWrapper show={url}>
          <Link
            className="previous-task-heading"
            href={`${url}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {`${description}`}
          </Link>
        </ComponentWrapper>
        <ComponentWrapper show={!url}>
          {description}
        </ComponentWrapper>
      </h1>
    </div>
  );
};

export default Header;
