import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import MovementDetails from './MovementDetails';
import SBTChecks from '../../../../components/RenderForm/Forms/Components/SBTChecks';
import TargetInfoHeader from './TargetInfoHeader';
import TargetInformation from './TargetInformation';
import TargetOutcome from '../../../Target/TargetsListPage/components/status/TargetOutcome';
import Warnings from '../../../Target/TargetSheetPage/components/banners/Warnings';

// Util(s)
import { getSelectors } from '../../../../utils/Risks/Target/targetRisksUtil';

// Styling
import './TargetSheet.scss';

const TargetSheet = ({ targetSheet }) => {
  const selectors = getSelectors(targetSheet.informationSheet);

  return (
    <>
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-full">
          <TargetInfoHeader informationSheet={targetSheet.informationSheet} />
        </div>
      </div>
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-full">
          <TargetOutcome targetSheet={targetSheet} isTargetList={false} />
        </div>
      </div>
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-full">
          <Warnings informationSheet={targetSheet.informationSheet} />
        </div>
      </div>
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-full">
          <SBTChecks value={selectors} restrictWidth />
          <MovementDetails informationSheet={targetSheet.informationSheet} />
          <TargetInformation informationSheet={targetSheet.informationSheet} />
        </div>
      </div>
    </>
  );
};

TargetSheet.propTypes = {
  targetSheet: PropTypes.shape({
    informationSheet: PropTypes.shape({}),
  }).isRequired,
};

export default memo(TargetSheet);
