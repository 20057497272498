import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Component(s)
import TaskManagementHeader from '../../../../../components/Headers/TaskManagementHeader';

const InternalHeader = ({ headerLabel, size, links, ...props }) => {
  return (
    <TaskManagementHeader
      headerLabel={headerLabel}
      links={links}
      size={size}
      {...props}
    />
  );
};

InternalHeader.propTypes = {
  headerLabel: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default memo(InternalHeader);
