// Global import(s)
import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import Person from './Person';

// Util(s)
import PersonUtil from '../../../../../../../../../../utils/Person/Uplift/personUtil';

const Persons = ({ persons: _persons, journey }) => {
  if (!_persons?.length) {
    return null;
  }

  const persons = PersonUtil.addRoleLabel(_persons);

  return (
    <>
      {persons.map((person, index) => {
        return (
          <Person
            key={person.id}
            positionedAt={index}
            person={person}
            journey={journey}
          />
        );
      })}
    </>
  );
};

Persons.propTypes = {
  persons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  journey: PropTypes.shape({}).isRequired,
};

export default Persons;
